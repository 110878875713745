import React, { useState, useEffect } from "react";
import useAPI from "../customHook/useAPI";
import { useParams } from 'react-router-dom';
import AuctionTitle from "../components/detail/auctionTitle";
import AuctionDescription from "../components/detail/auctionDescription";
import AuctionStatus from "../components/detail/auctionStatus"
import SellingArea from "../components/detail/selleingArea";
import AuctionAppraisal from "../components/detail/auctionAppraisal";
import Occupant from "../components/detail/occupant";
import RegistrationCertificate from "../components/detail/registrationCertificate";
import AuctionComment from "../components/detail/comment";
import AuctionImage from "../components/detail/auctionImage";
import DetailAside from "../components/detail/detailAside";
import styled from "styled-components";
import { useWindowSize } from "../customHook/useWindowSize";
import { useBlock } from "../customHook/useBlock";

export default function Detail() {
  const { getAPI } = useAPI();
  const [apiData, setApiData] = useState();
  const { auctionId } = useParams();
  const [bulidingArea, setBuildingArea] = useState(0) // 지분률 곱하지 않은 건물 면적
  const [landArea, setLandBuilding] = useState(0)
  const [totalBuildingArea, setTotalBuildingArea] = useState(0) // 지분률 곱한 건물 면적
  const [totalLandArea, setTotalLandArea] = useState(0)
  const [viewMore, setViewMore] = useState([false, false, false, false]) // 더보기 창 없애는 기능
  const { innerWidth } = useWindowSize()
  const { setBlock } = useBlock()

  const getTotalArea = (address) => {
    let totalBuildingArea = Number(address.reduce((sum, item) => sum + item.buildingArea * item.shareRatio, 0).toFixed(2));
    let totalLandArea = Number(address.reduce((sum, item) => sum + item.landArea * item.shareRatio, 0).toFixed(2));
    let buildingArea = Number(address.reduce((sum, item) => sum + item.buildingArea, 0).toFixed(2));
    let landArea = Number(address.reduce((sum, item) => sum + item.landArea, 0).toFixed(2));
    return { totalBuildingArea, totalLandArea, buildingArea, landArea }
  }

  useEffect(() => {
    setBlock(true)
    const fetchAPI = async () => {
      let { data } = await getAPI(`detail/${auctionId}`);
      if(data){
        setApiData(data)
        const { totalBuildingArea, totalLandArea, buildingArea, landArea } = getTotalArea(data.address)
        setTotalBuildingArea(totalBuildingArea)
        setTotalLandArea(totalLandArea)
        setBuildingArea(buildingArea)
        setLandBuilding(landArea)
      }
    }
    fetchAPI()
    setBlock(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Div>
      {apiData &&
        <div>
          {innerWidth > 1200 && <DetailAside apiData={apiData}/>}
          <main>
       
            <AuctionImage images={apiData.auctionImage ? JSON.parse(apiData.auctionImage) : []}/>

            <AuctionTitle apiData={apiData} bulidingArea={bulidingArea} landArea={landArea} totalBuildingArea={totalBuildingArea} totalLandArea={totalLandArea}/>

            <div className="separate" />

            {innerWidth <= 1200 && <DetailAside apiData={apiData}/>}

            <div className="bar"/>

            <AuctionDescription apiData={apiData} bulidingArea={bulidingArea} landArea={landArea} totalBuildingArea={totalBuildingArea} totalLandArea={totalLandArea}/>

            <div className="separate" />

            <AuctionStatus address={apiData.address} finalDate={apiData.finalDate} finalResult={apiData.finalResult} appraisalValue={apiData.appraisalValue} dateHistoryList={JSON.parse(apiData.dateHistoryList)} viewMore={viewMore} setViewMore={setViewMore}/>

            <div className="separate" />

            <SellingArea address={apiData.address} totalBuildingArea={totalBuildingArea} totalLandArea={totalLandArea}/>

            <div className="separate" style={{marginTop:"68px"}}/>

            {apiData.notice &&
              <>
                <div className="notice">
                  <h2>비고 및 참고</h2>
                  <div>{apiData.notice}</div>
                </div>
                <div className="separate" />
              </>
            }
            <AuctionAppraisal appraisalList={apiData.detail.appraisalList && JSON.parse(apiData.detail.appraisalList)} viewMore={viewMore} setViewMore={setViewMore}/>
         
            <div className="separate" />

            <Occupant maemulseo={apiData.maemulseo} address={apiData.address}/>

            <div className="separate" style={{marginTop:"68px"}}/>

            <RegistrationCertificate address={apiData.address} viewMore={viewMore} setViewMore={setViewMore}/>
     
            <div className="separate"/>
            <AuctionComment comment={apiData?.comment ? JSON.parse(apiData.comment):null}/>

          </main>
        </div>
      }
    </Div>
  )
}

const Div = styled.div`
  max-width:1200px;
  width:calc(100% - 80px);
  margin:22px auto 0;
  >div{
    display: flex;
    flex-direction:row-reverse;
    justify-content: space-between;
  }
  main{
    flex:790;
    max-width:790px;
    width: calc(100% - 410px);
    margin-right: 30px;
    .notice{
      margin-bottom:54px;
      h2{
        color: #191F28;
        font-size: 22px;
        font-weight: 700;
        margin:44px 0 28px;
      }
      >div{
        color: #4E5968;
        font-size: 15px;
        font-weight: 500;
      }
    }

    table{
      width:100%;
      border: 1px solid #EAEAEA;
      border-collapse: collapse;
      thead{
        height: 40px;
        background: #F2F4F6;
        th{
          border: 1px solid #EAEAEA;
          border-collapse: collapse;
          color: #333D4B;
          font-size: 14px;
          font-weight: 700;
          padding:0 10px;
        }
      }
      tbody{
        tr{
          height: 40px;
          td{
            text-align:center;
            border: 1px solid #EAEAEA;
            border-collapse: collapse;
            color: #333D4B;
            font-size: 14px;
            font-weight: 500;
            div{
              color: #333D4B;
              font-size: 14px;
              font-weight: 500;
            }
            span{
              color: #333D4B;
              font-size: 14px;
              font-weight: 500;
            }
            padding: 10px 10px;
          }
          .more{
            color: #1A6DFF;
            font-weight: 600;
            text-decoration: underline;
            cursor:pointer;
          }
        }
      }
    }
  }
  .separate{
    width:100%;
    height:1px;
    background: #DADADA;
  }
  .viewMore{ // 더보기
    width: 100%;
    height:92px;
    position: absolute;
    bottom:32px;
    .blur{
      width:100%;
      height: 40px;
      background: linear-gradient(360deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }
    .button{
      background: #fff;
      padding-top: 10px;
      height: 84px;
      div{
        margin: 0 auto;
        width: 126px;
        height: 42px;
        line-height: 42px;
        text-align:center;
        border-radius: 4px;
        border: 1px solid #DADADA;
        background: #FFF;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
        color: #333D4B;
        font-size: 15px;
        font-weight: 500;
        cursor:pointer;
      }
    }
  }

  @media (max-width: 1200px) {
    width:100%;
    main{
      >div, aside{
        width:calc(100% - 80px);
        margin: 0 auto;
      }
      .bar{
        width:100%;
        height:12px;
        background: #E5E8EB;
        margin: 24px 0 32px
      }
      margin-right:0px;
      max-width:inherit;
    }
  }

  @media (max-width: 767px) {
    margin-top:0px;
    main{
      .notice{
        margin-bottom:36px;
        h2{
          font-size: 20px;
          font-weight: 700;
          margin:36px 0 20px;
        }
        >div{
          font-size: 13px;
        }
      }

      >div, aside{
        width:calc(100% - 36px);
      }
      table{
        thead{
          th{
            font-size: 13px;
            padding:0 4px;
          }
        }
        tbody{
          tr{
            height: 40px;
            td{
              padding: 4px 4px;
              font-size: 13px;
              div, span{
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    .separate{
      width:calc(100% - 36px);
    }
  }
`