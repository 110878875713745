import { useWindowSize } from '../../customHook/useWindowSize';
import { Viewer } from '@react-pdf-viewer/core';

import { Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import styled from "styled-components";
import { useEffect } from 'react';
import { useModal } from "../../customHook/useModal";

import close from "../../images/close.svg"

export default function PdfModal({ fileUrl, setCloseButtonStyle }) {
  const { innerWidth, innerHeight } = useWindowSize()
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const { modalClose } = useModal()

  useEffect(()=>{
		let style = {
      display:'none',
		}
		setCloseButtonStyle(style)
		return () => {setCloseButtonStyle({})}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

  return (
    <Div $innerWidth={innerWidth} $innerHeight={innerHeight}>
      <img onClick={() => {modalClose()}} src={close} style={{position: "absolute", top: "0px", right: "-26px",cursor:'pointer', filter: "invert(100%) sepia(1%) saturate(0%) hue-rotate(331deg) brightness(114%) contrast(101%)"}} alt="close button"/>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div style={{ alignItems: 'center', backgroundColor: '#eeeeee', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', display: 'flex', height: '80px' }}>
            <Toolbar/>
          </div>
          <div style={{flex: 1, overflow: 'hidden',}}>
              <Viewer 
                fileUrl={fileUrl} plugins={[toolbarPluginInstance]}
                httpHeaders={{
                  "Access-Control-Allow-Origin": "*",
                  'content-type': 'application/json',
                  'Accept': 'application/json',
                }}
                />
            </div>
        </Worker>
      </div>
    </Div>
  )
}

const Div = styled.div`
  width:${(props) => props.$innerWidth * 0.9}px;
  height:${(props) => props.$innerHeight * 0.9}px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  align-items: center;
  .toolbarContainer{
    width: 100%;
    height:100px;
  }
`