import styled from "styled-components"
import SearchbarMain from './searchbarMain'
import building from '../images/building.png';
import { useWindowSize } from "../customHook/useWindowSize";

export default function MainImage() {
  const { isMobile } = useWindowSize()
  return (
    <Div>
      <h1>어떤 물건을 찾고 계세요?</h1>
      <h6>사건번호 검색을해서 검색해보세요 그럼 집이나옵니다!</h6>
      <div className="building" >
        <div className="background"/>
        <img src={building} alt="building" />
      </div>
      {!isMobile && <SearchbarMain />}
    </Div>
  );
}

const Div = styled.div`
  margin:0 auto;
  max-width: 1200px;
  /* width:calc(100% - 80px); */
  text-align: center;
  margin-top:64px;
  >h1{
    color: #191F28;
    font-size: 48px;
    font-weight: 700;
  }
  >h6{
    color: #4E5968;
    font-weight: 500;
    margin-top:28px;
    margin-bottom:56px;
  }
  .building{
    position: relative;
    height: 573px;
    .background{
      position: absolute;
      bottom:0;
      width: 100%;
      height:500px;
      border-radius: 24px;
      background: #4E99D2;
    }
    >img{
      position: absolute;
      right: 0;
      width:85%;
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    >h1{
      font-size: 28px;
    }
    >h6{
      font-size: 14px;
      margin-top:16px;
      margin-bottom:32px;
    }
    .building{
      height:inherit;
      aspect-ratio: 324 / 227;
      .background{
        height: inherit;
        aspect-ratio:324 / 198;
        /* height:200px; */
      }
    }
  }

`