import dots from "../../images/dots.svg"
import styled from "styled-components"
import ViewMore from "./viewMore";

export default function AuctionStatus({address, finalDate, finalResult, appraisalValue, dateHistoryList, viewMore, setViewMore }) {
  const getSoldPrice = (appraisalValue, dateHistoryList) => {
    if (dateHistoryList.price) {
      return `매각가 : ${dateHistoryList.price} (${(parseInt(dateHistoryList.price.replace(/,/g, '')) / appraisalValue * 100).toFixed(2)
        }%)`
    } else {
      return null
    }
  }

  const isFinished = (address) => {
    const auctionStateSet = new Set()
    for (const addressData of address) {
      if(addressData.auctionState){
        auctionStateSet.add(addressData.auctionState)
      } else {
        return false
      }
    }
    const auctionStateList = Array.from(auctionStateSet)
    // 주소들에 auctionState가 모두 존재하고, 기각 취하 취소 등 1개일때만 끝났다고 인지
    if(auctionStateList.length === 1){
      return auctionStateList[0]
    } else {
      return false
    }
  }

  const getStatus = (dateHistoryList, address) => { // address의 auctionStatus도 보기
    let last = dateHistoryList[dateHistoryList.length - 1]
    if (last.auctionDateType === "매각결정기일" && last.auctionResult === "") {
      dateHistoryList.splice(dateHistoryList.length - 1, 1) // 마지막 객체가 통상적으로 붙는 "매각결정기일" 이라면 삭제.
    }
    let data = []
    let finsiehd
    if(finalResult !== '미종국' && finalDate){
      finsiehd = true
    } else if(isFinished(address)){
      finsiehd = true
    }

    for (let i = 0; i < dateHistoryList.length; i++) {
      let last = dateHistoryList.length - 1 // 마지막 객체. ui 다르게 표시하기위해 사용.
      let isNotPaid = dateHistoryList[i].auctionResult === "미납" // 미납시 박스처리
      let isSolid = dateHistoryList[i].auctionResult === "매각" && dateHistoryList[i].price // 매각시 매각가 표시

      if (dateHistoryList[i].auctionDateType === "매각기일") {
        data.push(
          <div key={i} className="block">
            <div className="dots">
              <img src={dots} alt="dot_image" />
            </div>
            <div className={i === last ? "row last" : "row "}>
              <div className="dateInfo">
                {i === last && <div className="outerDot"><div className="innerDot"></div></div>}
                {dateHistoryList[i].auctionDate.replace(/\([^)]*\)/, '')}
              </div>
              <div>
                <span className="priceInfo">
                  {dateHistoryList[i].minimumBidPrice}
                </span>
                <span className={dateHistoryList[i].auctionResult === "매각" ? "statusInfo sold" : "statusInfo"}>
                  {dateHistoryList[i].auctionResult || "진행"}
                </span>
              </div>
            </div>
          </div>
        )
      } else { // 표로 만들어야함.
        if(!dateHistoryList[i].auctionDate){continue}
        data.push(
          <div key={i} className="row">
            <div className="dots">
              <img src={dots} alt="dot_image" />
            </div>
            <div style={{ width: "100%", justifyContent: "center" }}>
              {dateHistoryList[i].auctionDateType}:{dateHistoryList[i].auctionDate.replace(/\([^)]*\)/, '')}
              <span>
                {!isNotPaid && dateHistoryList[i].auctionResult !== "" && ` - ${dateHistoryList[i].auctionResult}`}
              </span>
            </div>
          </div>
        )
        isNotPaid && data.push(
          <div key={`${i}${i}`} className="row notpaid">
            <div className="borderBox">
              <span style={{ color: "#D91C18" }}>
                미납
              </span>
            </div>
          </div>
        )
      }
      if (isSolid) {
        data.push(
          <div key={`${i}${i}${i}`} className="row">
            <div className="dots">
              <img src={dots} alt="dot_image" />
            </div>
            <div style={{ width: "100%", justifyContent: "center" }}>
              {getSoldPrice(appraisalValue, dateHistoryList[i])}
            </div>
          </div>
        )
      }
    }
    if(finsiehd){
      if(finalDate){ // 전체 종결일때 finalDate 가질 수 있음
        data.push(
          <div key={finalDate+finalResult} className="row">
            <div className="dots">
              <img src={dots} alt="dot_image" />
            </div>
            <div style={{ width: "100%", justifyContent: "center" }}>
              {`${finalDate}`.slice(0, 4)}.{`${finalDate}`.slice(4, 6)}.{`${finalDate}`.slice(6, 8)} -&nbsp;<span style={{color:'#D91C18', fontWeight:'700'}}>{finalResult}</span>
            </div>
          </div>
        )
      } else { // 일부 취하 기각일때 finalDate 없음(주소에서 상태 받아옴)
        data.push(
          <div key={finalResult+isFinished(address)} className="row">
            <div className="dots">
              <img src={dots} alt="dot_image" />
            </div>
            <div style={{ width: "100%", justifyContent: "center" }}>
              <span style={{color:'#D91C18', fontWeight:'700'}}>{isFinished(address)}</span>
            </div>
          </div>
        )
      }
    }


    return data.reverse()
  }

  return (
    <Div>
      <h2>입찰 진행내역</h2>
      <div className="content" style={viewMore[0] ? { maxHeight: 'initial' } : { maxHeight: '212px' }}>{getStatus(dateHistoryList, address)}</div>
      {dateHistoryList.length > 3 && <ViewMore index={0} viewMore={viewMore} setViewMore={setViewMore} />}
    </Div>
  )
}



const Div = styled.div`
  padding-bottom: 32px;
  position:relative;
  h2{
    margin:44px 0 32px;
    color: #191F28;
    font-size: 22px;
    font-weight: 700;
  }
  .content{
    /* max-height: 212px; // 더보기용 */
    overflow: hidden;
  }
  .content > .block:first-child .dots{
    display:none; //content의 첫번째 자식이 block일때 dots 제거
  }

  .notpaid{
    margin-top: 14px;
    margin-bottom: 12px;
    .borderBox{
      justify-content:center;
    }
  }

  .content .row{
    display: flex;
    justify-content: space-between;
    align-items:center;
    height:26px;
    .borderBox{
      width:100%;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #EAEAEA;
    }
    .dateInfo{
      color: #B0B8C1;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .dateInfo:before{
      content:'';
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #B0B8C1;
      border-radius:50%;
      margin-right:16px;
      margin-left:7px;
    }
  >div{
    display: flex;
    align-items: center;
    .priceInfo{
      color: #B0B8C1;
      font-weight: 500;
      text-decoration:line-through;
      }
      .statusInfo{
        display:inline-block;
        width: 46px;
        height: 26px;
        line-height:26px;
        border-radius: 100px;
        background: #F2F4F6;
        text-align:center;
        color: #8B95A1;
        font-size: 12px;
        font-weight: 700;
        margin-left:8px;
      }
      .sold{
        color: #D91C18 !important;
        background: #FDEDED !important;
      }
    }
  }

  .dots{
    margin-left: 10px;
    height: 20px;
  }

  .content .last{
    margin-bottom: 2px;
    .dateInfo{
      color: #333D4B;
      .outerDot{
        width: 22px;
        height: 22px;
        background: rgba(26, 109, 255, 0.10);
        border-radius:50%;
        position:relative;
        margin-right:9px;
        .innerDot{
          width: 8px;
          height: 8px;
          background:#1A6DFF;
          border-radius:50%;
          position:absolute;
          left:calc(50% - 4px);
          top:calc(50% - 4px);
        }
      }
    }
    .dateInfo:before{
        display: none !important;
      }
    >div{
      .priceInfo{
        color: #333D4B;
        font-size: 18px;
        font-weight: 700;
        text-decoration:inherit;
      }
      .statusInfo{
        background: #E8F3FF;
        color: #1A6DFF;
      }
    }
  }
  @media (max-width: 767px) {
    h2{
      font-size: 20px;
      margin:36px 0 22px;
    }
    .content .row{
      .dateInfo{
        font-size: 14px;
      }
      >div{
        font-size:14px;
      .priceInfo{
        font-size: 15px;
        }
        .statusInfo{
        }
      }
    }
  }
`