import { useEffect } from "react"
import styled from "styled-components";

const { kakao } = window


export default function KakaoMap({ address }) {
 
  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(36.633535, 127.425882),
      level: 3
    };
    // 지도를 생성합니다.
    const map = new kakao.maps.Map(container, options);
    // 주소-좌표 변환 객체를 생성합니다.
    const geocoder = new kakao.maps.services.Geocoder();
    // 주소로 좌표를 검색합니다..
    geocoder.addressSearch(address, function (result, status) {

      // 정상적으로 검색이 완료됐으면 
      if (status === kakao.maps.services.Status.OK) {
        var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
        const marker = new kakao.maps.Marker({
          map: map,
          position: coords
        });

        // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
        map.setCenter(coords);

        var content = `
        <div class=balloon>
          <div>${address.split(',')[0]}</div>
        </div>
        `

        let markerPosition = marker.getPosition()
        // 마커 위에 커스텀오버레이를 표시
        const overlay = new kakao.maps.CustomOverlay({
          content: content,
          map: map,
          position: new kakao.maps.LatLng(markerPosition.Ma+0.0005, markerPosition.La)
        });
        overlay.setMap(map);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div id="map"></Div>
  )
}

const Div = styled.div`
  width:100%;
  height:100%;
  .balloon{
    background-color: #fff;
    border: 1px solid #DADADA;
    padding: 4px 8px;
    border-radius: 4px;
    >div{
      font-size:14px;
    }
  }
`