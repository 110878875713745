import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';


export function Slide({sliderValues, setSliderValues, maxValue, step, marks, style}) {  

  const formatValue = (value) => {
    if (value === maxValue) {
      return '무제한';
    }
    return value.toLocaleString();
  };

  const handleSliderChange = (values) => {
    setSliderValues(values);
  };

  return (
    <Div style={style}>
      <Slider
        range
        min={0}
        max={maxValue}
        step={step}
        value={sliderValues}
        onChange={handleSliderChange}
        marks={marks}
        dots={true}
        tipFormatter={formatValue}
      />
    </Div>
  );
}

const Div = styled.div`
  width: 366px;
  margin: 0 auto;
  height:102px;
  padding-top: 45px;

  .rc-slider{
    transform: translateX(3px);
  }
  .rc-slider-rail{
    background-color: #D1D6DB;
  }
  .rc-slider-handle{
    top: -3px;
    transform: translateX(calc(-50% - 3px)) !important;
    opacity:inherit;
    border:1px solid #A2ACB8;
  }
  .rc-slider-track{
    background-color:#1A6DFF;
    height:5px;
  }
  .rc-slider-dot-active{
    border-color:#1A6DFF;
  }
  .rc-slider-step{
    /* height:2px; */
  }
  .rc-slider-dot{
    border:0px;
    background-color: inherit;
  }
  .rc-slider-handle{
    width:28px;
    height:28px;
  }
  .rc-slider-handle:active{
    box-shadow:none;
    border-color:#1A6DFF;
  }
  .rc-slider-mark{
    top:33px;
    white-space: nowrap;
    >span{
      color: #8B95A1;
      font-size: 13px;
      font-weight: 500;
    }
  }
  @media (max-width: 767px) {
    width: 296px;
    padding-top: 24px;
  }
`
