import { jiwonCode } from "../../utils";
import heart from "../../images/heart.svg"
import heardRed from "../../images/heardRed.svg"

import styled from "styled-components"
import useAPI from "../../customHook/useAPI";
import { useState, useEffect } from "react";
import { useModal } from "../../customHook/useModal";
import { useAuth } from "../../customHook/useAuth";
import { useNavigate } from "react-router-dom";

export default function AuctionTitle({ apiData, totalBuildingArea, totalLandArea }) {

  const { postAPI } = useAPI()
  const [isLike, setLike] = useState(null)
  const { modalShow, modalClose}  = useModal()
  const { user } = useAuth()
  const navigate = useNavigate()

  const postLike = async () => {
    if(!user){
      navigate('/login')
      return
    }
    let data = {
      auctionId:apiData.auctionId
    }
    const { success } = await postAPI(`/auction/like`, data);
    if(success){
      setLike(!isLike)
    } else {
      modalShow({
        message:"관심물건 등록에 에러가 있습니다.",
        submessage:"에러가 지속된다면 관리자에게 문의해주세요",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
    }
  }

  useEffect(()=>{
    setLike(apiData.likes)
  },[apiData])
  return(
    <Div>
      <div className="topBox">
        <div className="areas">
          {totalBuildingArea !== 0 && <div className="area">건물 {(totalBuildingArea / 3.3).toFixed(1)}평</div>}
          {totalLandArea !== 0 && <div className="area">토지 {(totalLandArea / 3.3).toFixed(1)}평</div>}
        </div>
        <div className="view">
        {apiData.views !== 0 && <>조회수 {apiData.views.toLocaleString()}회</>}
        </div>
      </div>
      <div className="flexWrapper">
        <div className="infoArea">
          <div className="address">
            {apiData.address.length > 1 ? `${apiData.address[0].address} 외 ${apiData.address.length - 1}개` : apiData.address[0].address}
          </div>
          <div className="caseInfo">
            <span className="caseNumber">
              {`사건번호: ${apiData.caseNumber.toString().slice(0, 4)}타경${apiData.caseNumber.toString().slice(4)}[${apiData.itemNumber}]`}
            </span>
            <span className="separateBar" />
            <span className="court">
              법원: {Object.keys(jiwonCode).find(key => jiwonCode[key] === parseInt(apiData.court))}
            </span>
          </div>
        </div>
        <div className="like" onClick={()=>{postLike()}}>
          <img src={isLike ? heardRed : heart} alt="like button" />
          <span>
            관심물건
          </span>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  .topBox{
    margin-top:26px;
    height: 28px;
    display: flex;
    justify-content:space-between;
    .areas{
      display:flex;
      .area{
        height: 100%;
        border-radius: 4px;
        background: #F2F4F6;
        padding:6px 10px;
        color: #4E5968;
        font-size: 14px;
        font-weight: 600;
        margin-right:12px;
      }
    }
    .view{
      color: #B0B8C1;
      font-size: 14px;
      font-weight: 500;
      height: 100%;
      line-height: 28px;
    }
  }
  .flexWrapper{
    margin-top:16px;
    display: flex;
    justify-content:space-between;
    .infoArea{
      margin-bottom:42px;
      .address{
        color: #191F28;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
      }
      .caseInfo{
        display: flex;
        align-items:center;
        margin-top:14px;
        .separateBar{
          background-color: #DADADA;
          display: inline-block;
          width: 1px;
          height: 14px;
          margin:0 10px;
        }
        .caseNumber{
          color: #333D4B;
          font-size: 15px;
          font-weight: 500;
        }
        .court{
          color: #8B95A1;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
    .like{
      width: 103px;
      height: 42px;
      flex-basis: 103px; // width 고정
      flex-grow: 0;
      flex-shrink: 0;
      margin-left:16px;
      border-radius: 100px;
      border: 1px solid #DADADA;
      background: #FFF;
      display: flex;
      justify-content:center;
      align-items:center;
      cursor:pointer;
      > img{
        margin-right:6px;
      }
      > span{
        color: #333D4B;
        font-size: 14px;
        font-weight: 500;
      }
    } 
  }
  @media (max-width: 767px) {
    .topBox{
      .view{
        font-size: 13px;
      }
    }

    .flexWrapper{
      display: block;
      .infoArea{
        margin-bottom:16px;
        .address{
          font-size: 18px;
        }
        .caseInfo{
          margin-top:8px;
          *{
            font-size:14px !important;
          }
        }
      }
      .like{
        margin-left:0px;
        margin-bottom:16px;
      }
    }
  }

 
`