import styled from "styled-components";

// login, add wish, my board
export default function ModalContent({modalContent}){
  return(
    <Div>
      <div className="message">{modalContent.message}</div>
      <div className="submessage">{modalContent.submessage}</div>
      <div>{modalContent.others}</div>
      <div className="button">{modalContent.actionButton}</div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 440px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top:42px;

  .button{
    width: 120px;
    line-height:44px;
    height: 44px;
    border-radius: 4px;
    background: #1A6DFF;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    cursor:pointer;
  }

  .message{
    color: #191F28;
    font-size: 18px;
    font-weight: 700;
  }
  .submessage{
    margin-top:14px;
    color: #4E5968;
    font-size: 15px;
    font-weight: 500;
    .box{
      display:flex;
      justify-content:center;
      align-items:center;
      border-radius: 4px;
      background: #F2F4F6;
      width: 380px;
      height: 48px;
    }
  }
  .button {
    margin:34px 0 24px;
  }

  .flexContainer {
    // 예 or 아니요 버튼. confirmEmailDuplicationModal 에서 사용
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 15px;

    div {
      width: 46%;
      height: 60px;
      line-height: 60px;
      background: #222222;
      color: #FFFFFF;
    }
  }
  /* #bad3ff */

  .dotContainer{
    display: flex;
    justify-content: center;
    margin-bottom:22px;
  }
  .dot-flashing {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1A6DFF;
    color: #1A6DFF;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -20px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1A6DFF;
    color: #1A6DFF;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 20px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1A6DFF;
    color: #1A6DFF;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #1A6DFF;
    }
    50% {
      background-color: #bad3ff;
    }
    100% {
      background-color: #bad3ff;
    }
  }

  @media (max-width: 767px) {
    width: 324px;
    .message{
      font-size: 16px;
    }
    .dotContainer{
      margin-bottom:13px;
    }
    .submessage{
      font-size: 13px;
      .box{
        width: 288px;
        font-size: 13px;
        >span{
          font-size: 13px;
        }
      }
    }
    .button{
      margin:26px 0 20px;
      width: 102px;
      height: 40px;
      line-height: 40px;
      >div{
        font-size:14px;
      }
    }
  }
`