import { useEffect, useState } from 'react';
import useAPI from '../customHook/useAPI';
import MainImage from '../components/mainImage'
import RecommendList from '../components/recommendList'
import Banner from '../components/banner'
import YoutubeList from '../components/youtubeList'
import SubBanner from '../components/subBanner'
import styled from 'styled-components';

export default function Main() {

  const { getAPI } = useAPI()
  const [apiData, setApiData] = useState()

  useEffect(() => {
    const fetchAPI = async () => {
      let url = `/main`
      let { data } = await getAPI(url)
      setApiData(data)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Div>
      <section>
        <MainImage />
        <RecommendList apiData={apiData?.auctions} />
      </section>

      <Banner />
      <section>
        <YoutubeList apiData={apiData?.youtubes} />
        <SubBanner />
      </section>
    </Div>
  );
}

const Div = styled.div`
  >section{
    width:calc(100% - 80px);
    margin:0 auto;
  }
  @media (max-width: 767px) {
    >section{
      width:100%;
      >div{
        width:calc(100% - 36px);
      }
    }
  }
`