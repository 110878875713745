import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { keywordsList } from "../../utils";
import styled from 'styled-components';
import { useWindowSize } from '../../customHook/useWindowSize';

export function Keywords({ setSelected, filterOptions, setFilterOptions, close }) {
  const location = useLocation();
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()
  const [isAllChecked, setAllcheck] = useState(true)
  const [checkedList, setCheckedList] = useState([])
  const params = new URLSearchParams(location.search);

  const apply = (checkedList) => {
    let keywordsParams = checkedList.length > 0 ? [...checkedList].join('+') : null
    keywordsParams ? params.set('keywords', keywordsParams) : params.delete('keywords');
    close()
    navigate(`/search?${params.toString()}`)
  }

  useEffect(() => {
    let keywordsParams = params.get('keywords')?.split('+')
    if(keywordsParams?.length > 0){
      setAllcheck(false)
    }
    setCheckedList(keywordsParams ? keywordsParams : [])
    if(isMobile) return
    if (keywordsParams?.length > 0) {
      setSelected({
        "content": keywordsParams.length === 1 ? keywordsCodeToName(keywordsParams[0]) : keywordsCodeToName(keywordsParams[0]) + ' 외',
        "count": keywordsParams.length === 1 ? 0 : keywordsParams.length - 1,
      });
    } else {
      setSelected({content: '', count: 0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setAllcheck(checkedList.length === 0)
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.keywords = checkedList
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList])

  const handleChecked = (value) => {
    let newCheckedList;
    if (checkedList.includes(value)) {
      newCheckedList = checkedList.filter(item => item !== value)
    } else {
      newCheckedList = [...checkedList]
      newCheckedList.push(value)
    }
    newCheckedList.length === 0 ? setAllcheck(true) : setAllcheck(false)
    setCheckedList(newCheckedList)
  }

  const keywordsCodeToName = (codeToFind) => {
    const keywords = keywordsList.find(item => item.code === codeToFind);
    return keywords?.name
  }

  return (
    <Div>
      <header>
        <div>특수권리</div>
        <span>중복 선택 가능</span>
      </header>

      <div className="checkBox">
        <label htmlFor="전체">
          <input
            type="checkbox"
            id="전체"
            onChange={() => { setAllcheck(!isAllChecked); setCheckedList([]) }}
            checked={isAllChecked}
          />
          전체
        </label>

        {keywordsList.map((keywords) => (
          <label key={keywords.code} htmlFor={keywords.code}>
            <input
              type="checkbox"
              id={keywords.code}
              checked={checkedList.includes(keywords.code)}
              onChange={() => handleChecked(keywords.code)}
            />
            {keywords.name}
          </label>
        ))}
      </div>

      <div className="buttons">
        <button className="reset" onClick={() => { setAllcheck(true); setCheckedList([]); }}>초기화</button>
        <button className="apply" onClick={() => { apply(checkedList) }}>적용하기</button>
      </div>
    </Div>
  )
}

const Div = styled.div`
  width: 246px;
  .checkBox{
    >label{
      display: flex;
      align-items: center;
      color: #333D4B;
      font-size: 15px;
      font-weight: 500;
      margin:8px 0;
      >input{
        width: 24px;
        height: 24px;
        margin: 0 10px 0 0;
      }
      >input[type="checkbox"] {
        accent-color: #1A6DFF;
      }
    }
  }
`