import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useScrollLock } from "./useScrollLock";
import PdfModal from "../components/modal/pdfModal";
import ModalContent from "../components/modal/modalContent";
import AddressModal from "../components/modal/addressModal";
import ReportModal from "../components/modal/ReportModal"
import CarouselModal from "../components/modal/carouselModal"
import FilterModal from "../components/modal/filterModal"
import DocumentModal from "../components/modal/DocumentModal";
import close from "../images/close.svg"
import styled from "styled-components";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {

  const { lockScroll, unlockScroll } = useScrollLock();

  const [isShow, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  // setShow(false)로 바로 닫아버리면 rerendering돼서 fadeout animation 안먹힘.
  // setVisible(false) 후 일정시간 후 setShow하면 그시간동안 fadeout animation 적용 가능.
  const [closeButtonStyle, setCloseButtonStyle] = useState({})

  const [content, setContent] = useState('')

  useEffect(() => {
    let timeoutId;
    if (visible) {
      setShow(true);
    } else {
      setShow(false);
      // timeoutId = setTimeout(() => {
      //   setShow(false);
      // }, 300);
    }

    const keyDownHandler = event => {
      if(!visible){return}
      event.key === 'Escape' && modalClose()
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      if (timeoutId !== undefined) {clearTimeout(timeoutId);}
      document.removeEventListener('keydown', keyDownHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);


  const addressModal = useCallback((addressInfo) => {
    setVisible(true)
    setContent(<AddressModal addressInfo={addressInfo}/>)
    lockScroll()
  }, [lockScroll]);

  const documentModal = useCallback((detail) => {
    setVisible(true)
    setContent(<DocumentModal detail={detail}/>)
    lockScroll()
  }, [lockScroll]);


  const reportModal = useCallback((auctionId, caseNumber) => {
    setVisible(true)
    setContent(<ReportModal auctionId={auctionId} caseNumber={caseNumber}/>)
    lockScroll()
  }, [lockScroll]);

  const pdfModal = useCallback((fileUrl) => {
    setVisible(true)
    setContent(<PdfModal fileUrl={fileUrl} setCloseButtonStyle={setCloseButtonStyle}/>)
    lockScroll()
  }, [lockScroll]);

  const filterModal = useCallback(() => {
    setVisible(true)
    setContent(<FilterModal/>)
    lockScroll()
  }, [lockScroll]);

  const modalShow = useCallback((content) => {
    setVisible(true)
    setContent(<ModalContent modalContent={content} />)
    lockScroll()
  }, [lockScroll]);

  const modalClose = useCallback(() => {
    setVisible(false)
    unlockScroll()
    setCloseButtonStyle({}) // close시 style 모두 제거됨.
  }, [unlockScroll]);

  const carouselModal = useCallback((images, activeSlide) => {
    setVisible(true)
    setContent(<CarouselModal images={images} initialSlide={activeSlide} setCloseButtonStyle={setCloseButtonStyle}/>)
    lockScroll()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockScroll])

  const value = useMemo(
    () => ({ modalClose, documentModal, pdfModal, modalShow, addressModal, carouselModal, reportModal, filterModal }),
    /*eslint-disable */
    []
    /*eslint-enable */
  );

  return (
    <ModalContext.Provider value={value}>
      {isShow &&
        <Div visible={visible ? "true" : "false"}
          onClick={(e) => {e.stopPropagation();modalClose()}}
        // close modal when outside of modal is clicked
        >
          <div className="modalContent"
            onClick={e => { e.stopPropagation() }}
          // modal content click시 닫지 않도록.
          >
            {content}
            <img className="modalClose" style={closeButtonStyle} onClick={() => {modalClose()}} src={close} alt="close button"/>
          </div>
        </Div>
      }
      {children}
    </ModalContext.Provider>

  )

};

export const useModal = () => {
  return useContext(ModalContext);
};


const Div = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  animation: ${props => props.visible ? 'fadeIn' : 'fadeOut'} 0.2s ease-out;

  .modalContent {
    position: relative;
    text-align:center;
  }

  .modalClose {
    display: block;
    position: absolute;
    top: 28px;
    right: 36px;
    width: 16px;
    height: 16px;
    z-index:100;
    cursor:pointer;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media (max-width: 767px) {
    .modalClose {
      top: 16px;
      right: 16px;
    }
  }
`