import { useEffect, useState } from 'react';
export const useWindowSize = () => { 

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // https://bobbyhadz.com/blog/react-get-window-width-height

  function getWindowSize() {
    const {innerWidth, innerHeight }  = window;
    let isDesktop = false
    let isTablet = false
    let isMobile = false
    if(innerWidth >= 1024){
      isDesktop = true
    } else if ( innerWidth < 1024 && innerWidth >= 768){
      isTablet = true
    } else {
      isMobile = true
    }

    return {innerWidth, innerHeight, isDesktop, isTablet, isMobile};
  }

  return windowSize;  
}

