import styled from 'styled-components';
import YoutubeItem from './youtubeItem'
import youtube from '../images/youtube.png'
import arrow_right from "../images/arrow_right.png"
import Slider from "react-slick";
import slickArrow from "../images/slickArrow.svg"
import { useWindowSize } from '../customHook/useWindowSize';

export default function YoutubeList({ apiData }) {
  const { isTablet } = useWindowSize()

  const getYoutubeList = (apiData) => {
    return apiData?.map(data => <div key={data.key}><YoutubeItem data={data} /></div>)
  }

  function NextArrow({ currentSlide, slideCount, slidesToShow, onClick }) {
    return (
      <div onClick={onClick} className={"next slick-arrow" + (currentSlide === slideCount - slidesToShow ? " slick-disabled" : "")}>
        <img src={slickArrow} alt="arrow_right" />
      </div>
    );
  }

  function PrevArrow({ currentSlide, onClick }) {
    return (
      <div onClick={onClick} className={"prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}>
        <img src={slickArrow} alt="arrow_left" />
      </div>
    );
  }

  const settings = {
    className: "slick",
    infinite: false,
    pauseOnFocus:true,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow slidesToShow={4} />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Div>
      <h1><img src={youtube} alt="youtube icon" />경매광장 유튜브 영상</h1>
      {!isTablet ?
        <div className="youtubeList">
        {getYoutubeList(apiData)}
        </div>
        :
        <Slider {...settings}>
        {getYoutubeList(apiData)}
        </Slider>
      }
      <button><a href="https://www.youtube.com/@auctionworld" target="_blank" rel="noopener noreferrer">유튜브 더보기</a><img src={arrow_right} alt="arrow right" /></button>
    </Div>
  )
}

const Div = styled.div`
  margin:120px auto 0;
  max-width:1200px;
  width:100%;
  h1{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    color: #191F28;
    font-size: 28px;
    font-weight: 700;
    margin-bottom:40px;
    >img{
      margin-right:14px;
      display: inline-block;
    }
  }
  .youtubeList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap:10px;
    height:188px;
  }
  >button{
    margin:46px auto 122px;
    width: 208px;
    height: 54px;
    border-radius: 4px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    transition:0.1s;
    >img{
      margin-left:10px
    }
    &:hover{
      background: #F8F8F9;
    }
  }

  .slick-slider{
    position:relative;
    .slick-slide{
      padding:0 6px;
    }
    .slick-disabled{
      display:none;
    }
    .prev, .next{
      top:calc((100% - 58px)/2 - 29px);
      z-index:1;
      position:absolute;
      width: 58px;
      height: 58px;
      cursor:pointer;
      >img{
        width:100%;
        height:100%;
      }
    }
    .prev{
      transform:rotate(180deg);
      left: -29px;
    }
    .next{
      right:-29px;
    }
  }



  @media (max-width: 767px) {
    margin-top: 70px;
    width:100% !important;
    .youtubeList{
      padding-left:18px;
      padding-right:18px;
      height:172px;
      overflow:scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
    }
    h1{
      font-size: 22px;
      margin-bottom:32px;
      >img{
        width: 51px;
        height: 22px;
      }
    }
    >button{
      margin:0 auto 70px;
      width: 180px;
      height: 50px;
      font-size: 14px;

    }
    .slick-slider{
      .prev, .next{
        display: none;
      }
    }
  }
`