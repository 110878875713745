import styled from "styled-components";
import arrow_down from '../../images/arrow_down.png'

import { useState } from "react";

export default function FNQ() {
  const [currentIdx, setIdx] = useState()
  const questionAndAnswer = [
    {
      question: "경매물건 어떻게 찾나요?",
      answer: "경매물건 검색에서 원하는 조건을 설정하시면 쉽게 찾으실 수 있습니다."
    },
    {
      question: "경매광장 코멘트는 어떤 것인가요?",
      answer: "경매물건 분석 시, 쉽게 찾아볼 수 없는 함정에 빠지는 것을 방지하기 위해 경매광장 선생님이 물건을 권리분석하여 위험성 또는 해결책까지 직접 분석하여 올립니다."
    },
    {
      question: "서비스 사용하려면 결제를 해야하나요?",
      answer: "현재는 가입회원에게 무료 사용기간을 적용해 드리고 있습니다. 이후 서비스가 유료화될 예정입니다."
    },
    {
      question: "등기부는 어떻게 보나요?",
      answer: "등기부 열람을 누른 뒤 1분정도 기다리시면 되고, 안 열릴 경우 고객센터로 문의주시면 신속히 처리해드리겠습니다."
    },
    {
      question: "등기부 열람료 결제해야하나요?",
      answer: "아니요, 무료 사용기간 동안에는 무료로 제공됩니다"
    },
  ]
  return (
    <Div isopened={currentIdx}>
      <div className="FNQ">
        {questionAndAnswer.map((FNQ, index) => {
          return (
            <div key={FNQ.question + FNQ.answer}>
              <div className="question" onClick={() => { setIdx(currentIdx === index ? null : index) }} >
                <div className="questionMark">Q</div>
                <span>
                  {FNQ.question}
                </span>
                <img src={arrow_down} className="arrow" alt="arrow" style={currentIdx === index ? { transform: "rotate(180deg)", transition: '0.2s' } : { transform: "rotate(0deg)" }} />
              </div>
              <div className="answer" style={currentIdx === index ? { display: "flex" } : { display: "none" }}>
                {FNQ.answer}
              </div>
            </div>
          )
        })}

      </div>
    </Div>
  )
}

const Div = styled.div`
  border-bottom:1px solid #EAEAEA;
  padding-bottom:36px;
  margin-bottom:${props=>props.isopened !== null ? '140px' : '206px'};

  .FNQ{
    max-width:1280px;
    min-width:324px;
    margin:16px auto 0;
    .question{
      margin-top:20px;
      height: 40px;
      display:flex;
      align-items:center;
      cursor:pointer;
      .questionMark{
        width:40px;
        height:40px;
        margin-right:18px;
        border:1px solid #EAEAEA;
        border-radius:50%;
        text-align:center;
        line-height:40px;
        color: #8B95A1;
        font-size: 20px;
        font-weight: 600;
      }
      .arrow{
        margin-left:auto;
      }
      span{
        color: #333D4B;
        font-weight: 500;
        cursor:pointer;
      }
      span:after{
        content:'';
        display: block;
        width:100%;
        height:1px;
        background-color: #333D4B;
        opacity: 0;
        transition:0.1s;
      }
    }
    .answer{
      height: 100%;
      min-height:58px;
      border-radius: 4px;
      background: #F2F4F6;
      height: 58px;
      align-items: center;
      padding:18px 24px;
      margin-top: 8px;
      color: #4E5968;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .FNQ >div:hover{
    .question{
      span:after{
        opacity: 1;
      }
    }
  }
  @media (max-width: 767px) {
    .FNQ{
      max-width:1280px;
      min-width:324px;
      margin:16px auto 0;
      .question{
        .questionMark{
        }
        .arrow{
        }
        span{
          font-size: 14px;
        }
      }
      .answer{;
        height: 100%;
        font-size: 13px;
        padding: 12px 18px;
      }

    }
  }
`