import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { purposeList } from "../../utils";
import styled from 'styled-components';
import { useWindowSize } from '../../customHook/useWindowSize';

export function Purpose({filterOptions, setFilterOptions, setSelected, close}) {
  const location = useLocation();
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()
  const [isAllChecked, setAllcheck] = useState(true)
  const [checkedList, setCheckedList] = useState([])
  const params = new URLSearchParams(location.search);

  const apply = (checkedList) => {
    let purposeParams = checkedList.length > 0 ? [...checkedList].join('+') : null
    purposeParams ? params.set('purpose', purposeParams) : params.delete('purpose');
    close()
    navigate(`/search?${params.toString()}`)
  }

  useEffect(() => {
    let purposeParams = params.get('purpose')?.split('+')
    purposeParams && setCheckedList(purposeParams)
    if(isMobile) return
    if (purposeParams?.length > 0) {
      setSelected({
        "content": purposeParams.length === 1 ? purposeCodeToName(purposeParams[0]) : purposeCodeToName(purposeParams[0]) + ' 외',
        "count": purposeParams.length === 1 ? 0 : purposeParams.length - 1,
      });
    } else {
      setSelected({content: '', count: 0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])


  useEffect(() => {
    setAllcheck(checkedList.length === 0)
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.purpose = checkedList
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList])

  const handleChecked = (value) => {
    let newCheckedList;
    if (checkedList.includes(value)) {
      newCheckedList = checkedList.filter(item => item !== value)
    } else {
      newCheckedList = [...checkedList]
      newCheckedList.push(value)
    }
    newCheckedList.length === 0 ? setAllcheck(true) : setAllcheck(false)
    setCheckedList(newCheckedList)
  }

  const purposeCodeToName = (codeToFind) => {
    const purpose = purposeList.find(item => item.code === codeToFind);
    return purpose?.name
  }

  const clickedStyle = {
    backgroundColor: '#fff',
    border: '1px solid #1A6DFF',
    color: '#1A6DFF',
    fontSize: '14px',
    fontWeight:'700',
  }

  return (
    <Div>
      <header>
        <div>물건종류</div>
        <span>중복 선택 가능</span>
      </header>
      <div className="listBox">
        <div style={isAllChecked ? clickedStyle : {}} onClick={() => { setAllcheck(!isAllChecked); setCheckedList([]) }}>전체</div>
        {purposeList.map((purpose) =>
          <div key={purpose.code} onClick={() => { handleChecked(purpose.code) }} style={checkedList.indexOf(purpose.code) !== -1 ? clickedStyle : {}}>
            {purpose.name}
          </div>
        )}
      </div>

      <div className="buttons">
        <button className="reset" onClick={() => { setAllcheck(true); setCheckedList([]); }}>초기화</button>
        <button className="apply" onClick={() => { apply(checkedList) }}>적용하기</button>
      </div>
    </Div>
  )
}

const Div = styled.div`
  .listBox{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:8px;
    >div{
      width: 94px;
      height: 48px;
      display: flex;
      justify-content:center;
      align-items:center;
      border-radius: 4px;
      background: #F2F4F6;
      color: #4E5968;
      font-size: 14px;
      font-weight: 500;
    }
  }
  @media (max-width: 767px) {
    .listBox{
      grid-gap:6px;
      >div{
        width: 76px;
        height: 48px;
        display: flex;
        justify-content:center;
        align-items:center;
        border-radius: 4px;
        background: #F2F4F6;
        color: #4E5968;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`