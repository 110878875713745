import styled from 'styled-components';
import ReactSelect, { components } from "react-select";
import arrow_down from "../../images/arrow_down.png"
import { useWindowSize } from '../../customHook/useWindowSize';

export default function Select(props) {
  const { isMobile } = useWindowSize()

  // option box box-shadow and border
  const customStyles = {
    container: (base) => ({
      ...base,
      height: '100%',
      borderRadius: "6px",
    }),
   
    menu: (provided) => ({
      ...provided,
      borderRadius: '6px',
      textAlign: 'left',
      paddingTop:"10px",
      boxShadow:"0 0 8px 0 rgba(0, 0, 0, 0.1)",
      paddingBottom:"10px",
      border: "solid 1px #edeeef",
      zIndex:"10",
      width:isMobile ? "140px" : "190px",
    }),

    menuList: (provided) => ({
      ...provided,
      // height:`${props.menuListHeight || 'inherit'}`,
    }),

    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? '#1A6DFF' : "#333",
      fontSize: "16px",
      fontWeight: isSelected ? '600' : "400",
      paddingLeft:'22px',
      backgroundColor: isFocused ? "#F8F8F9" : undefined,
      height:"44px",
      lineHeight:"32px",
      cursor:"pointer",
      ':active': {
        ...styles[':active'],
        backgroundColor:'rgba(0,0,0,0.2)'
      },
    }),

    control: (base,{isFocused}) => ({
      ...base,
      backgroundColor: 'white',
      borderRadius: '6px',
      textAlign: 'left',
      height: '100%',
      paddingLeft:'6px',
      cursor:"pointer",
      // This line disable the blue border
      border: isFocused? `solid 1px #1A6DFF`:`solid 1px #black'`,
      boxShadow: 'none',
      // This line disable the blue border
      "&:hover": {
        backgroundColor: "#F8F8F9",
      },
      ...props.controlStyle
    }),

    valueContainer: (base) => ({
      ...base,
      ...props.ValueContainerStyle
    }),


    placeholder:(base) => ({
      ...base,
      fontFamily: "Pretendard",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "-0.01px",
      color: "#5d5d5d",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
      paddingRight:'16px',
      paddingLeft:'16px'
    }),

    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "600",
      color: "#1A6DFF",
      ...props.singleValueStyle
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrow_down} alt="arrow down"/>
      </components.DropdownIndicator>
    );
  };

  return (
    <Div>
      <ReactSelect
        {...props}
        styles={customStyles}
        isSearchable={false}
        // defaultValue={props.defaultValue || props.options[0]}
        defaultValue={props.defaultValue}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
      />
    </Div>
  )
}

const Div = styled.div`
  height:100%;
`