import profile from "../../images/profile.png"
import styled from "styled-components"

export default function AuctionComment({ comment }) {

  return (
    <Div>
      <h2>경매광장 코멘트</h2>
      <div>
        <div className="profile">
          <img src={profile} alt={profile} />
        </div>
        <div className="balloon">
          <div className="title">
            <div className="tip">📢 Tip!</div>
            <div className="text">경매광장의 핵심 포인트</div>
          </div>
          <div className="comment">
            {comment?.length > 0 ?
              comment.map((comment) => {
                return (
                  <div className="comment">
                    <span>✔️ </span>{comment}
                  </div>
                )
              })
              :
              <div className="comment">
                코멘트 준비중입니다! 업데이트 하도록 하겠습니다.
              </div>
            }
          </div>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  margin:44px 0 140px;
  >h2{
    color: #191F28;
    font-size: 22px;
    font-weight: 700;
  }
  >div{
    margin-top:28px;
    display: flex;
    .profile{
      width: 60px;
      height: 60px;
      background: #F2F6F9;
      border-radius:50%;
      display: flex;
      justify-content:center;
      align-items:center;
      margin-right:16px;
    }
    .balloon{
      flex: 1;
      min-height: 168px;
      fill:#fff;
      box-shadow:0px 0px 16px rgba(0, 0, 0, 0.10);
      border-radius:0 16px 16px 16px ;
      padding:32px 42px;
      .title{
        display: flex;
        align-items:center;
        .tip{
          display: flex;
          align-items:center;
          justify-content:center;
          width: 52px;
          height: 24px;
          border-radius: 100px;
          border: 1px solid rgba(26, 109, 255, 0.80);
          background: rgba(238, 246, 255, 0.70);
          color: #1A6DFF;
          font-size: 10px;
          font-weight: 700;
        }
        .text{
          color: #333D4B;
          font-size: 18px;
          font-weight: 700;
          margin-left:6px;
        }
      }
    }
    .comment{
      margin-top:24px;
      color: #1A6DFF;
      font-size: 15px;
      font-weight: 500;
    }
  }
  @media (max-width: 1200px) {
    margin:44px auto 140px !important;
  }
  @media (max-width: 767px) {
    margin-top:36px !important;
    margin-bottom:88px !important;
    >h2{
      font-size: 20px;
    }
    >div{
      margin-top:16px;
      .profile{
        display: none;
      }
      .balloon{
        padding:22px 22px 28px;
        height: inherit;
        .title .text{
          font-size: 16px;
        }
        .comment{
          margin-top:20px;
          .comment{
            font-size:14px;
          }
        }
      }
    }
  }
`