import styled from 'styled-components';

export default function YoutubeItem({data}){
  return(
    <Div onClick={()=>{ window.open(`https://www.youtube.com/watch?v=${data.link}`, '_blank')}}>
      <img className="thumbnail" src={`https://img.youtube.com/vi/${data.link}/mqdefault.jpg`} alt="youtube Thumbnail" />
      <div>{data.title}</div>

    </Div>
  )
}

const Div = styled.div`
  max-width:230px;
  height:130px;
  margin-bottom:42px;
  cursor:pointer;
  >img{
    width:100%;
    height:100%;
    display:block;
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
  >div{
    margin-top:14px;
    width:100%;
    color: #333D4B;
    font-weight: 600;
    height:44px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 767px) {
    >img{
      width:144px;
      height: 90px;
    }
    >div{
      height: 40px;
      margin-top:10px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`