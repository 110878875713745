import styled from 'styled-components';
import AuctionItemCard from './auctionItemCard'

export default function AuctionItemList({ apiData }) {
  return (
    <Div>
      {apiData.map(data => <div key={data.key}><AuctionItemCard data={data} /></div>)}
    </Div>
  );
}

const Div = styled.div`
  max-width:1200px;
  width:100%;
  margin:0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap:30px 24px;
  text-align:left;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:30px 14px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    >div>div{
      height:380px;
      >img{
        height:196px;
      }
      >div{
        height:184px;
        padding-top:14px !important;
      }
    }
  }
  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`