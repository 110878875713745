import styled from "styled-components";
import { useEffect, useState } from "react";
import { useModal } from "../../customHook/useModal";
import { useWindowSize } from "../../customHook/useWindowSize";
import { Region } from "../dropdown/region";
import { Purpose } from "../dropdown/purpose";
import { Appraisal } from "../dropdown/appraisal";
import { BidingPrice } from "../dropdown/bidingPrice";
import { Keywords } from "../dropdown/keywords";
import { Area } from "../dropdown/area";
import { AuctionState } from "../dropdown/auctionState";
import { useNavigate, useLocation } from "react-router-dom";


export default function FilterModal() {
  const navigate = useNavigate()
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { modalClose } = useModal()
  const { innerWidth, innerHeight, isMobile } = useWindowSize()
  const [filterOptions, setFilterOptions] = useState({address:[], purpose:[], minAppraisal:0, maxAppraisal:2000000000, minBidding:0, maxBidding:2000000000, keywords:[], minLand:0, maxLand:300, minBuilding:0, maxBuilding:300, auctionState:[]})

  const apply = (filterOptions) => {

    let addressParams = filterOptions.address.length > 0 ? [...filterOptions.address].join('+') : null
    addressParams ? params.set('address', addressParams) : params.delete('address');
 
    let purposeParams = filterOptions.purpose.length > 0 ? [...filterOptions.purpose].join('+') : null
    purposeParams ? params.set('purpose', purposeParams) : params.delete('purpose');

    filterOptions.minAppraisal > 0 ? params.set('minAppraisalValue', filterOptions.minAppraisal) : params.delete('minAppraisalValue')
    filterOptions.maxAppraisal < 2000000000 ? params.set('maxAppraisalValue', filterOptions.maxAppraisal) : params.delete('maxAppraisalValue')

    filterOptions.minBidding > 0 ? params.set('minBidPrice', filterOptions.minBidding) : params.delete('minBidPrice')
    filterOptions.maxBidding < 2000000000 ? params.set('maxBidPrice', filterOptions.maxBidding) : params.delete('maxBidPrice')

    let keywordsParams = filterOptions.keywords.length > 0 ? [...filterOptions.keywords].join('+') : null
    keywordsParams ? params.set('keywords', keywordsParams) : params.delete('keywords');

    let auctionStateParams = filterOptions.auctionState.length > 0 ? [...filterOptions.auctionState].join('+') : null
    auctionStateParams ? params.set('auctionState', auctionStateParams) : params.delete('auctionState');

    filterOptions.minLand > 0 ? params.set('minLandArea', filterOptions.minLand*3.3) : params.delete('minLandArea')
    filterOptions.maxLand < 300 ? params.set('maxLandArea', filterOptions.maxLand*3.3) : params.delete('maxLandArea')
    filterOptions.minBuilding > 0 ? params.set('minBuildingArea', filterOptions.minBuilding*3.3) : params.delete('minBuildingArea')
    filterOptions.maxBuilding < 300 ? params.set('maxBuildingArea', filterOptions.maxBuilding*3.3) : params.delete('maxBuildingArea')

    modalClose(null)
    navigate(`/search?${params.toString()}`)
  }

  useEffect(()=>{
    let newFilterOptions = {}
    let addressParams = params.get('address')?.split('+') || []
    let purposeParams = params.get('purpose')?.split('+') || []

    let minAppraisalValueParams = parseInt(params.get('minAppraisalValue')) || 0
    let maxAppraisalValueParams = parseInt(params.get('maxAppraisalValue')) || 2000000000

    let minBidPriceParams = parseInt(params.get('minBidPrice')) || 0
    let maxBidPriceParams = parseInt(params.get('maxBidPrice')) || 2000000000

    let keywordsParams = params.get('keywords')?.split('+') || []

    let auctionStateParams = params.get('auctionState')?.split('+') || []
 
    let minLandAreaParams = parseInt(params.get('minLandArea')/3.3) || 0
    let maxLandAreaParams = parseInt(params.get('maxLandArea')/3.3) || 300
    let minBuildingAreaParams = parseInt(params.get('minBuildingArea')) || 0
    let maxBuildingAreaParams = parseInt(params.get('maxBuildingArea')) || 300

    newFilterOptions.address = addressParams
    newFilterOptions.purpose = purposeParams
    newFilterOptions.minAppraisal = minAppraisalValueParams
    newFilterOptions.maxAppraisal = maxAppraisalValueParams
    newFilterOptions.minBidding = minBidPriceParams
    newFilterOptions.maxBidding = maxBidPriceParams
    newFilterOptions.keywords = keywordsParams
    newFilterOptions.minLand = minLandAreaParams
    newFilterOptions.maxLand = maxLandAreaParams
    newFilterOptions.minBuilding = minBuildingAreaParams
    newFilterOptions.maxBuilding = maxBuildingAreaParams
    newFilterOptions.auctionState = auctionStateParams

    setFilterOptions(newFilterOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(()=>{
    !isMobile && modalClose() 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isMobile])

  return (
    <Div $innerWidth={innerWidth} $innerHeight={innerHeight}>
      <div className="sticky">
        검색필터
      </div>
      <div className="content">
        <Region filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <div className="separateBar"></div>
        <Purpose filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <div className="separateBar"></div>
        <Appraisal filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <BidingPrice filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <div className="separateBar"></div>
        <Keywords filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <div className="separateBar"></div>
        <Area filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <div className="separateBar"></div>
        <AuctionState filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
        <div className="separateBar"></div>
        <div className="buttons">
        <button className="reset" onClick={() => { navigate('/search');modalClose()}}>초기화</button>
        <button className="apply" onClick={() => { apply(filterOptions);modalClose() }}>적용하기</button>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width:${(props) => props.$innerWidth}px;
  height:${(props) => props.$innerHeight}px;
  .sticky{
    position:fixed;
    top:0;
    width:100%;
    height:64px;
    background-color: white;
    color: #333D4B;
    font-weight: 700;
    line-height:64px;
    border-bottom:1px solid #EAEAEA;
    z-index:100;
  }

  .content{
    padding-top:64px;
    overflow: scroll;
    height: calc(100%);
    >.buttons{
      min-width:360px;
      max-width:360px;
      margin:18px auto;
      display: flex;
      justify-content:center;
      >button{
        width: 106px;
        height: 44px;
        margin:0 5px;
        border-radius: 4px;
      }
      .reset{
        width:106px;
        border: 1px solid #D7D7D7;
        color: #333D4B;
        font-weight: 500;
        background: #FFF;
      }
      .apply{
        width: 208px;
        background: #1A6DFF;
        color: #FFF;
        font-weight: 700;
      }
    }
    .separateBar{
      background: #EAEAEA;
      width:100% !important;
      height:1px;
      margin-top:26px;
    }
    >div{
      width:324px;
      margin:0 auto;
      .content{
        margin-top: 14px;
        border-radius: 8px;
        border: 1px solid #E1E1E1;
        background: #FFF;
        position: absolute;
        padding:34px 34px 29px;
        z-index:20;
      }
      header{
        display: flex;
        align-items:center;
        margin-bottom:14px;
        margin-top: 27px;
        >div{
          color: #333D4B;
          font-size: 20px;
          font-weight: 600;
          }
        >span{
          display: flex;
          justify-content:center;
          align-items:center;
          width: 93px;
          height: 26px;
          border-radius: 100px;
          border: 1px solid #1A6DFF;
          background: #FFF;
          margin-left:8px;
          color: #1A6DFF;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .buttons{
        display:none;
        margin-top:28px;
        padding-top:28px;
        border-top:1px solid #EAEAEA;
        /* display: flex; */
        justify-content:center;
        >button{
          width: 106px;
          height: 44px;
          margin:0 5px;
          border-radius: 4px;
        }
        .reset{
          border: 1px solid #D7D7D7;
          color: #333D4B;
          font-weight: 500;
          background: #FFF;
        }
        .apply{
          background: #1A6DFF;
          color: #FFF;
          font-weight: 700;
        }
      }
    }
    .slideInput{
      white-space:nowrap;
      display: flex;
      align-items: center;
      >div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      label{
        color: #8B95A1;
        font-weight: 500;
        font-size:15px;
        margin-bottom:6px;
      }
      input{
        width: 146px;
        height: 44px;
        border-radius: 4px;
        border: 1px solid #E1E1E1;
        text-align:center;
      }
      input:focus{
        border: 1px solid #1A6DFF;
      }
      span{
        display: inline-block;
        margin:16px 10px 0;
      }
    }
    .slide{
      border-bottom:none;
    }
  }
`