import styled from "styled-components"
import { useState } from "react";
import { addressObject, purposeList } from "../utils";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "./select/select";
import searchIcon from '../images/search_icon.svg';
import { useModal } from "../customHook/useModal";



export default function SearchbarMain() {
  const { modalShow, modalClose } = useModal()

  const navigate = useNavigate()
  const location = useLocation();
  const [isAddressSearch, setAddressSearch] = useState(true)
  const [val1, setVal1] = useState(null);
  const [val2, setVal2] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [caseNumber, setCaseNumber] = useState('')
  const { sido, sigugun } = addressObject;
  const params = new URLSearchParams(location.search);

  const sidoCategoryOptions = [{ value: "all", label: "전체" }]
  for (let i = 0; i < sido.length; i++) {
    sidoCategoryOptions.push({ value: sido[i].sido, label: sido[i].codeNm })
  }

  const sigugunCategoryOptions = []
  let selectedSigugun = sigugun.filter((el) => el.sido === val1?.value)
  for (let i = 0; i < selectedSigugun.length; i++) {
    sigugunCategoryOptions.push({ value: selectedSigugun[i].sigugun, label: selectedSigugun[i].codeNm })
  }
  sigugunCategoryOptions.sort((a, b) => a.label.localeCompare(b.label));

  const typeCategoryOptions = []
  for (let i = 0; i < purposeList.length; i++) {
    typeCategoryOptions.push({ value: purposeList[i].code, label: purposeList[i].name })
  }

  const getAddressForSearch = () => {
    const getAddressPart = (val, data, keyToCompare) => {
      if (val) {
        const info = data.find(item => item[keyToCompare] === val);
        if (info) {
          return info[keyToCompare];
        }
      }
      return '';
    }
    if(val1?.value === 'all'){
      return 'all'
    }
    const sidoPart = getAddressPart(val1?.value, sido, 'sido');
    const sigugunPart = getAddressPart(val2?.value, sigugun, 'sigugun');
    const addressParts = [sidoPart, sigugunPart].filter(part => part !== '');
    return addressParts.join('');
  };

  const onSearch = () => {
    if (isAddressSearch) {
      const address = getAddressForSearch();
      if (address === '' && purpose === '') {
        modalShow({message:'주소 혹은 종류를 선택해주세요.',submessage: "",actionButton: <div onClick={() => { modalClose(); }}>닫기</div>})
        return;
      }
      if (address !== '' && address !== 'all') {
        params.set('address', address);
      }
    } else {
      if (caseNumber === '') {
        modalShow({message:'사건번호를 입력해주세요.',submessage: "",actionButton: <div onClick={() => { modalClose(); }}>닫기</div>})
        return;
      }
      params.set('caseNumber', caseNumber);
    }
    if (purpose !== '') {
      params.set('purpose', purpose);
    }
    navigate(`/search?${params.toString()}`)
  };

  const handleVal1Change = (selectedOption) => {
    // 첫 번째 select 값이 변경될 때 호출
    setVal1(selectedOption);
    setVal2(null); // 두 번째 select 값을 초기화
  };

  const handleVal2Change = (selectedOption) => {
    // 두 번째 select 값이 변경될 때 호출
    setVal2(selectedOption);
  };

  return (
    <Div>
      <div>

      <div className="searchTop">
        <div className={isAddressSearch ? "active" : null} onClick={() => { setAddressSearch(true) }}>주소검색</div>
        <div className={isAddressSearch ? null : "active"} onClick={() => { setAddressSearch(false) }}>사건번호검색</div>
      </div>
      {isAddressSearch ?
        <div className="searchBottom">
          <div className="selectContainer">
            <Select
              className="select"
              onChange={handleVal1Change}
              value={val1}
              options={sidoCategoryOptions}
              placeholder="시/도"
            />
            <Select
              className="select sigugun"
              onChange={handleVal2Change}
              value={val2}
              isDisabled={!val1 || val1?.value === "all"}
              options={sigugunCategoryOptions}
              placeholder="시/군/구"
            />
            <Select
              className="select type"
              onChange={(e) => { setPurpose(e.value) }}
              options={typeCategoryOptions}
              placeholder="종류"
            />

          </div>
          <button className="searchButton" onClick={() => { onSearch() }}>
            <img src={searchIcon} alt="search_icon" />
            <span>검색</span>
          </button>
        </div>

        :
        <div className="searchBottom">
          <input
            value={caseNumber}
            type="text"
            onChange={(e) => setCaseNumber(e.target.value)}
            placeholder="사건번호를 입력해주세요."
          />
          <button className="searchButton" onClick={() => { onSearch() }}>
            <img src={searchIcon} alt="search_icon" />
            <span>검색</span>
          </button>
        </div>
      }
      </div>
    </Div>
  );
}

const Div = styled.div`
  >div{
    position: relative;
    max-width: 860px;
    width: 87%;
    height: 158px;
    margin:0 auto;
    border-radius:10px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
    margin-top:-79px;
    margin-bottom:118px;
    background-color: white;
    .searchTop{
      margin:0 30px;
      border-bottom:1px solid #EAEAEA;
      display: flex;
      >div{
        color: #8B95A1;
        font-weight: 600;
        margin-top:26px;
        padding-bottom:19px;
        margin-right:16px;
        cursor:pointer;
        transition:0.1s;
        border-bottom: none;
      }
      .active{
        color: #1A6DFF;
        font-weight: 800;
        border-bottom: 3px solid #1A6DFF;
      }
    }
    .searchBottom{
      display:flex;
      justify-content:space-between;
      margin: 0 30px;
      margin-top: 22px;
      .selectContainer{
        display: flex;
        .select{
          width:114px;
          height:48px;
          margin-right:7px;
        }
        .sigugun ,.type{
          width:133px;
        }
      }
      input{
        width:calc(100% - 122px);
        padding:0 26px;
        border-radius: 6px;
        border: 1px solid #E1E1E1;
        font-weight: 600;
      }
      input::placeholder{
        color: #B0B8C1;
      }
      input:focus{
        border: 1px solid #1A6DFF;
        box-shadow: 0px 0px 4px 0px #1A6DFF;
      }
  
    }
  
    .searchButton{
      width: 102px;
      height: 48px;
      border-radius: 6px;
      background: #1A6DFF;
      color: #FFF;
      font-weight: 600;
      >img{
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      >span{
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 14px;
        margin-left:8px;
      }
    }
  }


  @media (max-width: 1023px) {
    padding:0 50px;
    >div{
      width:100%;
    }
  }
  @media (max-width: 767px) {

  }
  
`