import styled from "styled-components"
import ViewMore from "./viewMore"

export default function AuctionAppraisal({appraisalList, viewMore, setViewMore}) {
  const getAppraisal = (appraisalList) => { // 순서 바뀔일 없어서 index를 key값으로 사용
    return appraisalList?.map((appraisal, index) => {
      return (
        <div key={index}>
          <h3>{appraisal.header}</h3>
          <div>
            {appraisal.dataList.map((data, idx) => {
              return (
                <div key={`${index}${idx}`}>
                  <h4>{data.title}</h4>
                  <p>{data.content}</p>
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }

  const getLength = (appraisalList) => {
    let length = 0
    appraisalList?.forEach((apprasial)=>{
      length += apprasial.dataList.length
    })
    return length
  }

  return (
    <Div>
      <h2>현황 및 이용상태</h2>
      <div className="content" style={viewMore[1] ? {maxHeight:'initial'} : {maxHeight:'422px'}}>{getAppraisal((appraisalList))}</div>
      {getLength(appraisalList) > 5 && <ViewMore index={1} viewMore={viewMore} setViewMore={setViewMore}/>}
    </Div>
  )
}

const Div = styled.div`
  margin: 44px 0 0px;
  padding-bottom: 44px;
  position:relative;
  h2{
    color: #191F28;
    font-size: 22px;
    font-weight: 700;
    margin-bottom:28px;
  }
  h3, h4, p{
    margin-bottom:15px;
  }
  >div *{
    color: #4E5968;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
  .content{
    overflow: hidden; // 더보기용
  }
  @media (max-width: 767px) {
    h2{
      font-size: 20px;
      margin:36px 0 22px;
    }
    >div *{
      font-size: 13px;
    }
  }
`