import styled from "styled-components"

export default function ViewMore({ index, viewMore, setViewMore }) {

  const handleClick = () => {
    let newViewMore = [...viewMore]
    newViewMore[index] = true
    setViewMore(newViewMore)
  }

  return (
    <Div>
      {!viewMore[index] &&
        <div className="viewMore">
          <div className="blur" />
          <div className="button" onClick={() => {handleClick()}}>
            <div>
              더보기
            </div>
          </div>
        </div>
      }
    </Div>
  )
}

const Div = styled.div`
  @media (max-width: 767px) {
    .viewMore{
      .button{
        div{
          width: 120px !important;
          height: 40px !important;
          font-size:14px !important;
          line-height:40px !important;
        }
      }
    }
  }
`