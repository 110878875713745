import { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import arrow_down from "../../images/arrow_down.png"

export function Dropdown({ menu, openedMenu, openMenu, selected, children }) {
  const menuRef = useRef(null);
  const selectRef = useRef(null);
  const [contentLeft, setContentLeft] = useState(0)

  const closeMenu = (event) => {
    if (selectRef.current && selectRef.current.contains(event.target)) {
      return; // 클릭된 요소가 .selectBox인 경우, 아무 작업도 수행하지 않음
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      openMenu(null); // 닫기
    }
  };

  useEffect(() => {
    const parentRect = document.getElementsByClassName('SearchbarTopWrapper')[0].getBoundingClientRect();
    const childRect = menuRef.current.getBoundingClientRect();
    if (childRect.right > parentRect.right) {
      setContentLeft(parentRect.right - childRect.width);
    } // drop down content가 부모 너비를 넘어갈때 안넘어가게 조정

    if (openedMenu === menu) {
      document.addEventListener('mousedown', closeMenu);
    } else {
      document.removeEventListener('mousedown', closeMenu);
    }
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedMenu]);


    return (
    <Div $isOpened={openedMenu === menu}>
      <div ref={selectRef} className="selectBox" onClick={() => {openMenu(openedMenu === menu ? null : menu)}}>
        <div>{menu}</div>
        {selected.content && <div className="selected">{selected.content}</div>}
        <img src={arrow_down} alt="arrow down"/>
      </div>
      {selected.count !== 0 && 
      <div className="selectedCnt">
        <div>
        {selected.count}
        </div>
        </div>
      }
      <div ref={menuRef} className="content" style={{display:openedMenu === menu ? "block" : "none", left:contentLeft > 0 ? contentLeft : ''}}>
        {children}
      </div>
    </Div>
    )
}

const Div = styled.div`
  position:static;
  .selectBox{
    min-width: 114px;
    height: 48px;
    margin-right:12px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    border-color: ${props => props.$isOpened ? "#1A6DFF !important" : ""};
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;
    >div{
      margin-left: 20px;
      white-space:nowrap;
    }
    .selected{
      margin:0px;
      color: #1A6DFF;
      font-weight: 700;
      transform:translateX(10px);
      white-space:nowrap;
    }
    > img{
      margin:0 20px;
      transition:0.2s;
      transform:${props => props.$isOpened ? "rotate(180deg)" : "rotate(0deg)"};
      display: block;
      width:11px;
      height:8px;
    }
  }
  .selectedCnt{
    position: relative;
    top: -48px;
    >div{
      width: 22px;
      height: 22px;
      background-color: #1A6DFF;
      color: #FFF;
      font-size: 13px;
      font-weight: 500;
      border-radius:50%;
      display: flex;
      justify-content:center;
      align-items:center;
      position:absolute;
      top: -11px;
      right: 0px;
    }
  }
  .selectBox:hover{
    background: #F8F8F9;
  }
  .content{
    margin-top: 14px;
    border-radius: 8px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    position: absolute;
    padding:34px 34px 29px;
    z-index:20;
    >div{
      >header{
        display: flex;
        align-items:center;
        margin-bottom:26px;
        >div{
          color: #333D4B;
          font-size: 22px;
          font-weight: 600;
        }
        >span{
          display: flex;
          justify-content:center;
          align-items:center;
  
          width: 93px;
          height: 26px;
          border-radius: 100px;
          border: 1px solid #1A6DFF;
          background: #FFF;
          margin-left:8px;
          color: #1A6DFF;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .buttons{
        margin-top:28px;
        padding-top:28px;
        border-top:1px solid #EAEAEA;
        display: flex;
        justify-content:center;
        >button{
          width: 106px;
          height: 44px;
          margin:0 5px;
          border-radius: 4px;
        }
        .reset{
          border: 1px solid #D7D7D7;
          color: #333D4B;
          font-weight: 500;
          background: #FFF;
        }
        .apply{
          background: #1A6DFF;
          color: #FFF;
          font-weight: 700;
        }
      }
    }
  }
  
  .slideInput{
    white-space:nowrap;
    padding-bottom:24px;
    border-bottom:1px solid #EAEAEA;
    display: flex;
    align-items: center;
    label{
      color: #8B95A1;
      font-weight: 500;
    }
    input{
      width: 140px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #E1E1E1;
      text-align:center;
      margin-left:12px;
    }
    input:focus{
      border: 1px solid #1A6DFF;
    }
    span{
      display: inline-block;
      margin:0 16px;
    }
  }
`