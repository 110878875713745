import { useMemo, useEffect, useState } from "react";
import { Region } from "./dropdown/region"
import { Purpose } from "./dropdown/purpose"
import { Appraisal } from "./dropdown/appraisal"
import { BidingPrice } from "./dropdown/bidingPrice"
import { Area } from "./dropdown/area"
import { Keywords } from "./dropdown/keywords"
import { AuctionState } from "./dropdown/auctionState"
import { SelectSearchType } from "./selectSearchType";
import { Dropdown } from "./dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import search from '../images/search.svg';
import searchIcon from '../images/search_icon.svg';

import arrowRight from '../images/arrow_right.png';
import filter from '../images/filter.svg';
import { useWindowSize } from "../customHook/useWindowSize";
import { useModal } from "../customHook/useModal";


export function SearchbarTop({ topPosition, marginPoint, buttonDirection, setButtonDireiction }) {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate()
  const initialState = { "content": "", "count": 0 }
  const [type, setType] = useState(true); // true: 경매물건검색, false: 사건번호검색
  const [paramsSize, setParamsSize] = useState(0);
  const [openedMenu, openMenu] = useState(null)
  const [selectedRegion, setRegion] = useState(initialState)
  const [selectedPurpose, setPurpose] = useState(initialState)
  const [selectedAppraisal, setAppraisal] = useState(initialState)
  const [selectedBidingPrice, setBidingPrice] = useState(initialState)
  const [selectedKeywords, setKeywords] = useState(initialState)
  const [selectedArea, setArea] = useState(initialState)
  const [selectedAuctionState, setAuctionState] = useState(initialState)
  const { isMobile, innerWidth } = useWindowSize()
  const { filterModal } = useModal()
  const [caseNumber,setCaseNumber] = useState('')

  const scrollButton = (buttonDirection) => {
    const selectGroup = document.querySelector('.selectGroup');
    if (buttonDirection === 'right') {
      // Scroll to the right
      selectGroup.scrollLeft += 2000;
      setButtonDireiction('left')
    } else {
      // Scroll to the left
      selectGroup.scrollLeft -= 2000;
      setButtonDireiction('right')
    }
  }

  useEffect(()=>{
    openedMenu && openMenu(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[innerWidth])

  useEffect(()=>{
    if(!isMobile){return}
    let count = 0;
    params.forEach((value, key) => {
      if (key !== "page") { // "page" 파라미터는 제외
        count++;
      }
    });
    setParamsSize(count)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params,isMobile])

  useEffect(()=>{
    let caseNumber = params.get('caseNumber')
    setCaseNumber(caseNumber)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params])

  return (
    <Div $topPosition={topPosition} $marginPoint={marginPoint}>
      <div className="SearchbarTopWrapper">
        <SelectSearchType type={type} setType={setType} />
        {type ?
          <div className="selectGroup">
            {!isMobile ?
              <>
                {buttonDirection === "left" &&
                  <div className="leftButton direction" onClick={() => { scrollButton(buttonDirection) }}>
                    <div>
                      <img src={arrowRight} alt="arrow left" />
                    </div>
                  </div>
                }
                <div className="dropdowns">
                  <Dropdown menu="지역" openedMenu={openedMenu} openMenu={openMenu} selected={selectedRegion}>
                    <Region setSelected={setRegion} close={()=>{openMenu(null)}} />
                  </Dropdown>

                  <Dropdown menu="물건종류" openedMenu={openedMenu} openMenu={openMenu} selected={selectedPurpose}>
                    <Purpose setSelected={setPurpose} close={()=>{openMenu(null)}} />
                  </Dropdown>

                  <Dropdown menu="감정가" openedMenu={openedMenu} openMenu={openMenu} selected={selectedAppraisal}>
                    <Appraisal setSelected={setAppraisal} close={()=>{openMenu(null)}} />
                  </Dropdown>

                  <Dropdown menu="최저가" openedMenu={openedMenu} openMenu={openMenu} selected={selectedBidingPrice}>
                    <BidingPrice setSelected={setBidingPrice} close={()=>{openMenu(null)}} />
                  </Dropdown>

                  <Dropdown menu="특수권리" openedMenu={openedMenu} openMenu={openMenu} selected={selectedKeywords}>
                    <Keywords setSelected={setKeywords} close={()=>{openMenu(null)}} />
                  </Dropdown>
                  <Dropdown menu="면적" openedMenu={openedMenu} openMenu={openMenu} selected={selectedArea}>
                    <Area setSelected={setArea} close={()=>{openMenu(null)}} />
                  </Dropdown>

                  <Dropdown menu="진행상태" openedMenu={openedMenu} openMenu={openMenu} selected={selectedAuctionState}>
                    <AuctionState setSelected={setAuctionState} close={()=>{openMenu(null)}} />
                  </Dropdown>

                </div>
                {buttonDirection === "right" &&
                  <div className="rightButton direction" onClick={() => { scrollButton(buttonDirection); }}>
                    <div>
                      <img src={arrowRight} alt="arrow right" />
                    </div>
                  </div>
                }
              </>
              :
              <div className="filter" onClick={() => { filterModal()}}>
                필터
                <img src={filter} alt="filter" />
                {paramsSize !== 0 && <div>{paramsSize}</div>}
              </div>
            }

          </div>
          :
          <div className="search">
            <img src={search} alt="search_icon" />
            <form onSubmit={(e)=>{e.preventDefault();navigate(`/search?caseNumber=${caseNumber}`)}}>
              <input
                type="text"
                // value={caseNumber}
                onChange={(e) => {
                  setCaseNumber(e.target.value);
                }}
                placeholder="사건번호를 입력해주세요."
              />
              <button className="searchButton">
                <img src={searchIcon} alt="search_icon" />
                <span>검색</span>
              </button>
            </form>
          </div>
        }
      </div>
    </Div>
  );
}

const Div = styled.div`
  max-width:1920px;
  margin:0 auto;
  border-bottom:1px solid #EAEAEA;
  .SearchbarTopWrapper{
    max-width:1200px;
    width:calc(100% - 80px);
    margin:0 auto;
  }
  .direction{
    width: 100px;
    height: 48px;
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    position: absolute;
    top: ${(props) => `${props.$topPosition}`};
    right:${props => `${props.$marginPoint}`};

    display: flex;
    justify-content:right;
    align-items:center;
    z-index: 10;
    >div{
      width: 26px;
      height: 26px;
      background:#fff ;
      border:1px solid #E1E1E1;
      border-radius:50%;
      display: flex;
      justify-content:center;
      align-items:center;
      >img{
        display: block;
      }
    }
  }
  .rightButton{

  }
  .leftButton{
    left:${props => `${props.$marginPoint}`};
    transform:rotate(180deg);
  }
  .selectGroup{
    padding-top:34px;
    margin-bottom:32px;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  .selectGroup::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .selectGroup {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .dropdowns{
    display:flex;
    transition: transform 0.3s;
  }

  .search{
    display:flex;
    margin: 0px 0px 28px;
    padding-top: 34px;
    position:relative;
    >img{
      position:absolute;
      left:32px;
      bottom:19px;
      width: 14px;
      height: 14px;
    }
    form{
      width:100%;
      display: flex;
    }
    .searchButton{
      width: 102px;
      height: 100%;
      margin-left:12px;
      border-radius: 6px;
      background: #1A6DFF;
      color: #FFF;
      font-weight: 600;
      >img{
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      >span{
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 14px;
        margin-left:8px;
      }
    }
    input{
      max-width: calc(657px - 108px); // 108 = search button with + margin left
      width:100%;
      height:52px;
      padding:0 68px;
      border-radius: 6px;
      border: 1px solid #E1E1E1;
      font-weight: 600;
    }
    input::placeholder{
      color: #B0B8C1;
      font-size: 18px;
      font-weight: 500;
    }
    input:focus{
      border: 1px solid #1A6DFF;
      box-shadow: 0px 0px 4px 0px #1A6DFF;
    }
  }
  .filter{ // for mobile
    width: 92px;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #1A6DFF;
    color: #1A6DFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    position:relative;
    >img{
      margin-left:10px;
    }
    >div{
      position:absolute;
      top:-8px;
      right:-10px;
      width: 22px;
      height: 22px;
      line-height:22px;
      text-align:center;
      background-color: #1A6DFF;
      border-radius:50%;
      color: #FFF;
      font-size: 12px;
      font-weight: 700;

    }
  }

  .filterPopup{
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:white;
  }
  
  @media (max-width: 767px) {
    .SearchbarTopWrapper{
      width:calc(100% - 36px);
    }
    .selectGroup{
      padding-top:18px;
      margin-bottom:24px;
    }
    .search {
      padding-top:18px;
      margin-bottom:24px;
      img{
        left:18px;
        bottom:14px;
      }
      input{
        height:42px;
        padding:0 50px;
      }
      input::placeholder{
        font-size:16px;
      }
      .searchButton{
        height: 42px;
        margin-left:6px;
        font-weight: 500;
        >span{
          margin-top: 11px;
          margin-bottom: 10px;
          margin-left:6px;
        }
      }
    }
  }
`
