import { Routes, Route, } from "react-router-dom";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Like from '../components/mypage/like'
import Question from '../components/mypage/question'
import FNQ from '../components/mypage/fnq'
import NotFound from './notFound'
import { useAuth } from "../customHook/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { useBlock } from "../customHook/useBlock";
import { useEffect } from "react";
import profile from '../images/user.svg'


export default function Mypage() {
  const { user } = useAuth()
  const location = useLocation()
  const { isBlock } = useBlock()
  const navigate = useNavigate()

  


  const navigationList = [
    {
      url: '/mypage/like',
      title: '관심물건 리스트',
    },
    {
      url: '/mypage/question',
      title: '1:1 문의',
    },
    {
      url: '/mypage/FNQ',
      title: '자주하는 질문',
    },
  ]

  useEffect(()=>{
    if(!isBlock){
      if(!user){
        navigate('/login')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isBlock,user])

  useEffect(() => {
    document.documentElement.scrollTo({top: 0,left: 0,behavior: "instant"});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div>
      <div className="userInfo">
        <div>
          <img src={profile} alt="profile"></img>
        </div>
        <div>
          <div className="name">{user?.name}님</div>
          <div>경매광장에 오신걸 환영합니다!</div>
        </div>
      </div>
      <ul>
        {navigationList.map((item) => {
          return (
            <li key={item.url} className={`${location.pathname.includes(item.url) && 'active'}`}>
              <Link to={item.url}>
                {item.title}
              </Link>
            </li>
            )
          })
        }
      </ul>
      <Routes>
        <Route exact path="/like" element={<Like></Like>} />
        <Route exact path="/question" element={<Question></Question>}></Route>
        <Route exact path="/FNQ" element={<FNQ></FNQ>}></Route>
        <Route exact path="/*" element={<NotFound></NotFound>}></Route>
      </Routes>

    </Div>
  );
}

const Div = styled.div`
  width:calc(100% - 80px);
  max-width:820px;
  margin: 0 auto;
  .userInfo{
    display:flex;
    margin:44px 0 80px;
    >div:first-child{
      width: 88px;
      height: 88px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    >div:nth-child(2){
      margin-left:22px;
      .name{
        color: #333D4B;
        font-size: 20px;
        font-weight: 700;
        margin:20px 0 8px;
      }
      >div:nth-child(2){
        color: #8B95A1;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  ul{
    display:flex;
    border-bottom:1px solid #EAEAEA;
    & *{
      color: #333D4B;
      font-size: 24px;
      font-weight: 700;
    }
    li{
      margin-right:40px;
      >a{
        margin-bottom: 36px;
        display: inline-block;
      }
    }
    .active{
      >a{
        color:#1A6DFF;
      }
    }
    .active::after{
      content:'';
      display:block;
      width:100%;
      height:5px;
      background: #1A6DFF;
    }
  }
  @media (max-width: 767px) {
    width:calc(100% - 36px);
    min-width:324px;
    .userInfo{
      display:flex;
      margin:24px 0 40px;
      >div:first-child{
        width: 64px;
        height: 64px;
        img{
        }
      }
      >div:nth-child(2){
        margin-left:14px;
        .name{
          font-size: 16px;
          margin-top:10px;
        }
      }
    }
    ul{
      & *{
        font-size: 17px;
      }
      li{
        margin-right:23px;
        >a{
          margin-bottom: 22px;
        }
      }
    }
  }
`
