import styled from 'styled-components';
import { Link } from 'react-router-dom';
import main_logo from '../images/main_logo.svg';
import userImage from '../images/user.svg';
import arrow_down from '../images/arrow_down.png';
import hamberger from '../images/hamberger.svg';
import close from '../images/close.svg';

import { useAuth } from '../customHook/useAuth';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../customHook/useWindowSize';
export default function Gnb() {
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const menuRef = useRef(null);
  const { innerWidth, isDesktop } = useWindowSize()
  const [isSideBarOpen, sideBarOpen] = useState(false)

  const [isOpened, open] = useState(false)

  const closeMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      open(false); // 닫기
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  });

  useEffect(() => {
    innerWidth >= 1024 && sideBarOpen(false)
  }, [innerWidth])


  return (
    <>
      {isSideBarOpen &&
        <SideBar>
          <div>
            <ul>
              <li>
                <div onClick={() => { sideBarOpen(false);navigate('/search') }}>
                  경매물건 검색
                </div>
              </li>
              <li>
                <div onClick={() => { sideBarOpen(false);navigate('/recommend') }}>
                  추천물건
                </div>
              </li>
              <li className="unActive">
                <div>커뮤니티</div>
                <div>아직 준비중입니다. 조만간 찾아뵙겠습니다!</div>
              </li>
              <li className="unActive">
                <div>전문가 상담</div>
                <div>아직 준비중입니다. 조만간 찾아뵙겠습니다!</div>
              </li>
              <li className="unActive">
                <div>서비스 소개</div>
                <div>아직 준비중입니다. 조만간 찾아뵙겠습니다!</div>
              </li>
              <div />
              <li>
                <div onClick={() => { sideBarOpen(false);navigate('/mypage/like') }}>
                  관심물건 리스트
                </div>
              </li>
              <li>
                <div onClick={() => { sideBarOpen(false);navigate('/mypage/question') }}>
                  1:1 문의
                </div>
              </li>
              <li>
                <div onClick={() => { sideBarOpen(false);navigate('/mypage/FNQ') }}>
                  자주하는 질문
                </div>
              </li>
              <div />
              {user && 
                <li>
                  <div style={{ color: '#B0B8C1' }} onClick={() => { logout(); navigate('/') }}>로그아웃</div>
                </li>
              }
            </ul>
          </div>
        </SideBar>
      }
      <Div $isSideBarOpen={isSideBarOpen}>
        <div>
          <div className="nav">
            <div className="main_logo">
              <Link to="/"><img src={main_logo} alt="logo" /></Link>
            </div>
            {isDesktop &&
              <ul>
                <li>
                  <Link to="/search">경매물건 검색</Link>
                </li>
                <li>
                  <Link to="/recommend">추천물건</Link>
                </li>
                <li className="unActive">
                  <Link to="/community">커뮤니티</Link>
                  <div>아직 준비중입니다. 조만간 찾아뵙겠습니다!</div>
                </li>
                <li className="unActive">
                  <Link to="/consult">전문가 상담</Link>
                  <div>아직 준비중입니다. 조만간 찾아뵙겠습니다!</div>
                </li>
                <li className="unActive">
                  <Link to="/inform">서비스 소개</Link>
                  <div>아직 준비중입니다. 조만간 찾아뵙겠습니다!</div>
                </li>
              </ul>
            }
          </div>
          {user ?
            isDesktop ?
              <div className="profile" onClick={() => { open(!isOpened) }}>
                <img className="user" src={userImage} alt="user" />
                <img style={{ marginLeft: '8px' }} src={arrow_down} alt="arrow_down" />
                <div style={isOpened ? { display: 'block' } : { display: 'none' }} className="dropDown" ref={menuRef} >
                  <div onClick={() => { navigate('/mypage/like') }}>
                    관심물건 리스트
                  </div>
                  <div onClick={() => { navigate('/mypage/question') }}>
                    1:1 문의
                  </div>
                  <div onClick={() => { navigate('/mypage/FNQ') }}>
                    자주하는 질문
                  </div>
                  <div style={{ color: '#B0B8C1' }} onClick={() => { logout(); navigate('/') }}>로그아웃</div>
                </div>
              </div>
              :
              <div className="hamberger" onClick={() => { sideBarOpen(!isSideBarOpen) }}>
                <img src={isSideBarOpen ? close : hamberger} alt="sidebar button" />
              </div>
            :

            <div className="start">
              <div className="login" onClick={() => {sideBarOpen(false);navigate('/login') }}>회원가입/로그인</div>
              {innerWidth >= 1400 && <div className="toggle" style={{ width: '254px' }}>👋 3초만에 간편 회원가입과 로그인해보세요</div>}
              {innerWidth > 1023 && innerWidth < 1400 && <div className="toggle" style={{ width: '214px' }}>👋 3초만에 간편가입을 해보세요</div>}
              {!isDesktop &&
                <div className="hamberger" onClick={() => { sideBarOpen(!isSideBarOpen) }}>
                  <img src={isSideBarOpen ? close : hamberger} alt="sidebar button" />
                </div>
              }
            </div>
          }
        </div>
      </Div>
    </>
  );
}

const SideBar = styled.div`
  margin-top:76px;
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  background: white;
  padding:42px 40px;
  z-index: 100;
  >div{
    height:100%;
    overflow:scroll ;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
  }
  ul {
    li{
      margin-right:40px;
      >div{
        font-weight: 600;
        font-size:18px;
      }
      margin-bottom:38px;
      cursor:pointer;
    }
    li:hover{
      color: #B0B8C1;
    }
    .unActive{
      cursor:inherit;
      position:relative;
      >div:nth-child(2){
        /* display:none; */
        position:absolute;
        width: 248px;
        height: 38px;
        border-radius: 100px;
        border: 1px solid #DCDCDC;
        background: #FFF;
        color: #4E5968;
        font-size: 13px;
        font-weight: 600;
        display: none;
        justify-content:center;
        align-items: center;
        bottom: -28px;
        top: calc(50% - 38px / 2);
        left: 100px;
      }
    }
    .unActive:hover{
      >div{
        display: flex;
      }
    }    
    >div{
      width:100%;
      height:1px;
      margin-bottom:38px;
      background: #EAEAEA;
    }
  }
  @media (max-width: 767px) {
    padding:34px 18px;
    .unActive{
      >div:nth-child(2){
        font-size: 11px !important;
        width: 210px !important;
      }
    }
  }
`

const Div = styled.div`
  position: ${(props) => props.$isSideBarOpen ? 'fixed' : 'sticky'};
  top: 0;
  background: white;
  z-index: 11;
  height: 76px;
  border-bottom:#EAEAEA 1px solid;
  min-width:1024px;
  
  > div{
    max-width:1200px;
    width:calc(100% - 80px);
    line-height: 76px;
    height: 100%;
    margin:0 auto;
    display:flex;
    justify-content:space-between;
    align-items: center;
    .profile{
      display: flex;
      justify-content: center;
      align-items: center;
      position:relative;
      cursor:pointer;
      .user{
        width:40px;
        height:40px;
        border-radius:50%;
        object-fit:cover;
      }
      .dropDown{
        position:absolute;
        width: 220px;
        height: 216px;
        border-radius: 6px;
        border: 1px solid #E1E1E1;
        background: #FFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);
        top:66px;
        right:0px;
        padding:12px 0;
        >div{
          height:48px;
          line-height:48px;
          padding:0 22px;
          cursor:pointer;
          &:hover{
            background: #F8F8F9;
          }
        }
      }
    }
    .nav{
      display:flex;
      .main_logo{
        margin-right:56px;
        >a{
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
      ul {
        display:flex;
        li{
          margin-right:40px;
          font-weight: 600;
          cursor:pointer;
        }
        li:hover{
          color: #B0B8C1;
        }
        .unActive{
          cursor:inherit;
          position:relative;
          >div{
            /* display:none; */
            position:absolute;
            width: 248px;
            height: 38px;
            border-radius: 100px;
            border: 1px solid #DCDCDC;
            background: #FFF;
            color: #4E5968;
            font-size: 13px;
            font-weight: 600;
            display: none;
            justify-content:center;
            align-items: center;
            bottom: -28px;
            left: calc(50% - 248px / 2);
          }
          a{
            cursor:default;
            pointer-events: none;
          }
        }
        .unActive:hover{
          >div{
            display: flex;
          }
        }
      }
    }
    .start{
      display: flex;
      align-items: center;
      justify-content: center;
      position:relative;
      &:hover{
        >div{
          opacity: 1;
        }
      }
      .toggle{
        position:absolute;
        width: 254px;
        /* width:214px; */
        height: 38px;
        border-radius: 100px;
        border: 1px solid #DCDCDC;
        background: #FFF;
        font-size: 13px;
        font-weight: 600;
        text-align:center;
        top: 66px;
        line-height: 38px;
        opacity: 0;
        transition:0.1s;
      }
      >.login{
        width: 138px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        border-radius: 100px;
        border: 1px solid #1A6DFF;

        color: #1A6DFF;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.56px;
        transition:0.1s;
        &:hover{
          background: #F1F6FF;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    min-width:768px;
    width:100%;
    .hamberger{
      display: flex;
      >img{
        width: 26px;
        height: 20px;
        margin-left: 22px;
        cursor:pointer;
      }
    }
  }
  @media (max-width: 767px) {
    min-width:360px;
    > div{
      width:calc(100% - 36px);
      .nav{
        .main_logo{
          >a{
            width:128px;
          }
        }
      }
      .start{
        >.login{
          width: 108px;
          height: 38px;
          font-size: 12px;
        }
        .hamberger{
          >img{
            width: 24px;
            height: 18px;
            margin-left: 14px;
          }
        }
      }
    }
  }
`