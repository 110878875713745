import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { convertToKoreanCurrency } from '../utils'
import logo from '../images/logoBlackWhite.svg'
import view from '../images/view.png'


export default function AuctionItemCard({data}) {
  const navigate = useNavigate()
  const { auctionId, finalResult, failedCount, auctionState, address, appraisalValue, minimumBidPrice, auctionImage, views  } = data

  const auctionStatus = (finalResult, failedCount, auctionState, minimumBidPrice, appraisalValue, address) => {
    if(address.auctionState){
      return address.auctionState
    }
    if(finalResult && finalResult !== '미종국'){
      return finalResult
    }
    if(auctionState === '유찰'){
      return `유찰 ${failedCount}회 (${Math.round(minimumBidPrice/appraisalValue*100)}%)`
    }
    return auctionState
  }
  
  return (
    <Div onClick={()=>{navigate(`/auction/${auctionId}`)}}>
      {JSON.parse(auctionImage)[0] ? 
      <img alt="auctionImage" src={'https://auctionword-uploads.s3.ap-northeast-2.amazonaws.com/auctionImage/'+JSON.parse(auctionImage)[0]}/>
      :
      <div className="emptyAuctionImage">
        <img alt="auctionImage" src={logo}/>
      </div>
      }
      <div className="info">
        <div className="address">{address[0].address}{`${address.length >= 2 ? `외 ${address.length-1}`: ''}`}</div>
        <div className="appraisal">감정가 <span>{convertToKoreanCurrency(appraisalValue)}</span></div>
        <div className="minimum">최저가 {convertToKoreanCurrency(minimumBidPrice)}</div>
        <div className="bottom">
          <span className="status">{auctionStatus(finalResult, failedCount, auctionState, minimumBidPrice, appraisalValue, address)}</span>
          <div>
            <img alt="view" src={view}/>
            {views}
          </div>
        </div>
      </div>
    </Div>
  );
}

const Div = styled.div`
  cursor: pointer;
  height: 380px;
  border-radius:10px;
  box-shadow:0px 4px 12px rgba(0, 0, 0, 0.08);
  >img{
    width:100%;
    height: 196px;
    display: block;
    border-radius: 10px 10px 0px 0;
  }
  .emptyAuctionImage{
    width:100%;
    height: 196px;
    border-radius: 10px 10px 0px 0;
    background-color:#E5E8EB;
    display: flex;
    justify-content:center;
    align-items:center;
  }
  >.info{
    padding:14px 18px 23px;
    >.address{
      color: #191F28;
      font-weight: 600;
      margin-bottom:14px;
      height:44px;
      display: -webkit-box;
      line-height:22px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    >.appraisal{
      color: #8B95A1;
      font-size: 15px;
      font-weight: 500;
      margin-bottom:5px;
      text-align:left;
      >span{
        text-decoration:line-through;
      }
    }
    >.minimum{
      color: #1A6DFF;
      font-size: 18px;
      font-weight: 700;
      margin-bottom:16px;
      text-align:left;
    }
    >.bottom{
      display:flex;
      justify-content:space-between;
      .status{
        color: #4E5968;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        background: #F2F4F6;
        padding:5px 9px 6px;
      }
      div{
        display:flex;
        align-items:center;
        color: #B0B8C1;
        font-size: 14px;
        font-weight: 500;
        >img{
          margin-right:4px;
        }
      }
    }
  }


  @media (max-width: 767px) {
    height: 320px;
    >img{
      height: 160px;
    }
    .emptyAuctionImage{
      height: 160px;
    }
    >.info{
      padding: 7.5px 18px 18px;
      >.address{
        font-size: 14px;
        height:40px;
        line-height:20px;
        margin-bottom:10px;
      }
      >.appraisal{
        font-size: 13px;
      }
      >.minimum{
        font-size: 16px;
      }
      >.bottom{
        .status{
          font-size: 12px;
        }
        div{
          font-size: 12px;
          >img{
            width:16px;
            height: 12px;
          }
        }
      }
    }
  }
`