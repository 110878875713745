import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { addressObject } from "../../utils";
import styled from 'styled-components';
import close_button_blue from '../../images/close_button_blue.svg'
import { useWindowSize } from '../../customHook/useWindowSize';


export function Region({filterOptions, setFilterOptions, setSelected, close}) {
  // filterOptions, setFilterOptions 모바일에서만 사용
  const location = useLocation();
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()
  const { sido, sigugun } = addressObject;
  const [isAllChecked, setAllcheck] = useState(false) // 전국 체크 여부.
  const [addressCode, setAddressCode] = useState([]) // 선택된 모든 주소 코드. ex) 서울 -> 11, 서울 종로구 -> 11110
  const [openedSido, setOpenedSido] = useState(null) // 현재 열린 광역시 또는 도
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    let addressParams = params.get('address')?.split('+')
    addressParams && setAddressCode(addressParams)
    if(isMobile) return
    if (addressParams?.length > 0) {
      setSelected({
        "content": addressParams.length === 1 ? convertAddress(addressParams[0])?.substring(0, 2) : convertAddress(addressParams[0])?.substring(0, 2) + ' 외',
        "count": addressParams.length === 1 ? 0 : addressParams.length - 1,
      });
    } else {
      setSelected({content: '', count: 0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])


  useEffect(() => {
    addressCode.length === 0 && setAllcheck(true)
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.address = addressCode
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressCode])

  const convertAddress = (address) => {
    if (typeof address === 'string') {
      if (address.length === 5) { // 시도 시군구
        let sidoInfo = addressObject.sido.filter(item => item.sido === address.slice(0, 2))[0].codeNm
        let sigugunString = addressObject.sigugun.filter(item => address.slice(0, 2) === item.sido && address.slice(2) === item.sigugun)[0].codeNm
        return sidoInfo + ' ' + sigugunString
      } else if (address.length === 2) {
        return addressObject.sido.filter(item => item.sido === address)[0].codeNm
      }
    } else {
      let parsedAddressList = []
      address.forEach(address => {
        if (address.length === 5) { // 시도 시군구
          let sidoInfo = addressObject.sido.filter(item => item.sido === address.slice(0, 2))[0].codeNm
          let sigugunString = addressObject.sigugun.filter(item => address.slice(0, 2) === item.sido && address.slice(2) === item.sigugun)[0].codeNm
          parsedAddressList.push(sidoInfo + ' ' + sigugunString)
        } else if (address.length === 2) {
          parsedAddressList.push(addressObject.sido.filter(item => item.sido === address)[0].codeNm)
        }
      })
      return parsedAddressList
    }
  }

  const apply = (addressCode) => {
    let addressParams = addressCode.length > 0 ? [...addressCode].join('+') : null
    addressParams ? params.set('address', addressParams) : params.delete('address');
    close()
    navigate(`/search?${params.toString()}`)
  }

  const clickAll = (sidoCode) => { // 시군구 리스트의 전체 클릭.
    if (addressCode.includes(sidoCode)) { // sidoCode가 이미 있어서 sidoCode 지우기.
      setAddressCode(addressCode.filter(data => data !== sidoCode))
    } else { // sidoCode가 없어서 sidoCode 추가 및 sidoCode로 시작하는것 다 지우기
      let newData = addressCode.filter(data => !data.startsWith(sidoCode))
      setAddressCode([...newData, sidoCode])
    }
  }

  const clickSigugun = (sigugunCode) => { // 시군구 리스트의 개별 클릭.
    if (addressCode.includes(sigugunCode)) { // sigugunCode 이미 있어서 sigugunCode 지우기.
      setAddressCode(addressCode.filter(data => data !== sigugunCode))
    } else { // sigugunCode 없어서 sigugunCode 추가 및 sidoCode 삭제.
      let newData = addressCode.filter(data => data !== sigugunCode.slice(0, 2))
      setAddressCode([...newData, sigugunCode])
    }
  }

  return (
    <Div>
      <header>
        <div>지역</div>
        <span>중복 선택 가능</span>
      </header>
      <div className="listBox">
        <div>
          <p>지역</p>
          <div className="region">
            <p style={isAllChecked && openedSido === null ? { backgroundColor: '#F8F8F9', fontWeight: "600", color: "#1A6DFF" } : {}} onClick={() => { setAllcheck(true); setOpenedSido(null);setAddressCode([])}}>전국</p>
            {sido.map((item) => {
              return (
                <p key={item.sido} style={openedSido === item.sido ? { backgroundColor: '#F8F8F9', fontWeight: "600", color: "#1A6DFF" } : {}} onClick={() => { setOpenedSido(item.sido) }}>
                  {item.codeNm}
                </p>
              )
            }
            )}
          </div>
        </div>
        <div>
          <p>상세지역</p>
          <div className="region detail">
            {
              openedSido ?
                <>
                  <p style={addressCode.includes(openedSido) ? { backgroundColor: '#F8F8F9', fontWeight: "600", color: "#1A6DFF" } : {}} onClick={() => { clickAll(openedSido) }}>전체</p>
                  {sigugun.filter((el) => el.sido === openedSido).sort((a, b) => a.codeNm.localeCompare(b.codeNm)).map((el) => {
                    return (
                      <p key={el.sigugun} onClick={() => { clickSigugun(el.sido + el.sigugun); }} style={addressCode.indexOf(el.sido + el.sigugun) !== -1 ? { backgroundColor: '#F8F8F9', fontWeight: "600", color: "#1A6DFF" } : {}}>
                        {el.codeNm}
                      </p>
                    )
                  })}
                </>
                :
                <></>
            }
          </div>
        </div>
      </div>

      <div className="selected">
        {addressCode.map((address) => {
          return <p key={address}>{convertAddress(address)}<img src={close_button_blue} alt="delete button" onClick={() => { setAddressCode(addressCode.filter(item => item !== address)) }} /></p>
        })}
      </div>


      <div className="buttons">
        <button className="reset" onClick={() => { setAllcheck(true); setAddressCode([]); setOpenedSido(null); }}>초기화</button>
        <button className="apply" onClick={() => { apply(addressCode) }}>적용하기</button>
      </div>

    </Div>
  )
}

const Div = styled.div`
  .listBox{
    display: flex;
    >div{
      >p{
        color: #8B95A1;
        font-size: 14px;
        font-weight: 500;
        margin-bottom:13px;
        text-align: left;
      }
      .region{
        border-radius: 1px;
        border: 1px solid #E1E1E1;
        background: #FFF;
        width: 200px;
        height: 230px;
        overflow:scroll;
        > p{ 
          cursor:pointer;
          width: 198px;
          height: 30px;
          padding:6px 18px;
          color: #333D4B;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
  .selected{
    margin-top:18px;
    width: 400px;
    min-height:62px;
    background: #F2F4F6;
    padding:10px;
    overflow: scroll;
    >p{
      float:left;
      margin:2px;
      padding:0 10px 0 16px;
      height: 38px;
      border-radius: 100px;
      border: 1px solid #1A6DFF;
      background: #FFF;
      display: flex;
      justify-content:center;
      align-items:center;
      color: #1A6DFF;
      font-size: 14px;
      font-weight: 600;
      >img{
        margin-left: 4px;
      }
    }
  }
  @media (max-width: 767px) {
    .listBox{
      >div{
        .region{
          width: 161px;
          > p{ 
            width: 159px;
          }
        }
      }
    }
    .selected{
      margin-top:16px;
      width: 324px;
      >p{
        margin:2px;
        width:148px;
        >img{
          margin-left: 34px;
        }
      }
    }
  }

`