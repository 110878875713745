
import styled from "styled-components"
import useAPI from "../../customHook/useAPI";
import { useModal } from "../../customHook/useModal";
import { jiwonNmObject, convertToFullCaseNumberForParams } from "../../utils";
import KakaoMap from "./KakaoMap";
import josa from "../../images/josa.svg"
import mamulseo from "../../images/mamulseo.svg"
import appraisal from "../../images/appraisal.svg"
import landRC from "../../images/landRC.svg"
import landRCBlackWhite from "../../images/landRCBlackWhite.svg"
import buildingRC from "../../images/buildingRC.svg"
import buildingRCBlackWhite from "../../images/buildingRCBlackWhite.svg"
import buildingState from "../../images/buildingState.svg"
import landState from "../../images/landState.svg"
import realPrice from "../../images/realPrice.svg"
import { useState, useEffect } from "react";
import { useAuth } from "../../customHook/useAuth";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../customHook/useWindowSize";

export default function DetailAside({ apiData }) {
  const { pdfModal, documentModal, modalShow, modalClose, reportModal } = useModal()
  const { isMobile } = useWindowSize()
  const { getAPI, postAPI } = useAPI();
  const [hasBuilding, setBuilding] = useState(false)
  const [hasLand, setLand] = useState(false)
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const getRegistrationCertificate = async (auctionId, address, type) => {
    if(!user){
      navigate('/login')
      return
    }
    let targetAddress;
    if(type ==="건물"){
      targetAddress = hasBuilding
    } else if(type === "토지"){
      targetAddress = hasLand
    }
    if (!targetAddress) { return }
    let { success, status, data, message } = await getAPI(`/auction/registrationCertificate?auctionId=${auctionId}&listType=${targetAddress.listType}`);
    if(status === 401){
      logout(true)
      navigate('/login')
      return
    }
    if(!data){ // 비정상적인 접근 or 에러인 경우
      modalShow({
        message:message || '알 수 없는 에러입니다',
        submessage: message ? '' : "에러가 지속된다면 관리자에게 문의해주세요",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
      return
    }
    if (!success) {
      modalShow({ 
        message: 
          <>
            <div style={isMobile ? {fontSize:'26px'} : {fontSize:'30px'}}>🧐</div>
            열람 가능한 등기부 등본이 없습니다.
            <br/>등기부 등본을 발급 하시겠습니까?
          </>, 
        submessage: 
          <div className="box">
            등기 무료 발급 가능 횟수가 3번 중&nbsp;<span style={{color:'#1A6DFF',fontWeight:'800'}}>{data.registration_cnt}번</span>&nbsp;남았습니다. {!isMobile&& `(${data.registration_cnt}/3)`}
          </div>, 
        actionButton: <div onClick={() => { modalClose();postRegistrationCertificate(auctionId, address, type); }}>발급</div> }
      )
      return
    }
    if (data.pdfURL) {
      pdfModal(data.pdfURL)
      return
    } else {
      modalShow({
        message:
          <>
            <div className="dotContainer">
              <div className="dot-flashing"></div>
            </div>
            등기부를 분석 중입니다.
          </>,
        submessage:
          <>
            창을 새로고침하면 등기부가 뜹니다.
            <br />
            이 작업은 최소 1분 이상 걸릴 수 있습니다.            
            <br />
            분석이 지연될 시, 고객센터로 문의주세요.
          </>
        ,
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
    }
  }

  const postRegistrationCertificate = async (auctionId, address, type) => {
    if(!user){
      navigate('/login')
      return
    }
    let targetAddress;
    if(type ==="건물"){
      targetAddress = hasBuilding
    } else if(type === "토지"){
      targetAddress = hasLand
    }
    if (!targetAddress) { return }
    let data = {
      // 11231033152
      auctionId,
      listType: targetAddress.listType
    }
    let { success, message } = await postAPI(`/auction/registrationCertificate`, data);

    if (success) {
      modalShow({
        message:
          <>
            <div className="dotContainer">
              <div className="dot-flashing"></div>
            </div>
            등기부를 분석 중입니다.
          </>,
        submessage:
          <>
            창을 새로고침하면 등기부가 뜹니다.
            <br />
            이 작업은 최소 1분 이상 걸릴 수 있습니다.            
            <br />
            분석이 지연될 시, 고객센터로 문의주세요.
          </>
        ,
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
      return
    } else {
      if(message === '매각물건명세서가 등록되지 않았습니다.'){
        modalShow({
          message,
          submessage: "매각물건명세서는 입찰기일 7일전 등록됩니다.",
          actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
        })
        return       
      }
      if(message === '매각물건명세서 분석 중인 물건입니다.'){
        modalShow({
          message,
          submessage: "급하신 경우 관리자에게 문의해주세요.",
          actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
        })
        return       
      }
      
      modalShow({
        message:message || '알 수 없는 에러입니다',
        submessage: message ? '' : "에러가 지속된다면 관리자에게 문의해주세요",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
      return
    }
  }

  const isVaildAddressType = (address, type) => {
    let filtered = address.filter(addressData => addressData.listType.includes(type))
    return filtered.length > 0 ? filtered[0] : null
  }

  const handleReport = (apiData) => {
    if(!user){
      navigate('/login')
      return
    }
    reportModal(apiData.auctionId, `${apiData.caseNumber.toString().slice(0, 4)}타경${apiData.caseNumber.toString().slice(4)}[${apiData.itemNumber}]`)
  }

  useEffect(()=>{
    setBuilding(isVaildAddressType(apiData.address, '건물'))
    setLand(isVaildAddressType(apiData.address, '토지'))
    return () => {
      modalClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[apiData])

  return (
    <Aside>
      <div className="kakaoMap">
        <KakaoMap address={apiData.address[0].address} />
      </div>
      <div className="sticky">
        <div className="separateBar" />
        <div className="links">
          <h2>
            사건 관련 자료
          </h2>
          <div className="gridWrapper">
            <div onClick={() => { window.open(`https://www.courtauction.go.kr/RetrieveRealEstSaHjosa.laf?jiwonNm=${jiwonNmObject[apiData.court]}&saNo=${convertToFullCaseNumberForParams(apiData.caseNumber)}&maemulSer=1`, '_blank') }}>
              <div style={{ backgroundImage: `url(${josa})` }}/>
              <div>
                현황조사서
              </div>
            </div>
            <div onClick={() => { apiData.maemulseo ? pdfModal(apiData.maemulseo.link) : modalShow({message:'매각물건명세서가 등록되지 않았습니다.',submessage: "매각물건명세서는 입찰기일 7일전 등록됩니다.",actionButton: <div onClick={() => { modalClose(); }}>닫기</div>})}}>
              <div style={{ backgroundImage: `url(${mamulseo})` }}/>
              <div style={{left:"-2px"}}>
                매각물건명세서
              </div>
            </div>
            <div onClick={() => { apiData.detail.appraisalReportLink ? window.open(apiData.detail.appraisalReportLink, '_blank') : modalShow({message:'감정평가서가 등록되지 않았습니다.',submessage: "",actionButton: <div onClick={() => { modalClose(); }}>닫기</div>})}}>
              <div style={{ backgroundImage: `url(${appraisal})` }}/>
              <div>
                감정평가서
              </div>
            </div>
            <div className={!hasLand ? 'unActive' : null} onClick={() => { getRegistrationCertificate(apiData.auctionId, apiData.address, "토지") }}>
              {hasLand ?   
                <div style={{ backgroundImage: `url(${landRC})` }}/>
                :
                <div style={{ backgroundImage: `url(${landRCBlackWhite})` }}/>
              }
              <div>
                토지등기부
              </div>
            </div>
            <div className={!hasBuilding ? 'unActive' : null} onClick={() => { getRegistrationCertificate(apiData.auctionId, apiData.address, "건물") }}>
              {hasBuilding ?   
                <div style={{ backgroundImage: `url(${buildingRC})` }}/>
                :
                <div style={{ backgroundImage: `url(${buildingRCBlackWhite})` }}/>
              }
              <div>
                건물등기부
              </div>
            </div>
            <div onClick={() => { window.open('https://www.gov.kr/search?srhQuery=%EA%B1%B4%EC%B6%95%EB%AC%BC%EB%8C%80%EC%9E%A5', '_blank') }}>
              <div style={{ backgroundImage: `url(${buildingState})` }}/>
              <div>
                건축물대장
              </div>
            </div>
            <div onClick={() => { window.open('https://rt.molit.go.kr/srh/srh.do', '_blank') }}>
              <div style={{ backgroundImage: `url(${landState})` }}/>
              <div>
                실거래가
              </div>
            </div>
            {/* 아파트 실거래가 ->     https://rt.molit.go.kr/srh/srh.do?cmd=rtSearch&menuGubun=A&srhType=LOC#,11,11680,1168010300,,,,2023,3,1,1 */}
            {/* 연립 다세대 실거래가 -> https://rt.molit.go.kr/srh/srh.do?cmd=rtSearch&menuGubun=B&srhType=LOC#,11,11215,1121510100,,,,2023,2,1,1 */}
            {/* menuGubun -> A: 아파트, B: 연립 다세대, C: 단독 다가구, E: 오피스텔, H: 상업, 업무용, I: 공장, 창고, G: 토지 */}
            {/* 11,11680,1168010300 -> 시도, 시군구, 동 */}
            {/* 2023,2,1,1 */}
            {/* <div className={!(apiData.documentAccepted || apiData.documentSent) ? 'unActive' : null}  onClick={() => { (apiData.documentAccepted || apiData.documentSent) && documentModal(apiData) }}> */}
            <div className={!(apiData.detail.documentAccepted || apiData.detail.documentSent) ? 'unActive' : null}  onClick={() => {(apiData.detail.documentAccepted || apiData.detail.documentSent) && documentModal(apiData.detail) }}>
              <div style={{ backgroundImage: `url(${realPrice})` }}/>
              <div>
                문건/송달내역
              </div>
            </div>
          </div>
        </div>
        <div className="report">
          <div>📨&nbsp;&nbsp;잘못된 정보가 있나요?</div>
          <div onClick={()=>handleReport(apiData)}>제보하기 {'>'}</div>
        </div>
      </div>
    </Aside>
  )
}

const Aside = styled.aside`
  max-width:380px;
  min-width:380px;
  .sticky{
    position: sticky;
    top: 74px;
    background: white;
    z-index: 5;
    margin-bottom:140px;
  }
  .kakaoMap{
    width:100%;
    aspect-ratio:380 / 240;
    border-radius: 10px;
    border: 1px solid #DADADA;
    margin-bottom:16px;
    overflow: hidden;
  }
  .separateBar{
    width:100%;
    height:1px;
    background:#EBEBEB;
  }
  .links{
    padding:24px;
    width:100%;
    aspect-ratio:380 / 336;
    border:1px solid #D2E7FF;
    box-shadow:0px 0px 8px rgba(0, 0, 0, 0.08);
    margin-top:16px;
    border-radius:10px;
    h2{
      color: #333D4B;
      font-size: 18px;
      font-weight: 700;
      padding-bottom:16px;
      border-bottom:1px solid #EBEBEB;
    }
    .gridWrapper{
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap:16px 14px;
      margin-top:18px;
      >div{
        cursor:pointer;
        margin: 0 auto;
        width: 72px;
        >div:first-child{
          width: 100%;
          aspect-ratio:1/1;
          border-radius:16px;
          border: 1px solid #E1E1E1;
          background-position: center;
          background-repeat: no-repeat;
        }
        >div:nth-child(2){
          color: #333D4B;
          font-size: 13px;
          font-weight: 500;
          white-space:nowrap;
          text-align: center;
          margin-top:10px;
          position: relative;
        }
        >div:hover{
          &:first-child{
            transition:0.1s;
            border: 1px solid #1A6DFF;
            background-color: #F8F8F9;
          }
        }
      }
      .unActive{
        >div:nth-child(2){
          color: #B0B8C1;
        }
      }
      .unActive:hover{
        cursor:inherit;
        >div:first-child{
          border: 1px solid #E1E1E1;
          background-color: inherit;
        }
      }
    }
  }
  .report{
    width: 100%;
    height: 60px;
    margin-top:20px;
    border-radius: 8px;
    border: 1px solid #D9EAFE;
    background: #F8FAFF;
    padding:0 24px;
    display: flex;
    justify-content:space-between;
    align-items:center;
    >div:first-child{
      color: #333D4B;
      font-size: 15px;
      font-weight: 700;
    }
    >div:nth-child(2){
      color: #1A6DFF;
      font-size: 13px;
      font-weight: 700;
      cursor:pointer;
    }
  }

  @media (max-width: 1200px) {
    margin-top: 24px;
    width:100%;
    max-width:inherit;
    min-width:inherit;
    .sticky{
      margin-bottom:0px;
    }
    .links {
      aspect-ratio:inherit;
      padding-bottom:54px;
      .gridWrapper{
        display: flex;
        >div >div:first-child{
          width:72px;
        }
      }
    }
  }
  @media (max-width: 812px) {
    .sticky{
      .links{
        .gridWrapper{
          display: grid;
          grid-template-columns: repeat(6, minmax(0, 1fr));
        }
      }
    }
  }


  @media (max-width: 767px) {
    .kakaoMap{
      margin-top:16px;
    }
    .sticky{
      margin-top: 24px;
      .separateBar{
        display:none;
      }
      .links{
        border:inherit;
        box-shadow:inherit;
        padding:0;
        .gridWrapper{
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  @media (max-width: 718px) {
    .sticky{
      .links{
        .gridWrapper{
          display: grid;
          grid-template-columns: repeat(6, minmax(0, 1fr));
          grid-gap: 16px 12px;
        }
      }
    }
  }

  @media (max-width: 580px) {
    .sticky{
      .links{
        .gridWrapper{
          display: grid;
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
      }
    }
  }

  @media (max-width: 448px) {
    .sticky{
      .links{
        .gridWrapper{
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
      }
    }
  }



`