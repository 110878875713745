import styled from 'styled-components';
import banner from '../images/banner.png'
import bannerMobile from '../images/bannerMobile.png'
import { useWindowSize } from '../customHook/useWindowSize';

export default function Banner() {
  const {isMobile} = useWindowSize()
  return (
    <Div>
      <div style={{background: `#F7F8FB url(${isMobile ? bannerMobile : banner}) center no-repeat`}}>
        <h2>경매물건 분석 🏆1등 전문가</h2>
        <h5>부동산경매 실전 & 투자, 특수물건 20년 이상 경력<br />경매 교육 수강생 누적 8천여 명 경매광장 아카데미</h5>
        <button><a href="https://www.youtube.com/@auctionworld" target="_blank" rel="noopener noreferrer">경매광장 유튜브 구독하기</a></button>
      </div>
    </Div>
  )
}

const Div = styled.div`
  >div{
    width:100%;
    max-width:1920px;
    height: 420px;
    text-align:center;
    padding:105px 0 103px;
    margin:0 auto;
    >h2{
      margin-bottom:28px;
      color: #191F28;
      font-size: 32px;
      font-weight: 700;
    }
    >h5{
      color: #6B7684;
      font-weight: 500;
      line-height: 24px;
    }
    >button{
      margin:42px auto 0;
      width: 208px;
      height: 56px;
      border-radius: 100px;
      background: #272E40;
      box-shadow: 0px 4px 16px 0px rgba(39, 46, 64, 0.30);
      >a{
        color: #FFF;
        font-weight: 600;
      }
    }
  }
  @media (max-width: 767px) {
    >div{
      background-size: cover !important;
      height: 340px;
      padding:76px 0 75px;
      >h2{
        font-size: 26px;
      }
      >h5{
        font-size: 14px;
        line-height: 22px;
      }
      >button{
        width: 194px;
        height: 54px;
        font-size: 14px;
      }
    }
  }

`