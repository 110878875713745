import { useModal } from "../../customHook/useModal";
import styled from "styled-components"

export default function SellingArea({ address, totalBuildingArea, totalLandArea }) {
  const { addressModal } = useModal()

  const getAddress = (address) => {
    return (
      <table>
        <colgroup>
          <col width="9.1%" />
          <col width="15.7%" />
          <col width="36.8%" />
          <col width="15.7%" />
          <col width="12.7%" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>구분</th>
            <th>소재지</th>
            <th>용도</th>
            <th>상세</th>
          </tr>
        </thead>
        <tbody>
          {address.map((addressItem) => (
            <tr key={addressItem.listNumber}>
              <td width="">
                <div>
                  {addressItem.listNumber}{addressItem.auctionState && `(${addressItem.auctionState})`}
                </div>
              </td>
              <td>
              <div style={addressItem.auctionState ? {textDecoration:'line-through', textDecorationColor: 'red'} : {}}>
                  {addressItem.listType}
                </div>
              </td>
              <td>
              <div style={addressItem.auctionState ? {textDecoration:'line-through', textDecorationColor: 'red'} : {}}>
                  {addressItem.address}
                </div>
              </td>
              <td>
                <div style={addressItem.auctionState ? {textDecoration:'line-through', textDecorationColor: 'red'} : {}}>
                  {addressItem.typeDetail}
                </div>
              </td>
              <td className="more" onClick={() => { addressModal(`${JSON.parse(addressItem.addressDetail)}`); }}>보기</td>
              {/* <td className="more" onClick={() => { addressModal(addressItem.addressDetail);}}>보기</td> */}

            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Div>
      <h2>매각되는 토지 및 건물</h2>
      <div className="area">
        <div>
          <div>건물</div>
          <div>{totalBuildingArea.toFixed(1)}㎡ {`(${((totalBuildingArea / 3.3).toFixed(1))})평`}</div>
        </div>
        <div className="separateBar" />
        <div>
          <div>토지</div>
          <div>{totalLandArea.toFixed(1)}㎡  {`(${(totalLandArea / 3.3).toFixed(1)})평`}</div>
        </div>
      </div>
      <div>{getAddress(address)}</div>
    </Div>
  )
}

const Div = styled.div`
  >h2{
    margin:44px 0 28px;
    color: #191F28;
    font-size: 22px;
    font-weight: 700;
  }
  .area{
    margin-bottom:28px;
    display: flex;
    justify-content:space-evenly;
    align-items:center;
    width:100%;
    height: 86px;
    border-radius: 4px;
    border: 1px solid #DADADA;
    background: #FFF;
    .separateBar{
      width: 1px;
      height: 40px;
      background: #E4E4E4;
    }
    >div{
      text-align:center;
      color: #333D4B;
      font-size: 18px;
      font-weight: 600;
      div:first-child{
        color: #8B95A1;
        font-size: 14px;
        font-weight: 500;
        margin-bottom:4px;
      }
    }
  }
  @media (max-width: 767px) {
    >h2{
      font-size: 20px;
      margin: 36px 0 20px;
    } 
    .area{
      >div{
        div:last-child{
          font-size: 14px;
        }
      }
    }
    table{
      table-layout: fixed;
      >tbody>tr>td{
        >div{
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`

