import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAPI from "./useAPI";
import { useBlock } from "./useBlock";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate()
  const { getAPI } = useAPI();
  const { setBlock } = useBlock()
  useEffect(() => {
    const fetchAPI = async () => {
      let { data } = await getAPI(`/auth`);
      if(data?.isLoggedIn){
        setUser(data.user)
      }
      setBlock(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLogin = async() => {
    return user ? true : false
  }

  // call this function to sign out logged in user
  const logout = async (notRidrect) => {
    await getAPI(`/auth/logout`);
    setUser(null);
    !notRidrect && navigate("/", { replace: true });
  };


  const value = useMemo(
    () => ({
      user,
      isLogin,
      logout,
    }),
    /*eslint-disable */
    [user]
    /*eslint-enable */
  );
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/