import styled from "styled-components"

export default function Occupant({ maemulseo, address }) {
  const getOccupant = (maemulseo) => {
    let occupantData = maemulseo?.occupantData ? JSON.parse(maemulseo.occupantData) : null
    return (
      <table>
        <thead>
          <tr>
            <th>임차인</th>
            <th>점유부분</th>
            <th>보증금/차임</th>
            <th>전입/확정/배당</th>
            <th>대항력</th>
            <th>출처</th>
          </tr>
        </thead>
        <tbody>
          {maemulseo === null ?
            <tr>
              <td colSpan="6">
                매각 물건 명세서가 등록되지 않았습니다.
              </td>
            </tr>
            :

            occupantData.length === 0 ?
              <tr>
                <td colSpan="6">
                  조사된 임차내역이 없습니다.
                </td>
              </tr>
              :
              occupantData.map((occupantItem, index) => { // 순서 바뀔일 없어서 index를 key값으로 사용
                let deposit = occupantItem["보증금"]
                let payment = occupantItem["차임"]
                let countervailing = occupantItem["countervailing"]
                let occupentRights = occupantItem["점유의권원"].replace(/\s*/g, '')
                // curruntOccupant = occupantItem["점유자성명"]
                return (
                  <tr key={index} style={{ height: "80px" }}>
                    <td>{occupantItem["점유자성명"]}</td>
                    <td>{occupantItem["점유부분"]}</td>
                    <td style={{ textAlign: 'left', textWrap: 'nowrap' }}>
                      {deposit === "미상" && payment === "미상" && "미상"}
                      {deposit !== "미상" && deposit !== "" && <div>보증금: {deposit}원</div>}
                      {payment !== "미상" && payment !== "" && payment !== "없음" && payment !== "0" && <div>차&nbsp;&nbsp;&nbsp;임: {payment}원</div>}
                    </td>
                    <td style={{ textAlign: 'left'  }}>
                      <div>{occupentRights === "점포임차권자" || occupentRights === "사무실임차권자" ? "사업등록" : "전입신고"} : {occupantItem["전입신고일자·외국인등록(체류지변경신고)일자·사업자등록신청일자"] || occupantItem["전입신고일자,사업자등록신청일자"]}</div>
                      <div>확정일자 : {occupantItem["확정일자"]}</div>
                      <div>배당요구 : {occupantItem["배당요구여부(배당요구일자)"]}</div>
                    </td>
                    <td style={{ color: "#D91C18" }}>{countervailing === "unknown" ? "미상" : countervailing === true ? "O" : "X"}</td>
                    <td>{occupantItem["정보출처구분"]}</td>
                  </tr>
                )
              })
          }
        </tbody>
      </table>
    );
  };

  const addressListTypeSet = new Set()
  for (const addressTypeList of address) {
    addressListTypeSet.add(addressTypeList.listType)
  }
  const getAddressType = Array.from(addressListTypeSet)

  const getCaution = (maemulseo) => {
    let data = []
    if(maemulseo?.occupantNotice){
      data.push(maemulseo.occupantNotice)
    }
    if(maemulseo?.acquireRights && (maemulseo.acquireRights !== '해당사항없음')){
      data.push(maemulseo.acquireRights)
    }
    if(maemulseo?.landRights && (maemulseo.landRights !== '해당사항없음')){
      data.push(maemulseo.landRights)
    }
    if(maemulseo?.maemulseoNotice && (maemulseo.maemulseoNotice !== '해당사항없음')){
      data.push(maemulseo.maemulseoNotice.replace(/＊/g,''))
    }
    return data
  }

  let caution = getCaution(maemulseo)

  return (
    <Div>
      <div className="title">
        <div>
          임차인 현황
        </div>
        <div>
          {'('}
          {(maemulseo?.buildingTopPriorityRights || maemulseo?.landTopPriorityRights) && 
            <>
              말소기준권리 : {
                getAddressType.length === 1 && getAddressType[0] === "토지" ? // 토지만 있는경우만 토지 말소기준등기 적용
                  JSON.parse(maemulseo.landTopPriorityRights)[0].date
                  :
                  JSON.parse(maemulseo.buildingTopPriorityRights)[0].date
              } /&nbsp;
            </>
          }
          배당요구종기일 : {address[0].allocationDate}{')'}
        </div>
      </div>
      <div>{getOccupant(maemulseo)}</div>
      {caution.length > 0 &&
      <>
        <div className="subTitle">매각물건명세서상 주의사항</div>
        {caution.map((data, index)=>{
          return <div key={index} className="caption">• {data}</div>
        })}
      </>
      }
    </Div>
  )
}

const Div = styled.div`
  >.title{
    margin:44px 0 28px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    >div:first-child{    
      color: #191F28;
      font-size: 22px;
      font-weight: 700;
    }
    >div:nth-child(2){
      color: #8B95A1;
      font-size: 14px;
      font-weight: 400;
    }
  }
  >.subTitle{
    margin:24px 0 0;
    font-size: 16px;
    font-weight: 600;
  }
  .caption{
    margin-top:12px;
    color: #4E5968;
    font-size: 15px;
    font-weight: 500;
  }
  table{
    tr {
      th:first-child{
        min-width:58px;
      }
      th:nth-child(2){
        min-width:70px;
      }
      th:nth-child(3){
        min-width:87px;
      }
      th:nth-child(5){
        min-width:58px;
      }
      th:nth-child(6){
        min-width:70px;
      }
    }
  }
  @media (max-width: 767px) {
    margin:0px !important;
    width:100% !important;
    >.title{
      margin:36px 18px 20px;
      display: block;
      >div:first-child{    
        font-size: 20px;
      }
      >div:nth-child(2){
        font-size: 13px;
        margin-top:8px;
      }
    }
    >.subTitle{
      margin-left: 18px;
      margin-right: 18px;
    }
    >div{
      padding-bottom: 12px;
      table{
        margin:0 18px;
        min-width: 640px;
      }
    }
    >div:nth-child(2){
      overflow: scroll;
    }
    .caption{
      margin-left: 18px;
      margin-right: 18px;
      font-size: 13px;
      &:last-child{
        margin-bottom:-44px;
      }
    }
  }
`