import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Slide } from '../slide/slide';
import styled from 'styled-components';
import { useWindowSize } from '../../customHook/useWindowSize';

export function Area({setSelected, filterOptions, setFilterOptions, close}) {
  const location = useLocation();
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()
  const [landValues, setLandValues] = useState([0, 300]);
  const [buildingValues, setBuildingValues] = useState([0, 300]);

  const params = new URLSearchParams(location.search);

  const apply = ([minLandArea, maxLandArea], [minBuildingArea, maxBuildingArea]) => {
    minLandArea > 0 ? params.set('minLandArea', minLandArea*3.3) : params.delete('minLandArea')
    maxLandArea < 300 ? params.set('maxLandArea', maxLandArea*3.3) : params.delete('maxLandArea')
    minBuildingArea > 0 ? params.set('minBuildingArea', minBuildingArea*3.3) : params.delete('minBuildingArea')
    maxBuildingArea < 300 ? params.set('maxBuildingArea', maxBuildingArea*3.3) : params.delete('maxBuildingArea')
    close()
    navigate(`/search?${params.toString()}`)
  }

  useEffect(() => {
    let minLandAreaParams = parseInt(params.get('minLandArea')/3.3) || 0
    let maxLandAreaParams = parseInt(params.get('maxLandArea')/3.3) || 300
    let minBuildingAreaParams = parseInt(params.get('minBuildingArea')/3.3) || 0
    let maxBuildingAreaParams = parseInt(params.get('maxBuildingArea')/3.3) || 300

    // setLandValues([minLandAreaParams ? minLandAreaParams/3.3 : 0,maxLandAreaParams ? maxLandAreaParams/3.3 : 300])
    if(minLandAreaParams || maxLandAreaParams){
      setLandValues([minLandAreaParams,maxLandAreaParams])
    }

    if(minBuildingAreaParams || maxBuildingAreaParams){
      setBuildingValues([minBuildingAreaParams,maxBuildingAreaParams])
    }
    if(isMobile) return
    let landContent = ""
    if(minLandAreaParams > 0 || (maxLandAreaParams && maxLandAreaParams < 300)){
      if(minLandAreaParams > 0){
        landContent = landContent + minLandAreaParams+'평'
      }
      landContent = landContent+ "~"
      if(maxLandAreaParams && maxLandAreaParams < 300){
        landContent = landContent + maxLandAreaParams+'평'
      }
    }
    let builidingContent = ""
    if(minBuildingAreaParams > 0 || (maxBuildingAreaParams && maxBuildingAreaParams < 300)){
      if(minBuildingAreaParams > 0){
        builidingContent = builidingContent + minBuildingAreaParams+'평'
      }
      builidingContent = builidingContent+ "~"
      if(maxBuildingAreaParams && maxBuildingAreaParams < 300){
        builidingContent = builidingContent + maxBuildingAreaParams+'평'
      }
    }
    if(landContent === "" && builidingContent ===""){
      setSelected({content:"",count:0})
    } else {
      setSelected({content:landContent || builidingContent,count:landContent && builidingContent ? 1 : 0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.minLand = landValues[0]
    newFilterOptions.maxLand = landValues[1]
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landValues])

  useEffect(() => {
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.minBuilding = buildingValues[0]
    newFilterOptions.maxBuilding = buildingValues[1]
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingValues])


  const marks={
    0: '0평', 100: '100평', 200: '200평', 300: '무제한'
  }
  const style={}

  return (
    <Div>
      <h1>면적</h1>
      <header>
        <div>토지면적</div>
      </header>

      <div className="slideInput">
        <div>
          <label >최저</label>
          <input type="text" readOnly value={landValues[0]+'평'} />
        </div>
        <span>~</span>
        <div>
          <label >최고</label>
          <input type="text" readOnly value={landValues[1] === 300 ? "무제한": landValues[1]+'평' }/>
        </div>
      </div>

      <div className="slide">
        <Slide 
          sliderValues={landValues}
          setSliderValues={setLandValues}
          maxValue={300}
          step={10}
          marks={marks}
          stype={style}
        />
      </div>
      
      <header>
        <div>건물면적</div>
      </header>
      <div className="slideInput">
        <div>
          <label >최저</label>
          <input type="text" readOnly value={buildingValues[0]+"평"}/>
        </div>
        <span>~</span>
        <div>
          <label >최고</label>
          <input type="text" readOnly value={buildingValues[1] === 300 ? "무제한": buildingValues[1]+'평' }/>
        </div>
      </div>
      <div className="slide">
        <Slide 
          sliderValues={buildingValues}
          setSliderValues={setBuildingValues}
          maxValue={300}
          step={10}
          marks={marks}
        />
      </div>
      <div className="buttons">
        <button className="reset" onClick={() => { setSelected({content:"",count:0});setLandValues([0,300]); setBuildingValues([0,300]);}}>초기화</button>
        <button className="apply" onClick={() => { apply(landValues, buildingValues) }}>적용하기</button>
      </div>
    </Div>
  )
}
const Div = styled.div`
  h1{
    color: #333D4B;
    font-size: 22px;
    font-weight: 600;
  }
  header{
    margin-bottom: 12px !important;
    >div{
      color: #6B7684 !important;
      font-size: 17px !important;
      font-weight: 600 !important;
      margin-top:28px;
    }
  }
  .slideInput{
    border-bottom:0px !important;
    >input{
      width: 176px;
      margin-left:0px;
    }
  }

  .slide{
    border-bottom:1px solid #EAEAEA;
    >div{
      height: 82px !important;
      padding-top:0px !important;
    }
  }
  .buttons{
    border-top:0px !important; 
    margin-top:0px !important;
    padding-top:22px !important;
  }
  @media (max-width: 767px) {
    h1{
      font-size: 20px;
      text-align: left;
      margin-top:26px;
    }
    header{
      margin-top: 0px !important;
      margin-bottom: 6px !important;
      >div{
        margin-top:16px;
      }
    }
    .slideInput{
      padding-bottom:10px;
      >input{
        width: 146px;
      }
      span{
        margin-top:0px;
      }
    }
    .slide{
      border-bottom:1px solid #EAEAEA;
      >div{
        padding-top:24px !important;
      }
  }
  }
`