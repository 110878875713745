import styled from "styled-components";
import loginLogo from "../images/login_logo.svg"
import kakao from "../images/kakao.png"
// import naver from "../images/naver.png"
import { useEffect } from "react";
import { useWindowSize } from "../customHook/useWindowSize";

export default function Login() {
  let href = window.location.host.includes('localhost') ? 'http://localhost:8080' : 'https://www.auctionworldplus.com'
  const { innerHeight } = useWindowSize()
  useEffect(() => {
    document.documentElement.scrollTo({top: 0,left: 0,behavior: "instant"});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div $innerHeight={innerHeight}>
      <div>
        <div className="logo">
          <img src={loginLogo} alt="logo"/>
        </div>
        <div className="text">
          3초 회원가입으로<br/>
          간편하게 이용해보세요!
        </div>
        <div className="subText">
          경매광장 플러스 지금 바로 회원가입하고<br/>
          다양한 혜택을 즐겨보세요
        </div>
        <div className="loginButton">
          <a className="kakao" href={`${href}/auth/kakao`}>
            <img src={kakao} alt="kakao_image"/>카카오로 시작하기
          </a>
          {/* <a className="naver" href={`${href}/auth/naver`}>
            <img src={naver} alt="naver_image"/> 네이버로 시작하기
          </a> */}
        </div>
        <div className="copyright">
          Copyright ⓒ프릴스퀘어,Inc. All Rights Reserved
        </div>
      </div>
    </Div>
  );
}

const Div = styled.div`
  background: #F9F9F9;
  display: flex;
  align-items:center;
  height:${props => props.$innerHeight-76+'px'};
  min-height:964px; // 192 + 580 + 192
  >div{
    margin:0 auto;
    padding:92px 37px 0;
    max-width: 460px;
    width:calc(100% - 80px);
    height: 580px;
    flex-shrink: 0;
    background:#fff;
    box-shadow:0px 4px 30px rgba(0, 0, 0, 0.04);
    >div{
      text-align:center;
    }
    .logo{
      margin-bottom:48px;
    }
    .text{
      color: #333D4B;
      font-size: 22px;
      font-weight: 700;
      margin-bottom:24px;
    }
    .subText{
      color: #8B95A1;
      font-size: 14px;
      font-weight: 500;
      /* margin-bottom:50px; */
      margin-bottom:80px;
    }
    .loginButton{
      >a{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        line-height: 58px;
        border-radius: 6px;
        margin-bottom:14px;
        font-weight: 700;
        cursor:pointer;
        >img{
          margin-right:12px;
        }
      }
      .kakao{
        background: #FFE200;
        color: #000;
      }
      .naver{
        background: #35D400;
        color: #FFF;
      }
    }
    .copyright{
      color: #B0B8C1;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.072px;
      margin-top: 66px;
    }
  }
  @media (max-width: 767px) {
    padding-top:0;
    padding-bottom:34px;
    background:#fff;
    min-height:520px;
    >div{
      box-shadow:none;
      width:360px;
      padding-top:68px;
      height: 520px;
    }
  }
`