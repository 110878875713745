import { useWindowSize } from "../../customHook/useWindowSize";
import styled from "styled-components";

// login, add wish, my board
export default function DocumentModal({ detail }) {
  const { innerWidth, innerHeight } = useWindowSize()

  let documentAccepted = detail.documentAccepted && JSON.parse(detail.documentAccepted)
  let documentSent = detail.documentSent && JSON.parse(detail.documentSent)

  const getDocument = (documentData) => {
    return (
      <table>
        <thead>
          <tr>
            <th>접수일</th>
            <th>접수내역</th>
            <th>결과</th>
          </tr>
        </thead>
        <tbody>
          {documentData.map((data, index) => { // 순서 바뀔일 없어서 index를 key값으로 사용
            return (
              <tr key={index}>
                <td>{data.date}</td>
                <td>{data.content}</td>
                <td>{data.result}</td>
              </tr>
            )
          })
          }
        </tbody>
      </table>
    );
  };

  return (
    <Div $innerWidth={innerWidth} $innerHeight={innerHeight}>
      <div>
        <div className="title">문건/송달내역</div>
        <div className="separateBar" />

        {detail.documentAccepted && 
        <>
          <h1>문건처리내역</h1>
          <div className="document">
            {getDocument(documentAccepted)}
          </div>
        </>
        }
        {detail.documentAccepted && 
        <>
          <h1>송달내역</h1>
          <div className="document">
            {getDocument(documentSent)}
          </div>
        </>
        }
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width:${(props) => props.$innerWidth * 0.9}px;
  max-width: 920px;
  height:${(props) => props.$innerHeight * 0.9}px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top:60px;

  >div{
    overflow:scroll;
    text-align:center;
    width:100%;
    margin-bottom:24px;
  }
  .title{
    color: #333D4B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align:left;
    width: 87%;
    margin:0 auto;
    padding:0 0 24px;
  }
  .separateBar{
    width:87%;
    height: 1px;
    background: #DBDBDB;
    margin:0 auto 24px;
  }
  h1{
    margin: 20px auto;
    font-size: 20px;
    font-weight: 500;
    text-align:left;
    width:87%;
  }
  .document{
    /* width: 99%; */
    width: 100%;
    margin: 0 auto;
    overflow: scroll;
  }
  table{
    margin:0 6.5%;
    border: 1px solid #EAEAEA;
    border-collapse: collapse;
    min-width:87%;
    thead{
      height: 40px;
      background: #F2F4F6;
      th{
        border: 1px solid #EAEAEA;
        border-collapse: collapse;
        color: #333D4B;
        font-size: 14px;
        font-weight: 700;
        padding:6px 10px;
      }
    }
    tbody{
      tr{
        height: 40px;
        td{
          text-align:center;
          border: 1px solid #EAEAEA;
          border-collapse: collapse;
          color: #333D4B;
          font-size: 14px;
          font-weight: 500;
          white-space:nowrap;
          div{
            color: #333D4B;
            font-size: 14px;
            font-weight: 500;
          }
          span{
            color: #333D4B;
            font-size: 14px;
            font-weight: 500;
          }
          padding: 6px 10px;
        }
        td:first-child{
        }
        td:nth-child(2){
          text-align:left;
        }
        td:nth-child(3){
          text-align:left;
          min-width:124px;
        }
      }
    }
  }
  
  @media (max-width: 767px) {
    width:${(props) => props.$innerWidth}px;
    height:${(props) => props.$innerHeight}px;
    border-radius:0;
    padding-top:44px;
    .title{
      font-size: 18px;
    }
    h1{
      margin: 20px auto;
      font-size: 16px;
    }
    .document{ // 글자 테두리에 걸치는것 방지
      width: 99%;
    }

    table{
      thead{
        th{
          font-size: 13px;
        }
      }
      tbody{
        tr{
          height: 40px;
          td{
            font-size: 13px;
            div, span{
              font-size: 13px;
            }
          }
        }
      }
    }
  }
`