import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function NotFound() {


  return (
    <Div>
      <div className="title">404 Error</div>
      <div className="description">
        요청하신 페이지를 찾을 수 없습니다.
        <br/>
        입력하신 주소가 정확한지 다시 한번 확인해주세요.
      </div>
      <Link to="/">홈으로 돌아가기</Link>
    </Div>
  );
}

const Div = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 744px;
  justify-content: center;
  text-align:center;
  .title{
    color: #4E5968;
    font-size: 50px;
    font-weight: 600;
  }
  .description{
    color: #4E5968;
    font-size: 14px;
    font-weight: 500;
    margin:24px 0 46px;
  }
  >a{
    color: #1A6DFF;
    font-size: 14px;
    font-weight: 700;
    text-decoration-line: underline;
  }
`
