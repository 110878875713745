import styled from "styled-components";
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function SelectSearchType({type, setType}) {
  const navigate = useNavigate()
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  useEffect(()=>{
    let caseNumberParams = params.get('caseNumber');
    if(caseNumberParams || caseNumberParams === ''){
      setType(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Div>
      <div style={{paddingRight:"23px"}}>
        경매물건 검색
      </div>
      <div className="type">
        <span onClick={()=>{setType(true);params.delete('caseNumber');navigate(`/search?${params.toString()}`)}} className={type ? "active" : "unactive"} style={!type ? {color:"#BAC3CF"} : {}}>
          경매물건
        </span>
        <span onClick={()=>{setType(false);params.set('caseNumber','');navigate(`/search?${params.toString()}`)}} className={type ? "unactive" : "active"}  style={type ? {color:"#BAC3CF"} : {}}>
          사건번호
        </span>
      </div>
    </Div>
  );
}

const Div = styled.div`
  display:flex;
  margin:42px 0 0;
  *{
    color: #333D4B;
    font-size: 26px;
    font-weight: 500;
  }
  .type{
    padding-left:14px;
    border-left:1px solid #DFDFDF;
    > span{
      font-weight: 700;
      padding:0 10px;
      transition:0.1s;
    }
    .unactive{
      cursor:pointer;
    }
    > .unactive:hover{
      text-decoration: underline;
      color: #6B7684 !important;
    }
  }
  @media (max-width: 767px) {
    margin-top:32px;
    *{
      font-size: 22px;
    }
    >div:first-child{
      padding-right:12px !important;
    }
    .type{
      padding-left:7px;
      > span{
        padding:0 7px;
        &.active{
          text-decoration:underline;
        }
      }
    }
  }
`