import styled from "styled-components"
import useAPI from "../../customHook/useAPI"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AuctionItemCard from "../auctionItemCard"
import { useModal } from "../../customHook/useModal"
import checkIcon from "../../images/checkIcon.svg"
import { useBlock } from "../../customHook/useBlock"
import { useAuth } from "../../customHook/useAuth"
import { useInView } from 'react-intersection-observer';

export default function Like() {
  const { getAPI, postAPI } = useAPI()
  const [apiData, setApiData] = useState();
  const [count, setCount] = useState(null)
  const [isEditMode, setEditMode] = useState(false)
  const [editChecked, setEditChecked] = useState([])
  const [isPagination, setPagenation] = useState(true)
  const [page, setPage] = useState(1); // pagination
  const { modalShow, modalClose } = useModal()
  const [reload, setReload] = useState(Math.random())
  const { isBlock, setBlock } = useBlock()
  const { user } = useAuth()

  const navigate = useNavigate()

  const deleteLike = async (e, editChecked, user) => {
    e.stopPropagation()
    if (editChecked.length === 0) return

    for (let i = 0; i < editChecked.length; i++) {
      let data = { auctionId : editChecked[i]}
      let { success } = await postAPI(`/auction/like`, data)
      if (success !== true) {
        modalShow({ message: `좋아요 삭제에 실패했습니다.`, submessage: '잠시 후 다시 시도해주세요.', actionButton: <div onClick={() => { modalClose() }}>닫기</div> })
        break
      }
    }
    setReload(Math.random())
    return
  }

  const auctionList = apiData?.map((data) => {
    if (isEditMode) {
      let checkedIndex = editChecked.indexOf(data.auctionId)
      return (
        <div key={data.auctionId} className="editBox"
          onClick={checkedIndex >= 0 ?
            () => {
              let newEditChecked = [...editChecked]
              newEditChecked.splice(checkedIndex, 1)
              setEditChecked(newEditChecked)
            }
            :
            () => { setEditChecked([...editChecked, data.auctionId]) }}
        >
          <div className="checkImage">
            <div style={checkedIndex >= 0 ? { backgroundImage: `url(${checkIcon})`, opacity: '1' } : { backgroundImage: `url(${checkIcon})`, opacity: '0' }}></div>
          </div>
          <div className="editButton" style={checkedIndex >= 0 ? { opacity: '1' } : {}} />
          <div className="auctionItemCard">
            <AuctionItemCard data={data} />
          </div>
        </div>
      )
    } else {
      return (
        <div key={data.auctionId} className="auctionItemCard">
          <AuctionItemCard data={data} />
        </div>
      )
    }
  })

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1, // 요소가 10% 이상 화면에 나타나면 콜백 실행
  });

  useEffect(() => {
    if(!user){return}
    // setBlock(true)
    const fetchAPI = async () => {
      let { success, data } = await getAPI(`/auction/like?count=true`);
      if (success) {
        setApiData(data.auctionList)
        if (data.count) { // page가 1일때만 불러옴
          setCount(data.count)
        }
        setEditMode(false)
        setEditChecked([])
        // setBlock(false)
      }
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, user]) // 처음에 user null이라 user도 dependency에 넣어줌

  useEffect(() => {
    if (isPagination && inView && apiData?.length >= 12) {
      setBlock(true)
      const fetchAPI = async () => {
        let newUrl =`/auction/like?page=${page + 1}`
        let { data } = await getAPI(newUrl)
        if(data.auctionList.length < 12){
          setBlock(false)
          setPagenation(false)
        }
        setApiData([...apiData, ...data.auctionList]);
        setPage(page + 1)
        setBlock(false)
      }
      fetchAPI()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Div>
      {!isBlock &&
      <>
        {apiData?.length > 0 ?
          <>
            <div className="top">
              <div className="count">
              {!isEditMode ?
                `총 ${count?.toLocaleString() || 0}건`
                :
                <>
                <span style={{color:'#1A6DFF'}}>
                  {editChecked.length}
                </span>
                /{count?.toLocaleString() || 0}
                </>
              }
              </div>
              <div className="editArea">
                {!isEditMode ?
                  <div className="edit" onClick={() => { setEditMode(true) }}>
                    편집하기
                  </div>
                  :
                  <div>
                    <span onClick={() => { setEditChecked([]) }}>선택해제</span>
                    <span onClick={(e) => { deleteLike(e, editChecked) }}>삭제</span>
                    <span onClick={() => { setEditMode(false); setEditChecked([]) }}>취소</span>
                  </div>
                }
              </div>
            </div>
            <div className="likeItem">{auctionList}</div>
            <div ref={ref} style={{ height: '50px' }}></div>
          </>
          :
          <div className="noItem">
            <div>관심물건 리스트가 없습니다.</div>
            <div>지금 당장 다양한 관심 물건들을 만나보세요!</div>
            <div onClick={() => { navigate('/search') }}>물건 보러가기</div>
          </div>
        }
      </>
      }
    </Div>
  )
}

const Div = styled.div`
  min-height:467px;
  padding-bottom: 90px;
  .top{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .count{
      color: #333D4B;
      font-size: 18px;
      font-weight: 700;
      margin: 37px 0 20px;
    }
  }
  .editArea{
    color: #6B7684;
    font-weight: 500;
    .edit{
      text-decoration-line: underline;
      cursor:pointer;
    }
    >div{
      >span{
        margin-right:16px;
        cursor:pointer;
      }
    }
  }

  .likeItem{
    max-width:1200px;
    width:100%;
    margin:0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:30px 14px;
    text-align:left;
    .editBox{
      position: relative;
      cursor:pointer;
      .checkImage{
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: #fff;
        top:12px;
        left:12px;
        z-index:2;
        > div{
          background-repeat: no-repeat;
          border-radius: 4px;
          background-position: center;
          transition:0.2s;
          height:100%;
          width:100%;
          background-color: #1A6DFF;
        }
      }
      .editButton{
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index:1;
        opacity: 0;
        transition: 0.2s;
      }
    }
    .editBox:hover{
      .editButton{
        opacity: 1;
      }
    }
  }
  .noItem{
    text-align:center;
    div:first-child{
      color: #4E5968;
      font-size: 18px;
      font-weight: 700;
      padding-top:150px;
    }
    div:nth-child(2){
      color: #8B95A1;
      font-size: 14px;
      font-weight: 500;
      margin:12px 0 28px;
    }
    div:nth-child(3){
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      width: 164px;
      height: 46px;
      line-height: 46px;
      border-radius: 6px;
      background: #1A6DFF;
      margin: 0 auto;
      cursor:pointer;
    }
  }

  @media (max-width: 767px) {
    min-height:inherit;
    padding-bottom:70px;
    .top{
      .count{
        font-size: 16px;
        margin: 26px 0 16px;
      }
    }
    .editArea{
      font-size: 14px;
      >div{
        font-size: 14px;
        >span{
          font-size: 14px;
          margin-right:14px;
        }
      }
    }

    .likeItem{
      grid-template-columns: 1fr 1fr;
      .auctionItemCard{
        /* min-width:324px; */
        >div{
          height:380px;
          >img{
            height:196px;
          }
          >div{
            height:184px;
            padding-top:14px !important;
          }
        }
      }
    }
    .noItem{
      div:first-child{
        padding-top:117px;
      }
      
    }
  }
  @media (max-width: 580px) {
    .likeItem{
      grid-template-columns: 1fr;
      width:324px;
      margin:0;
    }
  }
  @media (max-width: 420px) {
    .likeItem{
      margin:0 auto;
    }
  }


`