import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Slide } from '../slide/slide';
import { convertToKoreanCurrencyForSlide } from '../../utils';
import { useWindowSize } from '../../customHook/useWindowSize';


export function Appraisal({setSelected,filterOptions,setFilterOptions, close}) {
  const location = useLocation();
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()

  const params = new URLSearchParams(location.search);

  const [sliderValues, setSliderValues] = useState([0, 2000000000]);

  const maxValue = 2000000000
  const grid = 500000000
  const step = 10000000

  const apply = ([minAppraisalValue, maxAppraisalValue]) => {
    minAppraisalValue > 0 ? params.set('minAppraisalValue', minAppraisalValue) : params.delete('minAppraisalValue')
    maxAppraisalValue < maxValue ? params.set('maxAppraisalValue', maxAppraisalValue) : params.delete('maxAppraisalValue')
    close()
    navigate(`/search?${params.toString()}`)
  }

  useEffect(() => {
    let minAppraisalValueParams = params.get('minAppraisalValue')
    let maxAppraisalValueParams = params.get('maxAppraisalValue')
    if(minAppraisalValueParams || maxAppraisalValueParams){
      setSliderValues([parseInt(minAppraisalValueParams) || 0,parseInt(maxAppraisalValueParams) || 2000000000])
    }
    if(isMobile) return
    let content = ""
    if(minAppraisalValueParams > 0 || (maxAppraisalValueParams && maxAppraisalValueParams < maxValue)){
      if(minAppraisalValueParams > 0){
        content = content + convertToKoreanCurrencyForSlide(minAppraisalValueParams)
      }
      content = content+ "~"
      if(maxAppraisalValueParams && maxAppraisalValueParams < maxValue){
        content = content + convertToKoreanCurrencyForSlide(maxAppraisalValueParams)
      }
      setSelected({content,count:0})
    } else {
      setSelected({content,count:0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.minAppraisal = sliderValues[0]
    newFilterOptions.maxAppraisal = sliderValues[1]
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValues])

  const marks = {};
  for (let i = 0; i <= maxValue; i += grid) {
    marks[i] = (i/100000000).toLocaleString();
    if(marks[i] !== "0"){
      marks[i] += '억'
    } else {
      marks[i] += '원'
    }
  }
  marks[maxValue] = '무제한';

  return (
    <Div>
      <header>
        <div>감정가</div>
      </header>
      <div className="slideInput">
        <div>
          <label >최저</label>
          <input type="text" readOnly value={convertToKoreanCurrencyForSlide(sliderValues[0])} />
        </div>
        <span>~</span>
        <div>
          <label >최고</label>
          <input type="text" readOnly value={convertToKoreanCurrencyForSlide(sliderValues[1])} />
        </div>
      </div>

      <div>
        <Slide 
          sliderValues={sliderValues}
          setSliderValues={setSliderValues}
          maxValue={maxValue}
          step={step}
          marks={marks}
        />
      </div>

      <div className="buttons">
        <button className="reset" onClick={() => { setSliderValues([0, 2000000000]) }}>초기화</button>
        <button className="apply" onClick={() => { apply(sliderValues) }}>적용하기</button>
      </div>

    </Div>
  )
}

const Div = styled.div`

`