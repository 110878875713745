import styled from "styled-components"
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Answer() {
  const navigate = useNavigate()
  const location = useLocation();
  const [data, setData] = useState(null)

  useEffect(()=>{
    let questionData = get(location.state, "questionData")
    if(!questionData){
      navigate('/mypage/question')
    }
    setData(get(location.state, "questionData"))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.state])


  return (
    <Div>
     {data &&
      <>
        <h6>
          1:1문의 목록
        </h6>
        <div className="title">
          {data.title}
        </div>
        <div className="content">
          {data.content}
        </div>


        <div className="reply">
          <p>답변</p>
          {data.reply}
        </div>
        <button onClick={()=>{navigate('/mypage/question')}}>
          목록으로
        </button>
      </>
     }
    </Div>
  )
}

const Div = styled.div`
  max-width: 820px;
  width:(100% - 80px);
  margin:72px auto 200px;
  >h6{
    color: #8B95A1;
    font-size: 15px;
    font-weight: 500;
    text-decoration-line: underline;
  }
  >.title{
    color: #191F28;
    font-size: 30px;
    font-weight: 700;
    margin:26px 0 50px;
  }
  >.content{
    color: #4E5968;
    font-weight: 500;
    padding-bottom:46px;
    border-bottom: 1px solid #EAEAEA;
  }
  >.reply{
    margin:32px 0 42px;
    border-radius: 12px;
    background: #F9FAFB;
    padding:40px 34px;
    >p{
      width: 46px;
      height: 26px;
      line-height: 26px;
      text-align:center;
      border-radius: 100px;
      background: #E5E8EB;
      color: #6B7684;
      font-size: 12px;
      font-weight: 700;
      margin-bottom:16px;
    }
  }
  >button{
    width: 112px;
    height: 46px;
    line-height: 46px;
    text-align:center;
    border-radius: 4px;
    border: 1px solid #D7D7D7;
    background: #FFF;
  }
`