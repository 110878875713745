import React from "react";
import styled from "styled-components"
import ViewMore from "./viewMore";

export default function RegistrationCertificate({address, viewMore, setViewMore}) {

  const andCharacterParsing = (data) => {
    if (data.length >= 2 && data[0].startsWith('&') && !data[0].endsWith('&')) {
      // 첫번째인덱스가 &로 시작하지만 &로 끝나야함. 만약 그렇지 않는다면
      if (data[1].startsWith('&')) {
        // 두번째 인덱스가 &로 시작하는지 확인. 만약 &로 시작한다면 두번째인덱스의 &를 첫번째로 옮기기
        data[0] = data[0] + '&';
        data[1] = data[1].replace(/^&/, '');
      }
    }
    return data;
  }

  const splitRCdata = (data) => { // 주요등기사항 분할.
    let regex = /(\D*\d{1,3}(?:,\d{3})*\s*원(?:\s*\([^)]*\))?)/;
    const match = data.match(regex);
    let matchedData = []
    if (match?.[0]) {
      matchedData.push(match[0], data.replace(match[0], "").trim())
    } else {
      matchedData.push(data, '-')
    }
    // 10^ 등 줄바꿈 문자를 개행문자로 변경
    let andCharacterParsedData = andCharacterParsing(matchedData)

    let newMatchedData = andCharacterParsedData.map(data => data.replace(/\d+\^/g, '\n')) 
    // 권리자, 채권액 순서 로직
    if(newMatchedData[0].startsWith('청구금액')){
      return newMatchedData.reverse()
    }
    if(newMatchedData[1]?.startsWith('채권자')|| 
      newMatchedData[1]?.startsWith('근저당권자') || 
      newMatchedData[1]?.startsWith('저당권자') || 
      newMatchedData[1]?.startsWith('전세권자') || 
      newMatchedData[1]?.startsWith('임차권자') ||
      newMatchedData[1]?.startsWith('가등기권자') ||
      newMatchedData[1]?.startsWith('지상권자') ||
      newMatchedData[1]?.startsWith('지역권자') ||
      newMatchedData[1]?.startsWith('권리자') ||
      newMatchedData[1]?.startsWith('소유자')
      ){
      return newMatchedData.reverse()
    }
    return newMatchedData
  }

  const insertStyleSpans = (str) => { // &와 &사이 text line-through로 꾸며주기.
    const regex = /&([^&]+)&/g;
    let match;
    let lastIndex = 0;
    const styledParts = [];

    while ((match = regex.exec(str)) !== null) {
      const start = match.index;
      const end = regex.lastIndex;
      const plainTextBefore = str.substring(lastIndex, start);
      const styledText = match[0].replace(/&/g, '');
      styledParts.push(plainTextBefore, (
        <span key={start} style={{ textDecoration: "line-through", textDecorationColor: "red" }}>
          {styledText}
        </span>
      ));
      lastIndex = end;
    }

    styledParts.push(str.substring(lastIndex));
    return styledParts;
  };

  const getRegistrationCertificateData = (address, listType, index) => {
    let filteredAddress = address.filter(address => address.listType === listType)[0]
    let RCdata = filteredAddress.registrationCertificate && JSON.parse(filteredAddress.registrationCertificate.RCdata)
    return (
      <div style={viewMore[3+index] ? {maxHeight:'initial'} : {maxHeight:'471px'}}>
        <h1>{`• ${listType} 등기부`}</h1>
        <div>
          <table>
            <thead>
              <tr>
                <th style={{width:"114px"}} >등기일자</th>
                <th>권리종류</th>
                <th style={{width:"320px"}}>권리자/채권금액</th>
                <th style={{whiteSpace:'nowrap', width:"72px"}}>{`소멸여부`}</th>
              </tr>
            </thead>
            <tbody>
              {RCdata ? RCdata?.map((data, index) => {
                const mainRCdata = splitRCdata(data["주요등기사항"])
                const dateRegex = /(\d{4}년\d{1,2}월\d{1,2}일)/;
                if(!data["등기일자"].match(dateRegex)?.[0]){
                  return null
                }
                const date = data["등기일자"].match(dateRegex)[0] || data["등기일자"]

                return (
                  <React.Fragment key={`${index}`}>
                    <tr key={index}>
                      <td rowSpan="2">
                        {date.replace('년','.').replace('월','.').replace('일','')}
                        <br/>
                        {`(${data["출처"]} ${data["순위번호"]})`}
                      </td>
                      <td>{data["권리종류"]}</td>
                      <td>{insertStyleSpans(mainRCdata[0])}</td>
                      <td rowSpan="2" style={data["권리"] === '인수' ? {color: "#D91C18"} : {}}>{data["권리"]}</td>
                    </tr>
                    <tr>
                      <td>{data["권리정보"]}</td>
                      <td>{insertStyleSpans(mainRCdata[1])}</td>
                    </tr>
                  </React.Fragment>
                )
              })
              :
              <tr>
                <td colSpan="6">
                  등기부등본이 등록되지않았습니다. 등기부 등본을 호출해주세요.
                </td>
              </tr>
            }
            </tbody>
          </table>
        </div>
        {RCdata?.length> 4 && <ViewMore index={3+index} viewMore={viewMore} setViewMore={setViewMore}/>}
      </div>
    )
  }
  const listTypes = [...new Set(address.map(item => item.listType))];
  return (
    <Div>
      <h2>등기부 현황</h2>
      {listTypes.map((listType, index) => {
        return <div key={index}>{getRegistrationCertificateData(address, listType, index)}</div>
      })}

    </Div>
  )
}

const Div = styled.div`
  margin-bottom:28px !important;
  >h2{
    margin-top:44px;
    color: #191F28;
    font-size: 22px;
    font-weight: 700;
  }
  >div{
    position:relative;
    h1{
      margin-top:28px;
      color: #4E5968;
      font-size: 20px;
      font-weight: 700;
    }
    >div{
      overflow: hidden;
    }
    table{
      margin:14px 0 44px;
    }
  }
  @media (max-width: 767px) {
    margin:0px !important;
    width:100% !important;
    >h2{
      font-size: 20px;
      margin-top:36px;
      margin-left: 18px;
      margin-right: 18px;
    }
    >div{
      overflow: scroll;
      h1{
        margin-left: 18px;
        margin-right: 18px;
        margin-top:20px;
        font-size: 18px;
      }
      >div{
        overflow-x: scroll;
      }
      table{
        margin-left: 18px;
        margin-right: 18px;
        min-width: 640px;
      }
    }
  }
`