import styled from "styled-components"
import subLogo from '../images/sub_logo.svg'
// login
export default function Footer(){
  let currentURL = window.location.pathname;
  if (currentURL === '/login') {
    return null
  }
  return(
    <Div>
      <div>
        <img src={subLogo} alt="logo"/>
        <div>
          <h3>(주)프릴스퀘어 (대표이사:정다은) | 사업자번호 : 232-88-02694</h3>
          <h3>문의: auctionworld.plus@gmail.com Copyright</h3>
          <h3>ⓒ 프릴스퀘어,Inc. All Rights Reserved</h3>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width:1920px;
  width:100%;
  height:260px;
  background: #EDF1F6;
  margin:0 auto;
  >div{
    max-width:1200px;
    max-width: 1200px;
    width:calc(100% - 80px);
    margin:0 auto;
    padding-top:66px;
    >img{
      display: block;
    }
    >div{
      margin-top:36px;
      >h3{
        color: #869AB7;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.072px;
        line-height: 18px;

      }
    }
  }
  @media (max-width: 767px) {
    min-width:360px;
    >div{
      width:calc(100% - 36px);
    }
  }
`