import styled from 'styled-components';
import building from '../images/building2.png'
import { Link } from 'react-router-dom';
export default function SubBanner(){
  return(
    <Div>
      <div className="building" >
        <div className="background">
          <h6>부동산경매 실전 & 투자, 특수물건</h6>
          <h1>경매광장플러스 회원가입하고<br/> 다양하게 즐기세요!</h1>
          <button>
            <Link to="/login">회원가입 하기</Link>
          </button>
        </div>
        <img src={building} alt="building" />
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width: 1200px;
  height:410px;
  margin:0 auto 90px;
 .building{
    position: relative;
    height: 100%;
    .background{
      position: absolute;
      bottom:0;
      width: 100%;
      height:100%;
      border-radius: 20px;
      background: #4E99D2;
      padding:70px 88px;
      >h6{
        color: #FFF;
        font-size: 15px;
        font-weight: 600;
      }
      >h1{
        color: #FFF;
        font-size: 36px;
        font-weight: 700;
        margin:21px 0 40px;
        line-height:49px;
      }
      >button{
        width: 140px;
        height: 54px;
        border-radius: 100px;
        background: #FFF;
        color: #4E5968;
        font-weight: 700;
        cursor:pointer;
      }
    }
    >img{
      position: absolute;
      right: 0;
      bottom:0;
      width:71%;
      height: 72%;
      border-radius: 0 0 20px 0;
    }
  }
  @media (max-width: 767px) {
    height: 300px;
    .building{
      .background{
        padding:36px 22px;
        >h6{
          font-size: 13px;
        }
        >h1{
          font-size: 24px;
          line-height:35px;
          margin:23px 0 68px;
          position: relative;
          z-index:1;
        }
        >button{
          width: 130px;
          height: 50px;
          font-size: 14px;
          position: relative;
          z-index:1;
        }
      }
      >img{
        width:80%;
        height:50%;
      }
    }
  }
`