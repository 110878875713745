import styled from "styled-components"
import useAPI from "../../customHook/useAPI"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useBlock } from "../../customHook/useBlock"
import { useAuth } from "../../customHook/useAuth"

export default function Question() {
  const { getAPI } = useAPI()
  const [apiData, setApiData] = useState([]);
  const navigate = useNavigate()
  const { isBlock, setBlock } = useBlock()
  const { user } = useAuth()

  useEffect(() => {
    if(!user){return}
    setBlock(true)
    const fetchAPI = async () => {
      let { success, data } = await getAPI(`/question`);
      if (success) {
        setApiData(data)
      }
      setBlock(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTime = (time) => {
    const originalDate = new Date(time);
    const addedHours = 9;
    const newDate = new Date(originalDate.getTime() + addedHours * 60 * 60 * 1000);

    // 필요한 형식으로 날짜와 시간을 추출
    return newDate.toISOString().split('T')[0]; // YYYY-MM-DD 형식

  }

  return (
    <Div>
      <div className="top">
        <div>
          <div className="title">1:1 문의내역</div>
          <div className="description">한번 등록한 상담 내용은 수정이 불가능합니다.</div>
        </div>
        <div className="write" onClick={() => { navigate('/mypage/questionForm') }}>
          1:1 문의쓰기
        </div>
      </div>
      <div className="questionList">
        <div className="tableHeader">
          <div>
            제목
          </div>
          <div>
            작성일
          </div>
          <div>
            답변유무
          </div>
        </div>
        <div className="tableRow">
          {!isBlock &&
            <>
              {apiData.length > 0 ?
                <>
                  {apiData.map((data) => {
                    return (
                      <div key={data.key} style={data.reply && { cursor: "pointer" }} onClick={() => {
                        data.reply && navigate(`/mypage/question/${data.key}`, {
                          state: { questionData: data }
                        })
                      }}>
                        <div>
                          {data.title}
                        </div>
                        <div>
                          {getTime(data.createdAt)}
                        </div>
                        <div>
                          {!data.reply ? <span>답변대기</span> : <span className="complete">답변완료</span>}
                        </div>
                      </div>
                    )
                  })}
                </>
                :
                <div className="noQuestion">
                  문의한 내역이 없습니다.
                </div>
              }
            </>
          }
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  .top{
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:130px;
    .title{
      color: #4E5968;
      font-size: 22px;
      font-weight: 700;
      margin-bottom:10px;
    }
    .description{
      color: #8B95A1;
      font-size: 15px;
      font-weight: 500;
    }
    .write{
      width: 112px;
      height: 46px;
      line-height: 46px;
      text-align:center;
      border-radius: 6px;
      background: #1A6DFF;
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
      cursor:pointer;
    }
  }

  .questionList{
    border-top:2px solid #333D4B;
    margin-bottom:140px;
    .tableHeader{
      height:54px;
      line-height:54px;
      display:flex;
      color: #4E5968;
      font-size: 15px;
      font-weight: 700;
      >div:first-child{
        flex:1;
      }
      >div:last-child{
        margin-left:52px;
      }
      border-bottom:1px solid #EAEAEA;
    }
    .tableRow{
      >div{
        height:54px;
        line-height:54px;
        border-bottom:1px solid #EAEAEA;
        display:flex;
        color: #333D4B;
        font-size: 15px;
        font-weight: 500;

        >div:first-child{
        flex:1;
        }
        >div:last-child{
          margin-left:32px;
          color: #B0B8C1;
          .complete{
            color: #1A6DFF;
          }
        }
      }
      .noQuestion{
        text-align:center;
        color: #B0B8C1;
        font-size: 15px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: 767px) {
    .top{
      display:block;
      padding: 34px 0;
      height:inherit;
      .title{
        font-size: 16px;
        margin-bottom:84pxpx;
      }
      .description{
        font-size: 13px;
        margin-bottom:18px;
      }
    }
    .questionList{
      /* border-top:2px solid #333D4B; */
      /* margin-bottom:140px; */
      .tableHeader{
        display: none;
      }
      .tableRow{
        height:84px;
        >div{
          height:inherit;
          line-height:inherit;
          display:block;
          *{font-size: 13px;}

          >div:first-child{
            padding-top:22px;
            margin-bottom:8px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 19px;
            height: 38px;
          }
          >div:nth-child(2){
            display:inline-block;
          }
          >div:nth-child(2):after{
            display:inline-block;
            margin-left:8px;
            content:'';
            border-right:1px solid #E5E8EB;
            height:10px;
            width:1px;
          }
          >div:last-child{
            display:inline-block;
            margin-left:8px;
          }
        }

        .noQuestion{
          height:100%;
          font-size: 13px;
        }
      }
    }
  }
 
`