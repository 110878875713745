
import { useLocation, useNavigate } from "react-router-dom"

const headers = {
  'content-type': 'application/json',
  'Accept': 'application/json'
}

export default function useAPI() {
  const location = useLocation()
  const navigate = useNavigate()

  const getAPI = async (url) => {
    return await fetch(url, {
      method: "GET",
      credentials: 'same-origin',
      headers,
    }).then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
      .then(res => {
        if (res.status === 200) {
          return { success: res.body.success, data: res.body.data }
        }
        else {
          let { success, data, message } = res.body
          return { success, status: res.status, data, message }
        }
      })
  }

  const postAPI = async (url, data) => {
    return await fetch(url, {
      method: "POST",
      credentials: 'same-origin',
      headers,
      body: JSON.stringify(data)
    })
      .then(async (res) => {
        return { status: res.status, body: await res.json() }
      }
      )
      .then(res => {
        if (res.status === 201) {
          return { success: res.body.success, data: res.body.data }
        }
        else {
          let { success, data, message } = res.body
          return { success, status: res.status, data, message }
        }
      })
  }

  const putAPI = async (url, data) => {
    return await fetch(url, {
      method: "PUT",
      credentials: 'same-origin',
      headers,
      body: JSON.stringify(data)
    })
      .then(async (res) => {
        return { status: res.status, body: await res.json() }
      }
      )
      .then(res => {
        if (res.status === 201) {
          return { success: res.body.success, data: res.body.data }
        }
        else {
          let { success, data, message } = res.body
          return { success, status: res.status, data, message }
        }
      })
  }

  const patchAPI = async (url, data) => {
    return await fetch(url, {
      method: "PATCH",
      credentials: 'same-origin',
      headers,
      body: JSON.stringify(data)
    })
      .then(async (res) => {
        return { status: res.status, body: await res.json() }
      }
      )
      .then(res => {
        if (res.status === 201) {
          return { success: res.body.success, data: res.body.data }
        }
        else {
          let { success, data, message } = res.body
          return { success, status: res.status, data, message }
        }
      })
  }

  const deleteAPI = async (url) => {
    return await fetch(url, {
      method: "DELETE",
      credentials: 'same-origin',
      headers,
    }).then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
      .then(res => {
        if (res.status === 200) {
          return { data: res.body.data }
        } else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false
          navigate(location.pathname, {
            state: { errorStatusCode: responseCode }
          });
          return { responseCode, responseMessage, result }
        }
      })
  }

  return { getAPI, postAPI, putAPI, patchAPI, deleteAPI }

}


