import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Slide } from '../slide/slide';
import { convertToKoreanCurrencyForSlide } from '../../utils';
import { useWindowSize } from '../../customHook/useWindowSize';

export function BidingPrice({setSelected, filterOptions, setFilterOptions, close}) {
  const location = useLocation();
  const navigate = useNavigate()

  const { isMobile } = useWindowSize()
  const params = new URLSearchParams(location.search);

  const [sliderValues, setSliderValues] = useState([0, 2000000000]);

  const maxValue = 2000000000
  const grid = 500000000
  const step = 10000000

  const apply = ([minBidPrice, maxBidPrice]) => {
    minBidPrice > 0 ? params.set('minBidPrice', minBidPrice) : params.delete('minBidPrice')
    maxBidPrice < maxValue ? params.set('maxBidPrice', maxBidPrice) : params.delete('maxBidPrice')
    close()
    navigate(`/search?${params.toString()}`)
  }

  useEffect(() => {
    let minBidPriceParams = params.get('minBidPrice')
    let maxBidPriceParams = params.get('maxBidPrice')
    if(minBidPriceParams || maxBidPriceParams){
      setSliderValues([parseInt(minBidPriceParams) || 0, parseInt(maxBidPriceParams) || 2000000000])
    }
    if(isMobile) return
    let content = ""
    if(minBidPriceParams > 0 || (maxBidPriceParams && maxBidPriceParams < maxValue)){
      if(minBidPriceParams > 0){
        content = content + convertToKoreanCurrencyForSlide(minBidPriceParams)
      }
      content = content+ "~"
      if(maxBidPriceParams && maxBidPriceParams < maxValue){
        content = content + convertToKoreanCurrencyForSlide(maxBidPriceParams)
      }
      setSelected({content,count:0})
    } else {
      setSelected({content,count:0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if(!isMobile) return
    let newFilterOptions = filterOptions
    newFilterOptions.minBidding = sliderValues[0]
    newFilterOptions.maxBidding = sliderValues[1]
    setFilterOptions(newFilterOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValues])

  const marks = {};
  for (let i = 0; i <= maxValue; i += grid) {
    marks[i] = (i/100000000).toLocaleString();
    if(marks[i] !== "0"){
      marks[i] += '억'
    } else {
      marks[i] += '원'
    }
  }
  marks[maxValue] = '무제한';

  return (
    <Div>
      <header>
        <div>입찰가</div>
      </header>
      <div className="slideInput">
        <div>
          <label >최저</label>
          <input type="text" readOnly value={convertToKoreanCurrencyForSlide(sliderValues[0])} 
            // onChange={(e) => { handleValueChange(e.target) }} 
          />
        </div>
        <span>~</span>
        <div>
          <label >최고</label>
          <input type="text" readOnly value={convertToKoreanCurrencyForSlide(sliderValues[1])} 
            // onChange={(e) => { handleValueChange(e.target)}}
          />
        </div>
      </div>
      <div>
        <Slide 
          sliderValues={sliderValues}
          setSliderValues={setSliderValues}
          maxValue={maxValue}
          step={step}
          marks={marks}
        />
      </div>
      <div className="buttons">
        <button className="reset" onClick={() => { setSelected({content:"",count:0});setSliderValues([0, 2000000000]) }}>초기화</button>
        <button className="apply" onClick={() => { apply(sliderValues) }}>적용하기</button>
      </div>
    </Div>
  )
}

const Div = styled.div`

`