import styled from 'styled-components';
import AuctionItemCard from './auctionItemCard'
import arrow_right from "../images/arrow_right.png"
import slickArrow from "../images/slickArrow.svg"
import Slider from "react-slick";
import { useWindowSize } from '../customHook/useWindowSize';
import { useNavigate } from 'react-router-dom';

export default function RecommendList({ apiData }) {
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()

  const getAuctionList = (apiData) => {
    return apiData?.map(data => <div key={data.key}><AuctionItemCard data={data}/></div>)
  }

  function NextArrow({ currentSlide, slideCount, slidesToShow, onClick }) {
    return (
      <div onClick={onClick} className={"next slick-arrow" + (currentSlide === slideCount - slidesToShow ? " slick-disabled" : "")}>
        <img src={slickArrow} alt="arrow_right" />
      </div>
    );
  }

  function PrevArrow({ currentSlide, onClick }) {
    return (
      <div onClick={onClick} className={"prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}>
        <img src={slickArrow} alt="arrow_left" />
      </div>
    );
  }
  

  const settings = {
    className: "slick",
    infinite: false,
    pauseOnFocus:true,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow slidesToShow={4} />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          nextArrow: <NextArrow slidesToShow={3} />,
        }
      }
    ]
  };

  return (
    <Div>
      <h1>경매광장이 추천하는 물건</h1>
      {isMobile ?
        <div className="auctionList">
          {getAuctionList(apiData)}
        </div>
        :
        <Slider {...settings}>
          {getAuctionList(apiData)}
        </Slider>
      }
      <button onClick={()=>{navigate(`/recommend`)}}>추천물건 더보기<img src={arrow_right} alt="arrow right"/></button>
    </Div>
  );
}

const Div = styled.div`
  margin:0 auto;
  max-width: 1200px;
  width:100%;
  text-align:center;


  .slick-slider{
    position:relative;
    .slick-slide{
      padding:12px;
    }
    .slick-disabled{
      display:none;
    }
    .prev, .next{
      top:calc(50% - 29px);
      z-index:1;
      position:absolute;
      width: 58px;
      height: 58px;
      cursor:pointer;
      >img{
        width:100%;
        height:100%;
      }
    }
    .prev{
      transform:rotate(180deg);
      left: -21px;
    }
    .next{
      right:-21px;
    }
  }

  h1{
    text-align:center;
    color: #191F28;
    font-size: 28px;
    font-weight: 700;
    margin-bottom:22px;
  }

  >button{
    margin:34px auto 122px;
    width: 208px;
    height: 54px;
    border-radius: 4px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    transition:0.1s;
    &:hover{
      background: #F8F8F9;
    }
  }

  @media (max-width: 1023px) {
    .slick-slide{
      padding:12px 7px;   
    }
  }

  @media (max-width: 767px) {
    width: 100% !important;
    h1{
      margin-top:70px;
      font-size: 22px;
    }
    .auctionList{
      display: grid;
      grid-gap:14px;
      margin-top:-30px;
      padding:30px 18px;
      display: flex;
      overflow: scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
      >div{
        min-width:240px;
      }
    }
    >button{
      margin:32px auto 70px;
    }
    .slick-slider{
      .prev, .next{
        display: none;
      }
    }
  }
`