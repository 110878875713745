import styled from "styled-components";
import { useWindowSize } from '../../customHook/useWindowSize';
import { useState } from "react";
import { useModal } from "../../customHook/useModal";
import useAPI from "../../customHook/useAPI";

export default function ReportModal({ auctionId, caseNumber }) {
  const { innerWidth, innerHeight } = useWindowSize()
  const [content, setContent] = useState('')
  const { modalShow, modalClose } = useModal()
  const { postAPI } = useAPI()

  const submit = async () => {
    if(!content){return}
    let data = {
      auctionId,
      content
    }
    const { success } = await postAPI(`/auction/report`, data);
    if(success){
      modalShow({
        message:"제보가 완료되었습니다.",
        submessage:"검토 후 며칠 내 반영하겠습니다.",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
    } else {
      modalShow({
        message:"제보과정에 에러가 있습니다.",
        submessage:"에러가 지속된다면 관리자에게 문의해주세요",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
    }
  }

  return (
    <Div $innerWidth={innerWidth} $innerHeight={innerHeight}>
      <div>
        <div className="title">제보하기</div>
        <div className="separateBar" />
        <div className="label">
          사건번호
        </div>
        <div className="content">{`${caseNumber}`}</div>
        <div className="label">
          내용
        </div>
          <textarea
            placeholder="내용을 입력해주세요."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        <div className="buttons">
          <div className="white" onClick={()=>{modalClose()}}>닫기</div>
          <div className="blue" onClick={()=>{submit()}}>제출</div>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width:${(props) => props.$innerWidth * 0.9}px;
  height:${(props) => props.$innerHeight * 0.9}px;
  max-width: 420px;
  max-height: 512px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top:60px;
  >div{
    width:100%;
    overflow: scroll;
  }

  .title{
    color: #333D4B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align:left;
    width:100%;
    padding:0 36px 24px;
  }
  .separateBar{
    width:82%;
    height: 1px;
    background: #DBDBDB;
    margin:0 auto;
  }
  .label{
    width: 100%;
    text-align: left;
    padding:24px 36px 8px;
  }
  .content{
    text-align:left;
    height:48px;
    width:82%;
    padding:0 18px;
    line-height:48px;
    border-radius: 6px;
    border: 1px solid #DADADA;
    color: #191F28;
    font-weight: 500;
    margin:0 auto;
  }
  textarea{
    width:82%;
    height: 152px;
    padding:15px 18px;
    border-radius: 6px;
    border: 1px solid #DADADA;
    background: #FFF;
    resize: none;
    outline:#1A6DFF;

    &::placeholder{
      color: #D1D6DB;
    }
    &:focus-within{
      border: 1px solid #1A6DFF;
    }
  }
  .buttons{
    display: flex;
    justify-content:center;
    margin-top:29px;
    >div{
      width: 106px;
      height: 44px;
      line-height: 44px;
      border-radius: 4px;
      margin:0 5px 28px;
      cursor:pointer;
    }
    .white{
      border: 1px solid #D7D7D7;
      background: #FFF;
      color: #333D4B;
      font-weight: 500;
    }
    .blue{
      border: 1px solid #D7D7D7;
      background: #1A6DFF;
      color: #FFF;
      font-weight: 700;
    }
  }
  @media (max-width: 767px) {
    width: 324px;
    height: 464px;
    padding-top:44px;
    .title{
      font-size: 18px;
      padding:0 18px 18px;
    }
    .separateBar,.content,textarea{
      width:calc(100% - 36px);
      font-size:14px;
    }
    .label{
      padding-left:18px;
      padding-right:18px;
      font-size:13px;
    }
    .buttons{
      >div{      
        width: 102px;
        height: 40px;
        line-height: 40px;
      }
      *{
        font-size: 14px;
      }
    }
  }
`