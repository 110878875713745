import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import styled from "styled-components";
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function PageNumber({ total_pageno, page, setPage, style }) {
  const location = useLocation();
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search);

  const [selectedPage, setSelectedPage] = useState(page);

  useEffect(()=>{
    setSelectedPage(page)
  },[page])  

  const handleChange = (e, value) => {
    setSelectedPage(value);
    setPage(value)
    params.set('page', value)
    navigate(`${location.pathname}?${params.toString()}`)
  };

  return (
    <Div style={style}>
      <Stack spacing={2}>
        <Pagination count={total_pageno} page={selectedPage} shape="rounded" variant="outlined" onChange={handleChange} />
      </Stack>
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  justify-content: center;
  margin:40px auto;
`;