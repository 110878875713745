import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ARROW_left from '../../images/arrow_left.svg'
import logo from '../../images/logoBlackWhite.svg'
import styled from 'styled-components'
import { useState } from "react";
import { useModal } from "../../customHook/useModal";
import { v4 as uuidv4 } from 'uuid';

export default function AuctionImage({ images }) {
  // const [isModal, openModal] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0);
  const { carouselModal } = useModal()

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img className="arrowImage" src={ARROW_left} style={{ transform: 'rotateY(180deg)' }} alt="arrow_right" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img className="arrowImage" src={ARROW_left} alt="arrow_left" />
      </div>
    );
  }


  const settings = {
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    swipeToSlide: true,
    centerPadding: '0px',
    initialSlide: 0, // 0으로 두면 트랜지션 가능한데 activeSlide 로 두면 트렌지션 불가능
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };


  return (
    <Div>
      {images.length > 0 ?
        <>
          <Slider {...settings}>
            {images.map((image) => {
              return (
                <div className="auctionImages" key={uuidv4()}>
                  <img alt="auctionImage" src={'https://auctionword-uploads.s3.ap-northeast-2.amazonaws.com/auctionImage/' + image} onClick={() => { carouselModal(images, activeSlide) }} />
                </div>
              )
            })}
          </Slider>
          <div className="indicator">{`${activeSlide + 1} / ${images.length}`}</div>
        </>
        : 
        <div className="emptyAuctionImages">
          <img alt="logo" src={logo}/>
          <div>현재 찾으시는 물건 이미지가 없습니다.</div>
        </div>
      }
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  aspect-ratio: 790 / 480;
	background-color: #000;
  border-radius:4px;
  position:relative;
  cursor:pointer;
  .emptyAuctionImages{
    width:100%;
    height:100%;
    cursor:inherit;
    background: #E5E8EB;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    >div{
      color: #8B95A1;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      margin-top:24px;
    }
  }

  &:hover{
    .indicator{
      opacity: 1;
      transition:0.2s;
    }
  }
  .indicator{
    opacity: 0;
    position:absolute;
    right:16px;
    bottom:22px;
    padding: 0 14px;
    height: 24px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.60);
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .slick-slider{
    height:100%;
    overflow: hidden;
    border-radius: 4px;
    &:hover{
      .slick-arrow{
        opacity:1;
      }
    }

    .slick-slide{
      div:focus-visible{
        outline: 0;
      }
      .auctionImages{
        width:100%;
		    height:100%;
        > img{
          cursor:default;
          object-fit:contain;
          display: block;
          margin:0 auto;
          width:100%;
          aspect-ratio: 790 / 480;
          overflow:hidden;
          cursor:pointer;
        }
      }
    }
    .slick-arrow{
      opacity:0;
      transition:0.2s;
      width:60px;
      height:60px;
      cursor:pointer;
      &:before{
        display: none;
      }
    }
    .slick-prev{
      left: 3px;
      z-index:1;
    }
    .slick-next{
      right: 3px;
    }
  }
  @media (max-width: 1200px) {
   .indicator{
      opacity:1 ;
   } 
   .slick-slider .slick-arrow{
    opacity:1;
   }
  }
  @media (max-width: 767px) {
    width: 100% !important;
    border-radius:0;
    .indicator{
      right:6px;
      bottom:16px;
    }
    .slick-slider{
      &:hover{
        .slick-arrow{
          opacity:0;
        }
      }
      .slick-arrow{
        opacity:0;
      }
    }
  }
`