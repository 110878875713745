import styled from "styled-components"

function formatNumber(number) {
  const unitNames = ["", "만", "억", "조"];
  const digits = number.toString().split('').reverse();
  const parts = [];

  for (let i = 0; i < digits.length; i += 4) {
    const chunk = digits.slice(i, i + 4).reverse().join('');
    const parsedChunk = parseInt(chunk, 10);

    if (parsedChunk > 0) {
      const unit = unitNames[Math.floor(i / 4)];
      parts.push(`${parsedChunk}${unit}`);
    }
  }

  return parts.reverse().join('');
}

function ellipsis(string){
  if(string.length>=20){
    return string.substring(0, 20)+'...'
  } else {
    return string
  }
}

const getOwner = (involvedList) => { // 소유자
  if(!involvedList){
    return null
  }
  let creditors = involvedList.filter(item => item.title.includes("소유자"))
  if (creditors.length === 0) {
    return ""
  } else if (creditors.length === 1) {
    return ellipsis(creditors[0].contents)
  }
  else if (creditors) {
    return `${ellipsis(creditors[0].contents)} 외 ${creditors.length - 1}명`
  }
}

const getCreditor = (involvedList) => { // 채권자
  if(!involvedList){
    return null
  }
  let creditors = involvedList.filter(item => item.title === "채권자")

  if (creditors.length === 0) {
    return ""
  } else if (creditors.length === 1) {
    return ellipsis(creditors[0].contents)
  }
  else if (creditors) {
    return `${ellipsis(creditors[0].contents)} 외 ${creditors.length - 1}명`
  }
}

const getPurpose = (address, buildingArea, landArea) => {
  const share = address.some(item => item.shareRatio !== 1);

  let purposeString = [];

  if (buildingArea > 0) {
    purposeString.push(
      <span key="building">
        {share ? '건물' : '건물전부'}
        {share && <span className="red">지분</span>}
      </span>
    );
  }

  if (landArea > 0) {
    if (purposeString.length > 0) {
      purposeString.push(', ');
    }
    purposeString.push(
      <span key="land">
        {share ? '토지' : '토지전부'}
        {share && <span className="red">지분</span>}
      </span>
    );
  }
  return <>{purposeString}</>;
};

const isFinished = (address) => {
  const auctionStateSet = new Set()
  for (const addressData of address) {
    if(addressData.auctionState){
      auctionStateSet.add(addressData.auctionState)
    } else {
      return false
    }
  }
  const auctionStateList = Array.from(auctionStateSet)
  // 주소들에 auctionState가 모두 존재하고, 기각 취하 취소 등 1개일때만 끝났다고 인지
  if(auctionStateList.length === 1){
    return auctionStateList[0]
  } else {
    return false
  }
}

export default function AuctionDescription({ apiData, bulidingArea, landArea, totalBuildingArea, totalLandArea }) {
  let isNotPaid = JSON.parse(apiData.dateHistoryList).find(data => data.auctionResult === '미납')
  let finsiehd
  if(apiData.finalResult !== '미종국' && apiData.finalDate){
    finsiehd = true
  } else if(isFinished(apiData.address)){
    finsiehd = true
  }

  return(
    <Div>
      <div className="price">
        <div className="appraisalInfo">
          <div>
            {formatNumber(apiData.appraisalValue) + '원'}
          </div>
          <span>감정가</span>
        </div>
        <div className="separateBar" />
        <div className="mimimum">
          <div style={finsiehd ? {textDecoration:"line-through", textDecorationColor:"#D91C18",textDecorationThickness:"3px"} : {}}>{`${formatNumber(apiData.minimumBidPrice)}원(${parseInt(apiData.minimumBidPrice / apiData.appraisalValue * 100)}%)`}<span>{apiData.failedCount > 0 ? `유찰 ${apiData.failedCount}회` : '신건'}</span></div>
          <span>최저가</span>
        </div>
      </div>

      <div className="gridWrapper">
        <div>
          <div className="title">
            용도
          </div>
          <div className="description">
            {apiData.purpose}
          </div>
        </div>
        <div>
          <div className="title">
            소유자
          </div>
          <div className="description">
            {getOwner(JSON.parse(apiData.detail.involvedList))}
          </div>
        </div>
        <div>
          <div className="title">
            채권자
          </div>
          <div className="description">
            {getCreditor(JSON.parse(apiData.detail.involvedList))}
          </div>
        </div>
        <div>
          <div className="title">
            대상
          </div>
          <div className="description">
            {getPurpose(apiData.address, bulidingArea, landArea)}
          </div>
        </div>
        <div>
          <div className="title">
            건물
          </div>
          <div className="description">
            {bulidingArea === totalBuildingArea ?   
              <>
                {totalBuildingArea}㎡ {`(${((totalBuildingArea / 3.3).toFixed(1))})평`}
              </>
              :
              <>
                {bulidingArea}㎡ {`(${((bulidingArea / 3.3).toFixed(1))})평`}
                <br/>
                중&nbsp;<span className="red">{totalBuildingArea.toFixed(2)}㎡ {`(${((totalBuildingArea / 3.3).toFixed(1))})평`}</span>
              </>
            }
          </div>
        </div>
        <div>
          <div className="title">
            토지
          </div>
          <div className="description">
            {landArea === totalLandArea ?   
              <>
                {totalLandArea}㎡ {`(${((totalLandArea / 3.3).toFixed(1))})평`}
              </>
              :
              <>
                {landArea}㎡ {`(${((landArea / 3.3).toFixed(1))})평`}
                <br/>
                중&nbsp;<span className="red">{totalLandArea.toFixed(2)}㎡ {`(${((totalLandArea / 3.3).toFixed(1))})평`}</span>
              </>
            }
          </div>
        </div>
        <div>
          <div className="title">
            입찰보증금
          </div>
          <div className="description">
            {formatNumber(isNotPaid ? apiData.minimumBidPrice * 0.2 : apiData.minimumBidPrice * 0.1)}원
          </div>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  .price{
    margin-top:24px;
    width: 100%;
    height: 96px;
    border-radius: 4px;
    background: #F6F6F8;
    display: flex;
    justify-content:space-evenly;
    align-items:center;

    >div>span{
      color: #8B95A1;
      font-size: 14px;
      font-weight: 500;
      text-align:center;
      display: inline-block;
      width: 100%;
    }
    .appraisalInfo{
      >div{
        color: #191F28;
        font-size: 20px;
        font-weight: 700;
        margin-bottom:4px;
      }
      >span{
        color: #8B95A1;
        font-size: 14px;
        font-weight: 500;
        text-align:center;
        display: inline-block;
        width: 100%;
      }
    }
    .separateBar{
      background-color: #DADADA;
      width: 1px;
      height: 14px;
      margin:0 10px;
    }
    .mimimum{
      >div{
        color: #1A6DFF;
        display: flex;
        align-items:center;
        font-size: 20px;
        font-weight: 700;
        margin-bottom:4px;
        >span{
          text-align: center;
          margin-left:14px;
          display: inline-block;
          width: 56px;
          height: 26px;
          border-radius: 100px;
          background: #1A6DFF;
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-weight: 600;
          line-height: 26px;
        }
      }
    }
  }
  .gridWrapper{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap:0 24px;
    margin-top:40px;
    .title{
      color: #8B95A1;
      font-size: 15px;
      font-weight: 500;
      margin-bottom:10px;
    }
    .description{
      width:100%;
      color: #4E5968;
      font-size: 18px;
      font-weight: 700;
      height:44px;
      margin-bottom:10px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      span{
        font-size: 18px;
      }
      .red{
        color: #D91C18;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 767px) {
    .price{
      >div>span{
        color: #8B95A1;
        font-size: 14px;
        font-weight: 500;
        text-align:center;
        display: inline-block;
        width: 100%;
      }
      .appraisalInfo{
        >div{
          font-size: 14px;
        }
      }
      .mimimum{
        >div{
          font-size: 14px;
          >span{
            display: none;
          }
        }
      }
    }
    .gridWrapper{
      grid-template-columns: repeat(2, minmax(0, 1fr));
      /* grid-gap:0 14px; */
      .title{
        font-size: 14px;
        margin-bottom:6px;
      }
      .description{
        font-size: 15px;
        height:36px;
        margin-bottom:6px;
        span{
          font-size: 15px;
        }
      }
    }
  }
`