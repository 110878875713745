import { useEffect, useState } from 'react';
import useAPI from '../customHook/useAPI';
import AuctionItemList from '../components/auctionItemList';
import Banner from '../images/recommendBanner.png'
import { useNavigate, useLocation } from 'react-router-dom';
import Select from '../components/select/select';
import styled from 'styled-components';
import { useAuth } from '../customHook/useAuth';
import { useWindowSize } from '../customHook/useWindowSize';
import { useBlock } from '../customHook/useBlock';
import PageNumber from '../components/pageNumber';

export default function Recommend() {
  const { getAPI } = useAPI();
  const [apiData, setApiData] = useState([]);
  const [count, setCount] = useState(null)
  const [page, setPage] = useState(1); // pagination
  const [total_pageno, setTotal_pageno] = useState(0)
  const { setBlock } = useBlock()

  const location = useLocation();
  const navigate = useNavigate()
  const [currentUrl, setCurrentUrl] = useState()
  const [option, setOption] = useState({ value: "1", label: "매각기일 빠른 순" });
  const { user } = useAuth()
  const { isMobile, isTablet } = useWindowSize()

  const params = new URLSearchParams(location.search);

  const options = [
    { value: "1", label: "매각기일 빠른 순" },
    { value: "2", label: "매각기일 늦은 순" },
    { value: "3", label: "최저가 ↑" },
    { value: "4", label: "최저가 ↓" },
    { value: "5", label: "유찰 ↑" },
    { value: "6", label: "유찰 ↓" },
  ]

  const setOrderOption = (option) => {
    params.set('order', option.value)
    params.delete('page');setPage(1) // 필터가 바뀔때 page숫자 1로 초기화
    setOption(option)
    navigate(`/recommend?${params.toString()}`)
  }


  useEffect(() => {
    const orderParams = params.get('order')
    const pageParams = params.get('page')
    if (orderParams) {
      let isValiableOrdersParams = /^[1-6]$/.test(orderParams)
      if (!isValiableOrdersParams) {
        params.delete('order')
      } else {
        setOption(options.find(option => option.value === orderParams))
      }
    }
    if (pageParams) {
      let pageNumber = parseInt(pageParams)
      if (typeof pageNumber === "number" && !Number.isNaN(pageNumber)) { // page param vaild 검사
        page !== pageNumber && setPage(pageNumber)
      } else {
        params.delete('page')
      }
    }
    setCurrentUrl(params.toString() ? `/auction/recommend?${params.toString()}${isTablet ? '&itemsPerPage=15' : ''}` : `/auction/recommend${isTablet ? '?itemsPerPage=15' : ''}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.toString(), isTablet])

  // API 데이터 로드와 관련된 useEffect
  useEffect(() => {
    setBlock(true)
    if (!currentUrl) return
    const fetchAPI = async () => {
      let apiData;
      if (!total_pageno) {
        let newUrl = currentUrl === '/auction/recommend' ? currentUrl + `?count=true` : currentUrl + `&count=true`
        apiData = await getAPI(newUrl);
      } else {
        apiData = await getAPI(currentUrl);
      }
      setApiData(apiData.data.auctionList)
      if (apiData.data.count !== null) { // count가 true 일때만 불러옴
        setCount(apiData.data.count)
        setTotal_pageno(Math.ceil(apiData.data.count / 16))
      }
      document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
      setBlock(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl])


  const singleValueStyle = {
    color: '#6B7684',
    textAlign: 'right',
    fontWeight: '500',
  }

  const ValueContainerStyle = {
    marginRight: '-15px'
  }

  const controlStyle = {
    border: 'none',
    "&:hover": {
      backgroundColor: "white",
    },
  }

  return (
    <>
      <RecommendHeader>
        {!user &&
          <div className="banner" style={{ backgroundImage: `url(${Banner})` }}>
            <div>
              경매광장플러스 회원가입하고 {isMobile && <br />} 다양하게 즐기세요!
            </div>
            <div onClick={() => { navigate(`/login`) }}>
              {!isMobile && `회원`}가입 하기
            </div>
          </div>
        }
        <div className="header">
          경매광장 추천물건을 만나보세요!
          <span>HOT</span>
        </div>

      </RecommendHeader>
      {apiData &&
        <Div>
          <div className="top">
            <div className="count">
              전체 {count?.toLocaleString() || 0}건
            </div>
            <div className="select">
              <div className="questionMark">?</div>
              <Select
                onChange={(option) => { setOrderOption(option) }}
                value={option}
                options={options}
                singleValueStyle={singleValueStyle}
                controlStyle={controlStyle}
                ValueContainerStyle={ValueContainerStyle}
              />
            </div>
          </div>
          <AuctionItemList apiData={apiData} />
        </Div>
      }
      {total_pageno >= 2 && <PageNumber style={{ marginTop: '40px' }} total_pageno={total_pageno} setPage={setPage} page={page} />}
    </>
  );
}
const RecommendHeader = styled.div`
  max-width: 1200px;
  width:calc(100% - 80px);
  margin:24px auto 0;

  >.banner{
    width: 100%;
    height: 104px;
    border-radius:16px;
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    padding:0 50px 0 60px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    >div:first-child{
      color: #FFF;
      font-size: 22px;
      font-weight: 700;
    }
    >div:nth-child(2){
      width: 143px;
      height: 44px;
      line-height: 44px;
      text-align:center;
      border-radius: 8px;
      background: #FFF;color: #2C4BC9;
      font-weight: 700;
      cursor:pointer;
    }
  }
  >.header{
    margin-top:50px;
    color: #333D4B;
    font-size: 24px;
    font-weight: 700;
    display:flex;
    align-items:center;
    border-bottom:1px solid #EAEAEA;
    padding-bottom:32px;
    >span{
      width: 46px;
      height: 26px;
      line-height: 26px;
      text-align:center;
      border-radius: 6px;
      background: rgba(32, 200, 235, 0.12);
      color: #20C8EB;
      font-size: 12px;
      font-weight: 800;
      display: inline-block;
      margin-left:6px;
    }
  }
  @media (max-width: 767px) {
    width:calc(100% - 36px);
    >.banner{
      padding: 0 24px;
      height:92px;
      >div:first-child{
        font-size: 16px;
      }
      >div:nth-child(2){
        width: 74px;
        height: 38px;
        line-height: 38px;
        font-size:13px;
      }
    }
    >.header{
      justify-content: space-between;
      font-size: 20px;
      margin-top: 28px;
      padding-bottom: 24px;
    }
  }
`

const Div = styled.div`
  max-width:1200px;
  width:calc(100% - 80px);
  margin: 0 auto;
  >p{
    margin:36px 0 20px;
    color: #333D4B;
    font-size: 18px;
    font-weight: 700;
  }
  .top{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .count{
      color: #333D4B;
      font-size: 18px;
      font-weight: 700;
      margin: 27px 0 12px;
    }
    .select{
      .questionMark{
        width: 18px;
        height: 18px;
        background-color:#B0B8C1;
        text-align: center;
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
        border-radius:50%;
        line-height: 18px;
        margin-right: -8px;
        z-index: 1;
      }
      align-items: center;
      margin: 27px 0 12px;
      display: flex;
      justify-content: right;
    }
  }
  @media (max-width: 767px) {
    width:calc(100% - 36px);
    .top{
      .count, .select{
        margin: 26px 0 20px;
      }
      .count{
        font-size:16px;
      }
      .select{
        margin-right: -8px;
        *{
          font-size:13px !important;
        }
      }
    }
  }
`