import { useWindowSize } from "../../customHook/useWindowSize";
import styled from "styled-components";

// login, add wish, my board
export default function AddressModal({addressInfo}){
  const { innerWidth, innerHeight } = useWindowSize()

  return(
    <Div $innerWidth={innerWidth} $innerHeight={innerHeight}>
      <div className="title">부동산 표시</div>
      <div className="separateBar"/>
      <div className="content">{`${addressInfo}`}</div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 580px;
  height:${(props) => props.$innerHeight * 0.9}px;
  max-height: 512px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top:60px;

  .title{
    color: #333D4B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align:left;
    width:100%;
    padding:0 36px 24px;
  }
  .separateBar{
    width:87%;
    height: 1px;
    background: #DBDBDB;
  }
  .content{
    white-space: pre-line;
    text-align:left;
    width:100%;
    padding:30px 36px 60px;
    overflow: scroll;
  }
  
  @media (max-width: 767px) {
    max-width: 324px;
    max-height: 350px;
    padding-top:44px;
    .title{
      font-size: 18px;
      padding:0 18px 18px;
    }
    .content{
      padding:24px 18px 48px;
      font-size: 13px;
    }
  }
`