import styled from "styled-components"
import useAPI from "../customHook/useAPI"
import { useModal } from "../customHook/useModal"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export default function QuestionForm() {
  const {  postAPI } = useAPI()
  const { modalShow, modalClose } = useModal()
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const navigate = useNavigate()


  const handleSubmit = async () => {
    if(!title){
      modalShow({
        message:"제목을 입력해주세요.",
        submessage:"",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
      return
    }
    if(!content){
      modalShow({
        message:"내용을 입력해주세요.",
        submessage:"",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
      return
    }
    let data = {
      title,
      content
    }
    const { success } = await postAPI(`/question`, data);
    if(success){
      modalShow({
        message:"1:1문의를 제출하였습니다.",
        submessage:"문의 내용은 최대한 빨리 처리해드리겠습니다.",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
      setTitle('')
      setContent('')
      navigate('/mypage/question')
    } else {
      modalShow({
        message:"1:1문의과정에 에러가 있습니다.",
        submessage:"에러가 지속된다면 관리자에게 문의해주세요",
        actionButton: <div onClick={() => { modalClose(); }}>닫기</div>
      })
    }
  }

  return (
    <Div>
      <h1>문의등록을 위해<br/>아래 정보를 입력해주세요</h1>
      <p>
        자주 묻는 질문에 대한 답변은 FAQ에서 확인해 보세요.
      </p>
      <div className="data">
        <p>
          제목
        </p>
        <input
          type="text"
          placeholder="문의 제목"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="data">
        <p>
          내용
        </p>
        <textarea
          placeholder="문의 내용"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <button className="submit" onClick={()=>{handleSubmit()}}>제출하기</button>
    </Div>
  )
}

const Div = styled.div`
  max-width: 480px;
  width:100%;
  margin:68px auto 128px;
  >h1{
    color: #333D4B;
    font-size: 24px;
    font-weight: 700;
    line-height:32px;
  }
  >p{
    color: #8B95A1;
    font-size: 15px;
    font-weight: 500;
    margin:18px 0 22px
  }
  .data{
    >p{
      color: #4E5968;
      font-size: 15px;
      font-weight: 500;
      margin:28px 0 10px;
    }
    input{
      height: 48px;
    }
    textarea{
      height: 196px;
      outline:#1A6DFF;
    }
    input, textarea{
      width:100%;
      padding:18px;
      border-radius: 6px;
      border: 1px solid #DADADA;
      background: #FFF;
      resize: none;

      &::placeholder{
        color: #D1D6DB;
      }
      &:focus-within{
        border: 1px solid #1A6DFF;
      }
    }
  }
  .submit{
    width: 112px;
    height: 46px;
    line-height: 46px;
    border-radius: 6px;
    background: #1A6DFF;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    margin-bottom:70px;
    width:calc(100% - 36px);
    >p{
      font-size:14px;
    }
  }
`