import { useRef, useEffect, useState } from 'react';
import useAPI from '../customHook/useAPI';
import AuctionItemList from '../components/auctionItemList';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchbarTop } from '../components/searchbarTop'
import { purposeList, addressObject, keywordsList } from "../utils";
import { useBlock } from '../customHook/useBlock';
import { stateObject } from '../utils';
import Select from '../components/select/select';
import styled from 'styled-components';
import { useWindowSize } from '../customHook/useWindowSize';
import { useModal } from '../customHook/useModal';
import PageNumber from '../components/pageNumber';


export default function Search() {
  const { getAPI } = useAPI();
  const { modalClose } = useModal();
  const [apiData, setApiData] = useState([]);
  const [count, setCount] = useState(null)
  const [total_pageno, setTotal_pageno] = useState(0)

  const [page, setPage] = useState(1)

  const location = useLocation();
  const navigate = useNavigate()
  const [currentUrl, setCurrentUrl] = useState()
  const [option, setOption] = useState({ value: "1", label: "매각기일 빠른 순" });
  const { setBlock } = useBlock()
  const { innerWidth, isTablet } = useWindowSize()

  const [buttonDirection, setButtonDireiction] = useState(null)
  const [marginPoint, setmarginPoint] = useState(null)
  const [topPosition, setTopPosition] = useState(null)

  const params = new URLSearchParams(location.search);
  const prevParams = useRef(params.toString()); // params의 변화 감지

  const options = [
    { value: "1", label: "매각기일 빠른 순" },
    { value: "2", label: "매각기일 늦은 순" },
    { value: "3", label: "최저가 ↑" },
    { value: "4", label: "최저가 ↓" },
    { value: "5", label: "유찰 ↑" },
    { value: "6", label: "유찰 ↓" },
  ]

  const setOrderOption = (option) => {
    params.set('order', option.value)
    params.delete('page'); setPage(1) // 필터가 바뀔때 page숫자 1로 초기화
    setOption(option)
    navigate(`/search?${params.toString()}`)
  }

  const checkValiableIntegerParams = (paramsList) => { // 모든 params들이 양의정수인지 확인.
    return paramsList.every((value) => {
      let valueParam = params.get(value)
      if (valueParam) {
        if (/^[1-9]\d*$/.test(valueParam)) { // 양의정수
          return true
        } else {
          params.delete(value)
          return false
        }
      } else {
        return true
      }
    })
  }


  useEffect(() => {
    setBlock(true)
    let pageParams = params.get('page'); 
    if(pageParams){
      if(parseInt(pageParams) !== page){
        setPage(parseInt(pageParams))
      }
    }

    const fetchAPI = async () => {
      let apiData;
      if(!total_pageno){
        let newUrl
        if(currentUrl){
          if(currentUrl === '/auction'){
            if(currentUrl.includes('count=true')){
              newUrl = currentUrl
            } else {
              newUrl = currentUrl+ `?count=true`
            }
          } else {
            if(currentUrl.includes('count=true')){
              newUrl = currentUrl
            } else {
              newUrl = currentUrl+ `&count=true`
            }
          }
        } else {
          if(!params.toString()){ // 처음에 params가 있는 상태로 사이트에 접근할때
            newUrl = '/auction?count=true'
          }
        }
        if(newUrl){
          apiData = await getAPI(newUrl);
        }
      } else {
        apiData = await getAPI(currentUrl);
      }
      setApiData(apiData?.data?.auctionList || [])
      if (apiData?.data && apiData.data.count !== null) { // count가 true 일때만 불러옴
        setCount(apiData?.data?.count)
        setTotal_pageno(Math.ceil(apiData?.data.count/16))
      }
      document.documentElement.scrollTo({top: 0,left: 0,behavior: "instant"});
      setBlock(false)
    }
    fetchAPI()
    return () => {
      modalClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl?.replace('&count=true', '')?.replace('&itemsPerPage=16')])
      // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUrl])

  useEffect(() => {
    
  },[apiData])

  useEffect(() => {
    if (!params.toString()) {
      return
    }
    const purposeParams = params.get('purpose')?.split('+');
    const addressParams = params.get('address')?.split('+');
    const keywordsParams = params.get('keywords')?.split('+')
    const auctionStateParams = params.get('auctionState')?.split('+')
    const positiveIntigerParamList = ['minLandArea', 'maxLandArea', 'minBuildingArea', 'maxBuildingArea', 'minBidPrice', 'maxBidPrice', 'minAppraisalValue', 'maxAppraisalValue'] // 양의정수가 되어야하는 Params들
    const orderParams = params.get('order')
    const pageParams = params.get('page'); 

    let isValiablePurposeParams = true
    let isValiableAddressParams = true
    let isValiableKeywordsParams = true
    let isValiableIntegerParams = checkValiableIntegerParams(positiveIntigerParamList)
    let isValiableOrdersParams = true
    let isValiableAuctionStateParams = true

    if (purposeParams) {
      let purposeValidation = purposeParams.every(item => purposeList.find(purpose => purpose.code === item))
      if (!purposeValidation) {
        params.delete('purpose')
        isValiablePurposeParams = false
      }
    }
    if (addressParams) {
      let addressValidation = addressParams?.every((item) => {
        if (item.length === 2) {
          return addressObject.sido.find(address => address.sido === item)
        } else if (item.length === 5) {
          return addressObject.sigugun.filter(address => item.slice(0, 2) === address.sido && item.slice(2) === address.sigugun)[0]
        } else {
          return false
        }
      })
      if (!addressValidation) {
        params.delete('address')
        isValiableAddressParams = false
      }
    }
    if (keywordsParams) {
      let keywordsValidation = keywordsParams.every(item => keywordsList.find(keywords => keywords.code === item))
      if (!keywordsValidation) {
        params.delete('keywords')
        isValiableKeywordsParams = false
      }
    }
    if (auctionStateParams) {
      let auctionStateValidation = auctionStateParams.every(item => Object.values(stateObject).includes(item))
      if (!auctionStateValidation) {
        params.delete('auctionState')
        isValiableAuctionStateParams = false
      }
    }
    if (orderParams) {
      isValiableOrdersParams = /^[1-6]$/.test(orderParams)
      if (!isValiableOrdersParams) {
        params.delete('order')
      } else {
        setOption(options.find(option => option.value === orderParams))
      }
    }
    if (!pageParams || !Number.isInteger(parseInt(pageParams))){
      params.set('page', 1)
      setPage(1)
    }
    if (isValiableIntegerParams && isValiablePurposeParams && isValiableAddressParams && isValiableKeywordsParams && isValiableOrdersParams && isValiableAuctionStateParams) {
      setCurrentUrl(params.toString() ? `/auction?${params.toString()}` : `/auction`)
    } else {
      navigate(location.pathname + '?' + params.toString()) // inVaild params있을 시 제거 후 redirection
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.toString()])

  useEffect(()=>{ // page제외한 params이 변할때 count 호출 및 page 초기화
    const currentParams = params.toString();
    const currentWithoutPage = currentParams.replace(/&?page=[^&]+/, '');
    const prevWithoutPage = prevParams.current.replace(/&?page=[^&]+/, '');
    if (currentWithoutPage !== prevWithoutPage) { // page 제외한 params이 변했을 때
      params.set('page', 1)
      setPage(1)
      setCurrentUrl(params.toString() ? `/auction?${params.toString()}&count=true` : `/auction?count=true`)
      navigate(`${location.pathname}?${params.toString()}`)
      prevParams.current = params.toString(); // params 업데이트
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params.toString()])


  useEffect(() => { // tablet일 경우 15개만 호출
    if(!count) return
    if(!isTablet){
      if(total_pageno === Math.ceil(count/15)){ //isTablet이 아니고 이전에 total_pageno가 count/15라면 api itemsPerPage=16으로 다시 호출해야함.
        setTotal_pageno(Math.ceil(count/16))
        setCurrentUrl(params.toString() ? `/auction?${params.toString()}&itemsPerPage=16` : `/auction?itemsPerPage=16`)
        params.set('page', 1)
        setPage(1)
        navigate(`${location.pathname}?${params.toString()}`)
      }
    } else {
      setTotal_pageno(Math.ceil(count/15))
      setCurrentUrl(params.toString() ? `/auction?${params.toString()}&itemsPerPage=15` : `/auction?itemsPerPage=15`)
      params.set('page', 1)
      setPage(1)
      navigate(`${location.pathname}?${params.toString()}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isTablet, total_pageno])


  const SearchbarTopWrapper = document.getElementsByClassName('SearchbarTopWrapper')[0];
  const style = SearchbarTopWrapper?.currentStyle || (SearchbarTopWrapper && window.getComputedStyle(SearchbarTopWrapper));
  const dropdowns = document.getElementsByClassName('dropdowns')[0];
  const top = dropdowns?.getBoundingClientRect().top;
  const selectGroup = document.getElementsByClassName('selectGroup')[0];
  useEffect(() => {
    setmarginPoint(style?.marginLeft);
    setTopPosition(`${top}px`);

    if (dropdowns?.scrollWidth > selectGroup?.offsetWidth) {
      if (selectGroup?.scrollLeft === 0) {
        setButtonDireiction('right');
      }
      if (selectGroup?.scrollLeft + 1 > selectGroup?.scrollWidth - selectGroup?.clientWidth) {
        setButtonDireiction('left');
      }
    } else {
      setButtonDireiction(null)
    }

    // scroll 좌우 감지하여 화살표 방향 결정
    const handleScroll = () => {
      const scrollLeft = selectGroup?.scrollLeft;
      const maxScroll = selectGroup?.scrollWidth - selectGroup?.clientWidth;
      if (dropdowns?.scrollWidth > selectGroup?.offsetWidth) {
        if (scrollLeft === 0) {
          setButtonDireiction('right');
        }
        if (scrollLeft + 1 > maxScroll) {
          setButtonDireiction('left');
        }
      }
    };
    selectGroup?.addEventListener("scroll", handleScroll);
    return () => {
      selectGroup?.removeEventListener("scroll", handleScroll);
    };
  }, [SearchbarTopWrapper, style, dropdowns, top, selectGroup, dropdowns?.scrollWidth, innerWidth])


  const singleValueStyle = {
    color: '#6B7684',
    textAlign: 'right',
    fontWeight: '500',
  }

  const ValueContainerStyle = {
    marginRight: '-15px'
  }

  const controlStyle = {
    border: 'none',
    "&:hover": {
      backgroundColor: "white",
    },
  }

  return (
    <>
      <SearchbarTop topPosition={topPosition} marginPoint={marginPoint} buttonDirection={buttonDirection} setButtonDireiction={setButtonDireiction} />
      {apiData &&
        <Div>
          <div className="top">
            <div className="count">
              전체 {count?.toLocaleString() || 0}건
            </div>
            <div className="select">
              <div className="questionMark">?</div>
              <Select
                onChange={(option) => { setOrderOption(option) }}
                value={option}
                options={options}
                singleValueStyle={singleValueStyle}
                controlStyle={controlStyle}
                ValueContainerStyle={ValueContainerStyle}
              />
            </div>
          </div>
          <AuctionItemList apiData={apiData} />
        </Div>
      }
      {/* <div ref={ref} style={{ height: '50px' }}></div> */}
      {total_pageno >= 2 ? <PageNumber style={{ marginTop: '40px' }} total_pageno={total_pageno} setPage={setPage} page={page} />:<div  style={{ height: '80px' }}/>}
    </>
  );
}

const Div = styled.div`
  max-width:1200px;
  width:calc(100% - 80px);
  margin: 0 auto;
  >p{
    margin:36px 0 20px;
    color: #333D4B;
    font-size: 18px;
    font-weight: 700;
  }
  .top{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .count{
      color: #333D4B;
      font-size: 18px;
      font-weight: 700;
      margin: 27px 0 12px;
    }
    .select{
      .questionMark{
        width: 18px;
        height: 18px;
        background-color:#B0B8C1;
        text-align: center;
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
        border-radius:50%;
        line-height: 18px;
        margin-right: -8px;
        z-index: 1;
      }
      align-items: center;
      margin: 27px 0 12px;
      display: flex;
      justify-content: right;
      margin-right: -8px;
    }
  }

  @media (max-width: 767px) {
    width:calc(100% - 36px);
    .top{
      .count, .select{
        margin: 26px 0 20px;
      }
      .count{
        font-size:16px;
      }
      .select{
        margin-right: -8px;
        *{
          font-size:13px !important;
        }
      }
    }
  }

`