import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './page/main';
import Gnb from './components/gnb'
import Search from './page/search';
import Detail from './page/detail';
import Login from "./page/login";
import Recommend from "./page/recommend";
import NotFound from "./page/notFound";
import Mypage from "./page/mypage";
import QuestionForm from './page/questionForm'
import Answer from "./page/answer";

import { BlockProvider } from './customHook/useBlock';
import { AuthProvider } from './customHook/useAuth';
import { ModalProvider } from './customHook/useModal';

import Footer from "./components/footer";

import styled from "styled-components";

export default function App() {
  return (
    <BrowserRouter>
      <BlockProvider>
        <AuthProvider>
          <ModalProvider>
            <Gnb />
            <Div>
              <Routes>
                <Route exact path="/search" element={<Search />} />
                <Route exact path="/recommend" element={<Recommend />} />
                <Route exact path="/auction/:auctionId" element={<Detail />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/mypage/questionForm" element={<QuestionForm />} />
                <Route path="/mypage/question/:id" element={<Answer/>}></Route>
                <Route exact path="/mypage/*" element={<Mypage />} />
                <Route exact path="/" element={<Main />} />
                <Route path="*" element={<NotFound />} />
                {/* <Route exact path="/dashboard" element={<Dashboard/>} /> */}
              </Routes>
            </Div>
            <Footer/>
          </ModalProvider>
        </AuthProvider>
      </BlockProvider>
    </BrowserRouter>
  );
}

const Div = styled.div`
  min-width:1024px;
  @media (max-width: 1023px) {
    min-width:768px;
    width:100%;
  }
  @media (max-width: 767px) {
    min-width:360px;
  }
`
