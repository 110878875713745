import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ARROW_left from '../../images/arrow_left.svg'
import styled from 'styled-components'
import { useState, useEffect } from "react";
import { useWindowSize } from '../../customHook/useWindowSize'
import close from "../../images/close.svg"
import { useModal } from "../../customHook/useModal";
import { v4 as uuidv4 } from 'uuid';

export default function CarouselModal({images, initialSlide, setCloseButtonStyle }) {

  const { innerWidth, innerHeight, isMobile } = useWindowSize()

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [activeSlide, setActiveSlide] = useState(initialSlide);
  const { modalClose } = useModal()

  useEffect(()=>{
		let style = {
      display:'none',
		}
    let mobileStyle={
      top:'23px',
      right:'18px',
      filter: 'invert(99%) sepia(100%) saturate(0%) hue-rotate(272deg) brightness(108%) contrast(101%)'
    }
		setCloseButtonStyle(isMobile ? mobileStyle: style)
		return () => {setCloseButtonStyle({})}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])


  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img className="arrowImage" src={ARROW_left} style={{ transform: 'rotateY(180deg)' }} alt="arrow_right" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img className="arrowImage" src={ARROW_left} alt="arrow_left" />
      </div>
    );
  }


  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    swipeToSlide: true,
    centerPadding: '0px',
    initialSlide: initialSlide,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

	return (
    <Div $innerWidth={innerWidth} $innerHeight={innerHeight}>
      <img onClick={() => {modalClose()}} src={close} style={{position: "absolute", top: "0px", right: "-26px",cursor:'pointer', filter: "invert(100%) sepia(1%) saturate(0%) hue-rotate(331deg) brightness(114%) contrast(101%)"}} alt="close button"/>
      <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settings}>
        {images.map((image) => {
          return (
            <div className="auctionImages" key={uuidv4()}>
              <img alt="auctionImage" src={'https://auctionword-uploads.s3.ap-northeast-2.amazonaws.com/auctionImage/' + image} />
            </div>
          )
        })}
      </Slider>
      <div className="indicator">{`${activeSlide + 1} / ${images.length}`}</div>
      {!isMobile && 
        <Slider
          className="nav"
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          slidesToShow={6}
          initialSlide= {activeSlide}
          swipeToSlide={true}
          focusOnSelect={true}
          centerPadding={30}
          nextArrow={null}
          >
              {images.map((image) => {
            return (
              <div className="auctionImages" key={uuidv4()}>
                <img alt="auctionImage" src={'https://auctionword-uploads.s3.ap-northeast-2.amazonaws.com/auctionImage/' + image} />
              </div>
            )
          })}
        </Slider>
      }
		</Div>
	)
}


const Div = styled.div`
  top: -60px;
  width:${props => props.$innerWidth * 0.8 + 'px'};
  height:${props => props.$innerHeight * 0.8 + 'px'};
  max-width:1080px;
  max-height:640px;
	background-color: #191919;
  position:relative;
  .indicator{
    position:absolute;
    left:calc(50% - 54px/2);
    bottom:24px;
    padding:0 14px;
    height: 24px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.60);
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .center{
    margin-bottom: 10px;
    .slick-slide{
      div:focus-visible{
        outline: 0;
      }
    }
    .auctionImages{
      > img{
        cursor:default;
        object-fit:contain;
        display: block;
        margin:0 auto;
        width:100%;
        height:${props => props.height * 0.8 + 'px'};
        max-width:1080px;
        max-height:640px;
      }
    }
  
    .slick-arrow{
      width:60px;
      height:60px;
      cursor:pointer;
      &:before{
        display: none;
      }
    }
    .slick-prev{
      left: 3px;
      z-index:1;
    }
    .slick-next{
      right: 3px;
    }
    .arrowImage{
      width:100%;
      height:100%;
    }
  }
  .nav{
    .auctionImages{
      padding-right:3px;
      /* max-height:116px; */
      img{
        padding-left:3px;
        width:100%;
        /* height:100%; */
        aspect-ratio: 1 / 1
      }
    }
    .slick-arrow{
      display:none !important;
    }
  }
  @media (max-width: 767px) {
    top:0;
    width:${props => props.width * 1 + 'px'};
    height:${props => props.height * 1 + 'px'};
    max-height:inherit;
    max-width:inherit;
    background-color: #000;
    .indicator{
      top:23px;
    }
    .center{
      background-color: #191919;

      top:calc(50% - ${props => props.width * 260/360 + 'px'}/2);
      .auctionImages{
        > img{        
          width:${props => props.width * 1 + 'px'};
          aspect-ratio: 360/ 260;
          height: inherit;
        }
      }
      .slick-arrow{
        display: none !important;
      }
    }
  }
`